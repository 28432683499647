/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import CommentIcon from '@mui/icons-material/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import tiktokImge from '@/assets/image/tiktok-img.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { TiktokPostsTypes } from '@/types/Social';

import TitlePosts from './TitlePosts';

interface TiktokDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: TiktokPostsTypes;
}

const TiktokDetail: React.FC<TiktokDetailProps> = ({ openDialogDetail, onDialogDetailChange, detailSelected }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 3 }}>
          <TitlePosts item={detailSelected} />
          <Box sx={{ mt: 3, display: 'flex' }}>
            <Box
              component="img"
              src={tiktokImge}
              sx={{
                width: '200px',

                borderRadius: 1,
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
            <Box sx={{ width: '80%', ml: 4 }}>
              <Typography
                sx={{
                  letterSpacing: '0.01em',
                  wordBreak: 'break-all',
                }}
              >
                <HighlightTextSocial text={detailSelected.post_desc} keyword={topicSocial?.key_word_main} />
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Stack direction="row">
              <Box>
                <Avatar sx={{ backgroundColor: 'action.hover', width: '30px', height: '30px' }}>
                  <FavoriteIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
                </Avatar>
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: 'center',
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: '1.2rem',
                  }}
                >
                  {detailSelected.like_count}
                </Typography>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Avatar sx={{ backgroundColor: 'action.hover', width: '30px', height: '30px' }}>
                  <CommentIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
                </Avatar>
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: 'center',
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: '1.2rem',
                  }}
                >
                  {detailSelected.comment_count}
                </Typography>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Avatar sx={{ backgroundColor: 'action.hover', width: '30px', height: '30px' }}>
                  <VisibilityIcon sx={{ color: 'text.secondary', fontSize: '2rem' }} />
                </Avatar>
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: 'center',
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: '1.2rem',
                  }}
                >
                  {detailSelected.view_count}
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Divider sx={{ mt: 3, mb: 2 }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default TiktokDetail;
