import { useEffect, useRef } from 'react';

import { Typography, Dialog, DialogTitle, DialogContent, ImageList, ImageListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import { Transition } from '@/components/common/Transition';

import 'react-photo-view/dist/react-photo-view.css';

interface PhotosProps {
  openDialog: boolean;
  onDialogChange: (newValue: boolean) => void;
  listImg: string[];
}

const Photos: React.FC<PhotosProps> = ({ openDialog, onDialogChange, listImg }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialog && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialog]);

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '80vw', md: '50vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('identity.listImg')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <PhotoProvider>
            <ImageList sx={{}} cols={3} rowHeight={164}>
              {listImg?.map((img, index) => (
                <PhotoView key={index} src={img}>
                  <ImageListItem>
                    <img
                      src={img}
                      alt={img}
                      loading="lazy"
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </ImageListItem>
                </PhotoView>
              ))}
            </ImageList>
          </PhotoProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Photos;
