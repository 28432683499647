import { useEffect, useMemo, useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { Box, Chip, Grid, Link, Pagination, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import TextFieldOsint from '@/components/common/TextField/TextFieldOsint';
import TabHeaderProduct from '@/components/Osint/Fofa/TabHeaderProduct';
import { getToolname } from '@/configs/osintConfig';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useOsint from '@/Hooks/api/useOsint';
import { FofaStatisticsTypes, FofaTypes, OsintTypes, ProductCategory, SubmitData } from '@/types/Osint';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt, encrypt } from '@/utils/crypto';

const Fofa = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const domainActive = localStorage.getItem('domainActive') && decrypt(localStorage.getItem('domainActive') as string);
  const { dataWebSocketOsint } = useWebSocketContext();
  const [searchParams] = useSearchParams();
  const { handleGetOsint } = useOsint();

  const [dataFofa, setDataFofa] = useState<FofaTypes[]>();
  const [page, setPage] = useState<number>(1);
  const [domain, setDomain] = useState(domainActive || '');

  const filteredListOsint = useMemo(
    () => dataWebSocketOsint.find((item: OsintTypes) => item.domain === domain),
    [dataWebSocketOsint, domain],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (inforUser?.role === 'super admin' && location.search) {
        const res = await handleGetOsint({ domain: String(searchParams.get('domain')) });
        const { data } = res;
        setDataFofa(data?.osint?.fofa);
        console.log(data?.osint?.fofa);
      } else {
        setDataFofa(filteredListOsint?.data?.osint?.fofa);
      }
    };
    fetchData();
  }, [inforUser?.role, searchParams, filteredListOsint]);

  const displayedItems = useMemo(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    return dataFofa?.slice(startIndex, startIndex + PAGE_SIZE);
  }, [dataFofa, page]);

  // Hàm xử lý khi chuyển trang
  const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getTopStatistics = (data: FofaTypes[]) => {
    const countOccurrences = (arr: (string | number)[]) => {
      return arr.reduce((acc: Record<string, number>, val) => {
        const key = val.toString();
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});
    };

    const formatStatistics = (stats: Record<string | number, number>) => {
      return Object.entries(stats).map(([key, value]) => ({
        name: key,
        value,
      }));
    };

    const countries = data.map((item) => item.country).filter((country): country is string => Boolean(country));
    const ports = data.map((item) => item.port).filter((port): port is number => port !== undefined);
    const servers = data.map((item) => item.server).filter((server): server is string => Boolean(server));
    const protocols = data.map((item) => item.protocol).filter((protocol): protocol is string => Boolean(protocol));
    const titles = data.map((item) => item.title).filter((title): title is string => Boolean(title));

    const topCountries = formatStatistics(countOccurrences(countries));
    const topOpenPorts = formatStatistics(countOccurrences(ports));
    const topServers = formatStatistics(countOccurrences(servers));
    const topProtocols = formatStatistics(countOccurrences(protocols));
    const topTitles = formatStatistics(countOccurrences(titles));

    return {
      topCountries,
      topOpenPorts,
      topServers,
      topProtocols,
      topTitles,
    };
  };

  const statistics = useMemo(() => {
    if (dataFofa && dataFofa.length > 0) {
      return getTopStatistics(dataFofa);
    }
    return {
      topCountries: [],
      topOpenPorts: [],
      topServers: [],
      topProtocols: [],
      topTitles: [],
    };
  }, [dataFofa]);

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  const submitHandler = async (data: SubmitData) => {
    const params = new URLSearchParams({
      domain: data.searchInput,
    });
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
        {getToolname(location.pathname)}
      </Typography>
      {inforUser?.role === 'super admin' ? (
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextFieldOsint submitHandler={submitHandler} defaultValue={searchParams.get('domain') || ''} />
        </Box>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
          {!_.isEmpty(filteredListOsint?.data?.update_time) && (
            <ScanTime updateTime={filteredListOsint?.data?.update_time} />
          )}
        </Box>
      )}
      {dataFofa && (
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ fontWeight: 600 }}>{domainActive || searchParams.get('domain')}</Typography>
          <Typography sx={{ ml: 1 }}>-</Typography>
          <Typography sx={{ color: 'primary.main', ml: 1 }}>{dataFofa?.length || 0}</Typography>
          <Typography sx={{ ml: 1 }}>results</Typography>
        </Box>
      )}

      {!_.isEmpty(dataFofa) ? (
        <Grid container spacing={6}>
          <Grid item xs={12} lg={3}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={12}>
                <Typography
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    textTransform: 'uppercase',
                    mb: 1,
                    padding: 1,
                    color: 'text.secondary',
                  }}
                >
                  TOP COUNTRIES/REGIONS
                </Typography>
                {statistics.topCountries.map((country: FofaStatisticsTypes) => (
                  <Box key={country.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {country.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {country.value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Typography
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    textTransform: 'uppercase',
                    mb: 1,
                    padding: 1,
                    color: 'text.secondary',
                  }}
                >
                  TOP OPEN PORTS
                </Typography>
                {statistics.topOpenPorts.map((port: FofaStatisticsTypes) => (
                  <Box key={port.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {port.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {port.value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Typography
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    textTransform: 'uppercase',
                    mb: 1,
                    padding: 1,
                    color: 'text.secondary',
                  }}
                >
                  TOP SERVERS
                </Typography>
                {statistics.topServers.map((server: FofaStatisticsTypes) => (
                  <Box key={server.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {server.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {server.value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Typography
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    textTransform: 'uppercase',
                    mb: 1,
                    padding: 1,
                    color: 'text.secondary',
                  }}
                >
                  TOP PROTOCOLS
                </Typography>
                {statistics.topProtocols.map((protocol: FofaStatisticsTypes) => (
                  <Box key={protocol.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {protocol.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {protocol.value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Typography
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    textTransform: 'uppercase',
                    mb: 1,
                    padding: 1,
                    color: 'text.secondary',
                  }}
                >
                  TOP TITLES
                </Typography>
                {statistics.topTitles.map((title: FofaStatisticsTypes) => (
                  <Box key={title.name} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, ml: 2 }}>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {title.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {title.value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={9}>
            {displayedItems?.map((fofaItem: FofaTypes, index: number) => (
              <Box
                key={index}
                sx={{
                  padding: 2,
                  backgroundColor: 'background.menu',
                  mt: 2,
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 1,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Link href={fofaItem.link} underline="none">
                    <Typography variant="h6" fontWeight={500}>
                      {fofaItem.id}
                    </Typography>
                  </Link>

                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={fofaItem.port}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{
                        '.MuiChip-label': {
                          fontSize: '1.3rem',
                        },
                      }}
                    />
                    <Chip
                      label={fofaItem.protocol}
                      color="primary"
                      size="small"
                      sx={{
                        '.MuiChip-label': {
                          fontSize: '1.3rem',
                        },
                      }}
                    />
                  </Stack>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5} sx={{ mt: 3 }}>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {fofaItem.ip}
                    </Typography>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                      <Typography variant="body2" sx={{ color: 'primary.main' }}>
                        {fofaItem.country_en}
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        /
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'primary.main', ml: 1 }}>
                        {fofaItem.region}
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        /
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'primary.main', ml: 1 }}>
                        {fofaItem.city}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                      <Typography variant="body2">ASN:</Typography>
                      <Typography variant="body2" sx={{ color: 'primary.main', ml: 1 }}>
                        {fofaItem.asn}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                      <Typography variant="body2">Organization:</Typography>
                      <Typography variant="body2" sx={{ color: 'primary.main', ml: 1 }}>
                        {fofaItem.organization}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ color: 'primary.main', mt: 1 }}>
                      {fofaItem.domain}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {fofaItem.mtime}
                    </Typography>
                    {fofaItem.sdk_hash && (
                      <Box sx={{ display: 'flex', mt: 1 }}>
                        <Typography variant="body2">SDK_hash:</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', ml: 1 }}>
                          {fofaItem.sdk_hash.map((hash: ProductCategory) => (
                            <Chip
                              key={hash.name}
                              size="small"
                              icon={<LockIcon />}
                              label={hash.name}
                              color="default"
                              variant="outlined"
                              sx={{
                                '.MuiChip-icon': {
                                  fontSize: '1.5rem',
                                },
                                '.MuiChip-label': {
                                  fontSize: '1.2rem',
                                },
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                    {fofaItem.server && (
                      <Chip
                        label={fofaItem.server}
                        color="primary"
                        size="small"
                        variant="outlined"
                        sx={{
                          mt: 1,
                          '.MuiChip-label': {
                            fontSize: '1.2rem',
                          },
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TabHeaderProduct fofaItem={fofaItem} />
                  </Grid>
                </Grid>
              </Box>
            ))}
            {!_.isEmpty(dataFofa) && (
              <Pagination
                count={Math.ceil(Number(dataFofa?.length) / PAGE_SIZE)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
          No result
        </Typography>
      )}
    </>
  );
};
export default Fofa;
