import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import MenuTopic from '@/components/Social/MenuTopic';
import ResultReport from '@/components/Social/report/ResultReport';
import { useSocialInfo } from '@/contexts/Social';
import useSocial from '@/Hooks/api/useSocial';
import { ResultReportTypes } from '@/types/Social';

const ReportTopic = () => {
  const { t } = useTranslation();
  const { handleGetReport } = useSocial();
  const { topicSocial } = useSocialInfo();

  const [result, setResult] = useState<ResultReportTypes[]>([]);
  const [isResult, setIsResult] = useState(false);

  const handleReport = async () => {
    const res = await handleGetReport(topicSocial.topic_id);
    const { results } = res;
    setResult(results);
    setIsResult(true);
  };

  useEffect(() => {
    if (topicSocial) {
      handleReport();
    }
  }, [topicSocial]);

  return (
    <>
      <Helmet>
        <title>Report By Keyword - ESS</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
        {t('smcc.report')}
      </Typography>
      <MenuTopic />
      {isResult && <ResultReport results={result} />}
    </>
  );
};
export default ReportTopic;
