import * as React from 'react';

import BadgeIcon from '@mui/icons-material/Badge';
import CakeIcon from '@mui/icons-material/Cake';
import CarRentalIcon from '@mui/icons-material/CarRental';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EastIcon from '@mui/icons-material/East';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TwitterIcon from '@mui/icons-material/Twitter';
import { CardActions, Card, CardContent, Grid, Typography, useTheme, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import { IdentityData } from '@/interfaces/identity';

import CustomizableList from './CustomizableList';
import Photos from './Photos';

import 'react-photo-view/dist/react-photo-view.css';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton aria-expanded={expand} {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const IdentityCard = ({ identity }: { identity: IdentityData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const items = [
    { icon: <BadgeIcon />, primaryText: identity.cccd, type: 'pii' },
    { icon: <PermIdentityIcon />, primaryText: identity?.name, type: 'fullname' },
    { icon: <CakeIcon />, primaryText: identity.dob, type: 'birthday' },
    { icon: <HomeIcon />, primaryText: identity.address_1, type: 'address' },
    { icon: <HomeIcon />, primaryText: identity.address_2, type: 'address2' },
    { icon: <EmailIcon />, primaryText: identity.email, type: 'email' },
    { icon: <PhoneAndroidIcon />, primaryText: identity.phone, type: 'phonenum' },
    { icon: <FacebookIcon />, primaryText: identity.facebook_user_id, type: 'facebook' },
    { icon: <PersonPinIcon />, primaryText: identity.username, type: 'usename' },
    { icon: <CarRentalIcon />, primaryText: identity.plate, type: 'plate' },
    { icon: <TwitterIcon />, primaryText: identity.username_twitter, type: 'twitter' },
  ];

  // const rightColumn = [
  //   { title: 'Số định danh', nodeData: identity.cccd },
  //   { title: 'Giới Tính', nodeData: identity.gender },
  //   { title: 'Dân Tộc', nodeData: identity.tenDanToc },
  //   { title: 'Ngày Tháng Năm Sinh', nodeData: identity.dob },
  //   { title: 'Nơi Đăng Ký Khai Sinh', nodeData: identity.pob },
  //   { title: 'Thường Trú', nodeData: identity.pod },
  // ]
  // const leftColumn = [
  //   { title: 'Họ Tên', nodeData: identity.name },
  //   { title: 'Tình Trạng Hôn Nhân', nodeData: identity.dep },
  //   { title: 'Quê Quán', nodeData: identity.poc },
  //   { title: 'Nơi Ở Hiện Tại', nodeData: identity.address_1 },
  // ]
  const getDescription = ({ field }: { field: string }) => {
    const registry: { [key: string]: string } = {
      phone: 'Số điện thoại liên lạc chính của người dùng.',
      email: 'Địa chỉ email chính của người dùng.',
      cccd: 'Số chứng minh nhân dân của người dùng.',
      facebook_user_id: 'ID duy nhất trên Facebook của người dùng.',
      username_twitter: 'Tên người dùng trên Twitter của người dùng.',
      username: 'Tên đăng nhập chung của người dùng.',
      name: 'Họ và tên đầy đủ của người dùng.',
      parrent_name_1: 'Tên của bố hoặc người giám hộ 1.',
      parrent_name_2: 'Tên của mẹ hoặc người giám hộ 2.',
      dob: 'Ngày tháng năm sinh của người dùng.',
      address_1: 'Địa chỉ cư trú chính của người dùng.',
      pob: 'Nơi sinh của người dùng.',
      tenDanToc: 'Dân tộc hoặc tộc người của người dùng.',
      VungMien: 'Khu vực địa lý hoặc nơi cư trú.',
      doc: 'Ngày làm căn cước.',
      address_2: 'Địa chỉ cư trú phụ của người dùng.',
      imgUrl: 'URL đến hình đại diện của người dùng.',
      sex: 'Giới tính của người dùng.',
      old_cccd: 'Số CMND cũ của người dùng.',
      pod: 'Nơi đăng ký tạm trú của người dùng.',
      bhxh: 'Số bảo hiểm xã hội của người dùng.',
      mst: 'Mã số thuế cá nhân của người dùng.',
      parrent_contact_phone: 'Số điện thoại liên lạc của bố/mẹ/người giám hộ.',
      dep: 'Phòng ban hoặc đơn vị trong tổ chức.',
      job: 'Nghề nghiệp hoặc chức vụ của người dùng.',
      idNum: 'Số định danh cá nhân của người dùng.',
      area: 'Khu vực địa lý hoặc địa điểm.',
      usage_1: 'Mục đích chính sử dụng của nguồn tài nguyên.',
      usage_2: 'Mục đích sử dụng phụ của nguồn tài nguyên.',
      limit_time_of_use: 'Thời gian giới hạn sử dụng.',
      doituongsudung: 'Đối tượng sử dụng hoặc nhóm người dùng.',
      sex_binary: 'Biểu diễn nhị phân của giới tính người dùng.',
      typecard: 'Loại hoặc hạng của thẻ.',
      // poc: 'Điểm liên lạc hoặc giao tiếp.',
      poc: 'Nơi cấp thẻ căn cước.',
      related_name: 'Tên của người liên quan.',
      related_cccd: 'Số CMND của người liên quan.',
      related_doc: 'Loại tài liệu hoặc chứng chỉ của người liên quan.',
      mavach: 'Mã vạch hoặc mã quét.',
      sophathanh: 'Số serial hoặc mã nhận dạng.',
      cancuphaply: 'Mã xác thực hoặc xác nhận.',
      ma_so_dien: 'Mã số điện.',
      makh: 'Mã khách hàng.',
      GT: 'Quốc tịch của người dùng.',
      gender: 'Giới tính của người dùng.',
      pii: 'Thông tin cá nhân có thể xác định.',
      bod: 'Bắt đầu của tài liệu.',
      related_phone: 'Số điện thoại của người liên quan.',
      related_job: 'Nghề nghiệp của người liên quan.',
      studentId: 'Số học sinh hoặc sinh viên.',
      birthdateStr: 'Ngày tháng năm sinh của người dùng dưới dạng chuỗi.',
      className: 'Tên hoặc mã lớp của người dùng.',
      emailEdu: 'Địa chỉ email giáo dục.',
      profileUrl: 'URL đến trang hồ sơ của người dùng.',
      cvUrl: 'URL đến sơ yếu lý lịch của người dùng.',
      Comment: 'Ý kiến chung hoặc ghi chú.',
      maSinhvien: 'Mã số sinh viên.',
      job_des: 'Mô tả công việc hoặc vai trò.',
      STT: 'Trạng thái hoặc vị trí trong chuỗi.',
      stk: 'Số tài khoản ngân hàng.',
      stt: 'Trạng thái hoặc tình trạng của một thực thể.',
      work_computer: 'Máy tính sử dụng cho mục đích công việc.',
      typeofVihicle: 'Loại phương tiện.',
      plate: 'Biển số đăng ký xe.',
      tax: 'Thông tin liên quan đến thuế.',
      automaker: 'Nhà sản xuất của xe.',
      AccountID: 'ID tài khoản của người dùng.',
      AccountNumber: 'Số tài khoản của người dùng.',
      etag: 'Thẻ điện tử hoặc mã nhận dạng cho việc thu phí.',
      old_cccd_issuance_date: 'Ngày cấp thẻ chứng minh thư/căn cước cũ',
      old_cccd_issuance_place: 'Nơi cấp thẻ chứng minh thư/căn cước cũ',
      cccd_issuance_date: 'Ngày cấp thẻ căn cước',
      cccd_issuance_place: 'Nơi cấp thẻ căn cước',
      tinh_trang_hon_nhan: 'Tình trạng hôn nhân',
      hoc_van: 'Tình trạng học vấn',
      parrent_job: 'Nghề nghiệp của bố/mẹ/người giám hộ.',
      employment_type: 'Loại biên chế nghề nghiệp',
      recruitment_method: 'Phương thức tuyển dụng',
      recruitment_date: 'Ngày tuyển dụng',
      decision_number: 'Số quyết định tuyển dụng',
      issuing_authority: 'Cơ quan phát hành quyết định tuyển dụng',
      truong_lop: 'Tên trường mà người dùng học',
      chuyen_nganh: 'Chuyên ngành học',
      nam_tot_nghiep: 'Năm tốt nghiệp',
      loai_tot_nghiep: 'Loại tốt nghiệp',
      lever_english: 'Trình độ tiếng anh',
      parent_cccd_1: 'Số chứng minh nhân dân hoặc căn cước công dân của bố hoặc người giám hộ 1',
      parent_old_cccd_1: 'Số CMND hoặc CCCD cũ của bố hoặc người giám hộ 1.',
      parent_cccd_2: 'Số chứng minh nhân dân hoặc căn cước công dân của mẹ hoặc người giám hộ 2.',
      parent_old_cccd_2: 'Số CMND hoặc CCCD cũ của mẹ hoặc người giám hộ 2.',
      vo_chong_name: 'Tên của vợ hoặc chồng.',
      vo_chong_old_cccd: 'Số CMND hoặc CCCD cũ của vợ hoặc chồng.',
      vo_chong_cccd: 'Số chứng minh nhân dân hoặc căn cước công dân của vợ hoặc chồng.',
      chu_ho_name: 'Tên của chủ hộ.',
      chu_ho_old_cccd: 'Số CMND hoặc CCCD cũ của chủ hộ.',
      chu_ho_cccd: 'Số chứng minh nhân dân hoặc căn cước công dân của chủ hộ.',
      ma_quan_huyen: 'Mã Quận huyện nơi ở hiện tại của người dùng.',
      ma_phuong_xa: 'Mã Phường xã nơi ở hiện tại của người dùng.',
      related_name_2: 'Tên của người liên quan.',
      related_cccd_2: 'Số CMND hoặc CCCD của người liên quan.',
      ma_so_nuoc: 'Mã số nước của người dùng.',
      bank_name: 'Tên ngân hàng.',
      ten_tk_bank: 'Tên tài khoản ngân hàng.',
      so_nhan_khau: 'Số nhân khẩu trong cùng hộ gia đình.',
      so_ho_dung_chung: 'Số hộ dùng chung 1 hoá đơn.',
      employee_code: 'Mã số nhân viên.',
      telegram_id: 'Id telegram của người dùng',
      children_name_1: 'Tên con 1 của người dùng',
      children_dob_1: 'Ngày sinh con 1 của người dùng',
      dang_vien: 'Đảng viên',
      the_dang_vien: 'Số thẻ đảng viên',
      ngay_vao_dang: 'Ngày vào đảng',
      related_address: 'Nơi ở của người liên quan',
    };

    return registry[field];
  };
  const proccessField = Object.entries(identity)
    .map(([key, value]): { field: string; title: string; value: string[] } | undefined => {
      // Loại bỏ facebook_user_id và các giá trị rỗng
      if (
        key !== 'imgUrl' &&
        key !== 'STT' &&
        key !== 'gender' &&
        key !== 'GT' &&
        key !== 'ma_so_dien' &&
        key !== 'ma_so_nuoc' &&
        key !== 'typecard' &&
        !_.isEmpty(value)
      ) {
        return { field: key, title: getDescription({ field: key }), value };
      }
    })
    .filter(Boolean); // Loại bỏ các giá trị undefined
  const proccessField2 = proccessField.filter((key) => key !== undefined);
  const rightColumn = proccessField2.slice(0, Math.floor(proccessField.length / 2));
  const leftColumn = proccessField2.slice(Math.floor(proccessField.length / 2), proccessField.length);
  return (
    <Card
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: 'background.main',
        backdropFilter: 'blur(40px)',
        fontSize: '16px',
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'action.focus' }} aria-label="recipe">
            <CardMedia>
              <Avatar />
            </CardMedia>
          </Avatar>
        }
        action={
          identity?.name && (
            <IconButton
              aria-label="copy-content"
              onClick={() => {
                navigator.clipboard.writeText(identity.name[0]);
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          )
        }
        title={<Typography sx={{ fontSize: '1.2em' }}>{identity?.name ? identity?.name[0] : ''}</Typography>}
        subheader={<Typography sx={{ fontSize: '1em' }}>{identity?.cccd ? identity?.cccd[0] : ''}</Typography>}
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PhotoProvider>
              <PhotoView
                src={
                  !_.isEmpty(identity?.imgUrl)
                    ? identity.imgUrl[0]
                    : 'https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000'
                }
              >
                <CardMedia
                  sx={{ borderRadius: '100rem' }}
                  component="img"
                  image={
                    !_.isEmpty(identity?.imgUrl)
                      ? identity.imgUrl[0]
                      : 'https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000'
                  }
                  alt="Avatar"
                  className="!rounded-full"
                />
              </PhotoView>
            </PhotoProvider>

            {identity?.imgUrl && !_.isEmpty(identity?.imgUrl[1]) && (
              <>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <PhotoProvider>
                    {identity?.imgUrl.slice(1, 5).map((img: string, index: number) => (
                      <PhotoView key={img} src={img}>
                        <Grid item xs={3}>
                          <Box component="img" src={img} sx={{ height: '80px', width: '80px', borderRadius: 1 }} />
                        </Grid>
                      </PhotoView>
                    ))}
                  </PhotoProvider>
                </Grid>
                {identity?.imgUrl.length > 5 && (
                  <Box
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    sx={{
                      mt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <Typography variant="caption">{t('management.training.seeAll')}</Typography>
                    <EastIcon sx={{ ml: 1 }} />
                  </Box>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomizableList items={items} />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <InfoIcon sx={{ fontSize: '3rem' }} />
        </IconButton>

        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon sx={{ fontSize: '3rem' }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ textAlign: 'left' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {rightColumn.map((item) => (
                <React.Fragment key={Math.random()}>
                  <Typography fontSize={14} sx={{ color: 'text.secondary' }}>
                    {item?.title}
                  </Typography>
                  <Typography paragraph sx={{ ml: 1 }}>
                    {item?.value.map((itemChild) => (
                      <Typography key={itemChild} fontSize={16}>
                        {itemChild}
                      </Typography>
                    ))}
                  </Typography>
                </React.Fragment>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              {leftColumn &&
                leftColumn.map((item) => (
                  <React.Fragment key={Math.random()}>
                    <Typography fontSize={14} sx={{ color: 'text.secondary' }}>
                      {item?.title}
                    </Typography>
                    {item?.field === 'sex' ? (
                      <Typography paragraph sx={{ ml: 1 }} fontSize={16}>
                        {item?.value[0]}
                      </Typography>
                    ) : (
                      <Typography paragraph sx={{ ml: 1 }}>
                        {item?.value &&
                          item?.value.map((itemChild) => (
                            <Typography key={itemChild} fontSize={16}>
                              {itemChild}
                            </Typography>
                          ))}
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <Photos openDialog={openDialog} onDialogChange={setOpenDialog} listImg={identity?.imgUrl} />
    </Card>
  );
};
