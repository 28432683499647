/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Box, Dialog, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { RedditPostsTypes } from '@/types/Social';

import TitlePostsReddit from './TitlePostsReddit';

interface PostsRedditDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: RedditPostsTypes;
}

const PostsRedditDetail: React.FC<PostsRedditDetailProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detailSelected,
}) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.url}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2 }}>
          <TitlePostsReddit item={detailSelected} />
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                letterSpacing: '0.01em',
              }}
            >
              <HighlightTextSocial text={detailSelected?.title} keyword={topicSocial?.key_word_main} />
            </Typography>
            <Typography
              variant="body2"
              sx={{
                letterSpacing: '0.01em',
                mt: 2,
                color: 'text.secondary',
              }}
            >
              <HighlightTextSocial text={detailSelected?.content} keyword={topicSocial?.key_word_main} />
            </Typography>
            <Typography
              variant="body2"
              sx={{
                letterSpacing: '0.01em',
                mt: 2,
                color: 'text.secondary',
              }}
            >
              {detailSelected?.description}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PostsRedditDetail;
