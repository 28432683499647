import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Typography,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Breadcrumbs,
  Link,
  Grid,
  useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useAuth from '@/Hooks/api/useAuth';
import useNotify from '@/Hooks/common/useNotify';
import { decrypt } from '@/utils/crypto';

interface AddUserProps {
  setIsAddUser: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}
interface IUser {
  email: string;
  permission: string;
  expire_date_license: string;
  group_name: string;
}

interface IDomain {
  name_company: string;
  name_client: string;
  main_domain: string;
  country: string;
  address: string;
}

const AddUser: React.FC<AddUserProps> = ({ setIsAddUser, handleGetUser, handleGetCompany }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onNotify } = useNotify();
  const { handleCreateUser, handleCheckUserExist } = useAuth();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [valueRole, setValueRole] = useState(inforUser.role === 'super admin' ? 'manager' : 'member');
  const [listUser, setListUser] = useState<IUser[]>([]);
  const [listDomain, setListDomain] = useState<IDomain[]>([]);

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRole((event.target as HTMLInputElement).value);
  };

  const navigateSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setIsAddUser(false);
    setListUser([]);
    setListDomain([]);
    setValueUser('email', '');
    setValueUser('expireDate', '');
    setValueRole('member');
    setValueUser('groupName', '');
  };

  const validationSchemaUserSuperAdmin = yup.object().shape({
    email: yup.string().email().required(t('validation.fieldRequired')),
    expireDate: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string().required(t('validation.fieldRequired')),
  });

  const validationSchemaUserManager = yup.object().shape({
    email: yup.string().email().required(t('validation.fieldRequired')),
    expireDate: yup.string(),
    groupName: yup.string(),
  });

  const validationSchemaDomain = yup.object().shape({
    companyName: yup.string().required(t('validation.fieldRequired')),
    mainDomain: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?!:\/\/)([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/, t('validation.invalidDomain')),
    clientName: yup.string().required(t('validation.fieldRequired')),
    country: yup.string().required(t('validation.fieldRequired')),
    address: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string(),
  });

  const {
    handleSubmit: handleSubmitFormUser,
    control: controlFormUser,
    setValue: setValueUser,
  } = useForm({
    resolver: yupResolver(
      inforUser.role === 'super admin' ? validationSchemaUserSuperAdmin : validationSchemaUserManager,
    ),
    defaultValues: {
      groupName: inforUser.role === 'super admin' ? '' : 'groupName',
    },
  });

  const {
    handleSubmit: handleSubmitFormDomain,
    control: controlFormDomain,
    setValue: setValueDomain,
  } = useForm({
    resolver: yupResolver(validationSchemaDomain),
  });

  const onSubmitUser = (data: any, event: any) => {
    const buttonType = event.target.name;

    const UserWithListDomain = {
      username: data.email.trim(),
      email: data.email.trim(),
      expire_date_license: inforUser.role === 'super admin' ? data.expireDate.trim() : inforUser.expireDate,
      permission: valueRole,
      group_name: inforUser.role === 'super admin' ? data.groupName.trim() : '',
      list_company: inforUser.role === 'super admin' ? listDomain : [],
    };

    if (buttonType === 'addUser') {
      const navigateSuccessCheckUser = () => {
        if (inforUser.role === 'super admin' && _.isEmpty(listDomain)) {
          onNotify('warning', t('notify.addClient'), 3000);
        } else {
          handleCreateUser([UserWithListDomain], navigateSuccess);
        }
      };
      handleCheckUserExist({ username: data.email.trim() }, navigateSuccessCheckUser);
    } else if (buttonType === 'addMore') {
      const navigateSuccessCheckUser = () => {
        const isUsernameExists = listUser.some((user) => user.email === UserWithListDomain.email);
        if (isUsernameExists) {
          onNotify('warning', t('notify.duplicateEmail'), 3000);
        } else {
          setListUser((prevList) => [...prevList, UserWithListDomain]);
        }

        setValueUser('email', '');
        setValueUser('expireDate', '');
        // setValueRole('member');
        setValueUser('groupName', '');
      };
      handleCheckUserExist({ username: data.email.trim() }, navigateSuccessCheckUser);
    }
  };

  const onSubmitDomain = (data: any, event: any) => {
    const newDomain = {
      name_company: data.companyName.trim(),
      name_client: data.clientName.trim(),
      main_domain: data.mainDomain.trim(),
      country: data.country.trim(),
      address: data.address.trim(),
    };
    setListDomain((prevList) => [...prevList, newDomain]);
    setValueDomain('companyName', '');
    setValueDomain('clientName', '');
    setValueDomain('mainDomain', '');
    setValueDomain('country', '');
    setValueDomain('address', '');
  };

  return (
    <>
      {!_.isEmpty(listUser) && (
        <Box sx={{ borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover' }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{t('role.role')}</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listUser.map((row: IUser) => (
                  <TableRow key={row.email} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{row.email}</TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.permission}</TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip title={t('tooltip.delete')} placement="left">
                        <DeleteIcon
                          onClick={() => {
                            setListUser((prevList) => prevList.filter((user) => user.email !== row.email));
                          }}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'error.main',
                            },
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            type="submit"
            onClick={() => {
              handleCreateUser(listUser, navigateSuccess);
            }}
            sx={{
              mt: 1,
              textTransform: 'initial',
              color: '#02bb9f',
              border: `1px solid ${theme.palette.divider}`,
              bgcolor: 'action.hover',
              '&:hover': {
                bgcolor: 'action.disabled',
              },
            }}
          >
            {t('action.addAll')}
          </Button>
        </Box>
      )}
      <Box sx={{ borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover', boxShadow: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mt: 1 }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link
                underline="hover"
                key="1"
                color="inherit"
                onClick={() => {
                  setIsAddUser(false);
                }}
                sx={{ cursor: 'pointer', fontSize: '1.2rem' }}
              >
                {t('breadcrumb.addUser.listUser')}
              </Link>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {t('breadcrumb.addUser.addUser')}
              </Typography>
              ,
            </Breadcrumbs>
          </Box>
          <Box sx={{ display: 'flex' }}>
            {_.isEmpty(listUser) && (
              <Button
                type="submit"
                name="addUser"
                variant="contained"
                color="success"
                onClick={handleSubmitFormUser(onSubmitUser)}
                sx={{
                  textTransform: 'initial',
                  color: 'common.white',
                  fontSize: '1.2rem',
                }}
              >
                {t('action.addUser')}
              </Button>
            )}
            {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
              <Button
                type="submit"
                name="addMore"
                variant="contained"
                color="info"
                onClick={handleSubmitFormUser(onSubmitUser)}
                sx={{
                  ml: 2,
                  textTransform: 'initial',
                  color: 'common.white',
                  fontSize: '1.2rem',
                }}
              >
                {t('action.addMore')}
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '100%', marginTop: 1, marginBottom: 2 }}>
          <form onSubmit={handleSubmitFormUser(onSubmitUser)}>
            <Grid container sx={{ marginTop: { md: 1, xs: 0 }, justifyContent: 'space-between' }}>
              <Grid item xs={12} md={5.5} sx={{ marginTop: { md: 0, xs: '20px' } }}>
                <Controller
                  name="email"
                  control={controlFormUser}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      style={{ width: '100%' }}
                      label="E-mail"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      id="input-with-icon-textfield"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5.5} sx={{ marginTop: { md: 0, xs: '20px' } }}>
                <FormControl
                  sx={{
                    marginRight: '20px',
                    '.MuiFormLabel-root': {
                      fontSize: '1rem',
                    },
                  }}
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">{t('role.role')}</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={valueRole}
                    onChange={handleChangeRole}
                  >
                    {(inforUser?.role === 'manager' || inforUser?.role === 'admin') && (
                      <FormControlLabel
                        value="member"
                        control={<Radio />}
                        label={t('role.member')}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '1.4rem',
                          },
                        }}
                      />
                    )}
                    {inforUser?.role === 'super admin' && (
                      <FormControlLabel
                        value="manager"
                        control={<Radio />}
                        label={t('role.manager')}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '1.4rem',
                          },
                        }}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {inforUser?.role === 'super admin' && (
              <Grid container sx={{ marginTop: { md: 1, xs: 0 }, justifyContent: 'space-between' }}>
                <Grid item xs={12} md={5.5} sx={{ marginTop: { md: 0, xs: '20px' } }}>
                  <Controller
                    name="groupName"
                    control={controlFormUser}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="text"
                        style={{ width: '100%' }}
                        label={t('placeholder.groupName')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <GroupsIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5.5} sx={{ marginTop: { md: 0, xs: '20px' } }}>
                  <Controller
                    name="expireDate"
                    control={controlFormUser}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="date"
                        style={{ width: '100%' }}
                        label={t('placeholder.expireDate')}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        id="input-with-icon-textfield"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          min: new Date().toISOString().split('T')[0],
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </form>
          {inforUser?.role === 'super admin' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                mt: 2,
              }}
            >
              {!_.isEmpty(listDomain) && (
                <Box sx={{ borderRadius: 1, mt: 3, padding: 1, backgroundColor: 'action.hover' }}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('table.company.company')}</TableCell>
                          <TableCell>{t('table.company.client')}</TableCell>
                          <TableCell>{t('table.company.domain')}</TableCell>
                          <TableCell>{t('table.company.country')}</TableCell>
                          <TableCell>{t('table.company.address')}</TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listDomain.map((row: IDomain) => (
                          <TableRow key={row.main_domain} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{row.name_company}</TableCell>
                            <TableCell>{row.name_client}</TableCell>
                            <TableCell>{row.main_domain}</TableCell>
                            <TableCell>{row.country}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell component="th" scope="row">
                              <Tooltip title={t('tooltip.delete')} placement="left">
                                <DeleteIcon
                                  onClick={() => {
                                    setListDomain((prevList) =>
                                      prevList.filter((domain) => domain.main_domain !== row.main_domain),
                                    );
                                  }}
                                  sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                      color: 'error.main',
                                    },
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              <form onSubmit={handleSubmitFormDomain(onSubmitDomain)}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    padding: 1,
                    borderRadius: '10px',
                    border: `1px solid ${theme.palette.divider}`,
                    mt: 2,
                  }}
                >
                  <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="companyName"
                        control={controlFormDomain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.companyName')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="clientName"
                        control={controlFormDomain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.clientName')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="mainDomain"
                        control={controlFormDomain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.mainDomain')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="country"
                        control={controlFormDomain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.country')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: { md: 1, xs: 0 } }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="address"
                        control={controlFormDomain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            style={{ width: '100%' }}
                            label={t('placeholder.address')}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            id="input-with-icon-textfield"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', mt: 2, paddingBottom: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      name="addMoreDomain"
                      onClick={handleSubmitFormDomain(onSubmitDomain)}
                      sx={{
                        textTransform: 'initial',
                        color: 'common.white',
                        fontSize: '1.2rem',
                      }}
                    >
                      {t('action.addClient')}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
export default AddUser;
