import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { ThreadsPostsTypes } from '@/types/Social';

import PostsThreadsDetail from './PostsThreadsDetail';
import TitlePostsThreads from './TitlePostsThreads';

const initialPost: ThreadsPostsTypes = {
  link: '',
  post_time: '',
  post_content: '',
  text: '',
  id: '',
  pk: '',
  code: '',
  username: '',
  user_avatar: '',
  user_verified: true,
  user_pk: '',
  user_id: '',
  has_audio: null,
  reply_count: 0,
  like_count: 0,
  images: [],
  videos: [],
  process: true,
  replies: [],
  sentiment: 'neutral',
};

interface PostsThreadsProps {
  item: ThreadsPostsTypes;
}

const PostsThreads: React.FC<PostsThreadsProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<ThreadsPostsTypes>(initialPost);

  return (
    <>
      <Box>
        <TitlePostsThreads item={item} />
        <Box sx={{ mt: 2, cursor: 'pointer' }}>
          <Typography
            variant="body2"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              letterSpacing: '0.01em',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 5,
              textOverflow: 'ellipsis',
              color: 'text.secondary',
            }}
          >
            <HighlightTextSocial text={item.post_content} keyword={topicSocial?.key_word_main} />
          </Typography>
          <Typography
            variant="caption"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              mt: 0.5,
              width: 'fit-content',
              cursor: 'pointer',
              color: 'text.secondary',
              ':hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
              fontStyle: 'italic',
            }}
          >
            {t('action.showMore')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2, display: 'flex' }}>
        <Stack direction="row" sx={{ color: 'text.secondary' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="m8.962 18.91l.464-.588zM12 5.5l-.54.52a.75.75 0 0 0 1.08 0zm3.038 13.41l.465.59zm-5.612-.588C7.91 17.127 6.253 15.96 4.938 14.48C3.65 13.028 2.75 11.335 2.75 9.137h-1.5c0 2.666 1.11 4.7 2.567 6.339c1.43 1.61 3.254 2.9 4.68 4.024zM2.75 9.137c0-2.15 1.215-3.954 2.874-4.713c1.612-.737 3.778-.541 5.836 1.597l1.08-1.04C10.1 2.444 7.264 2.025 5 3.06C2.786 4.073 1.25 6.425 1.25 9.137zM8.497 19.5c.513.404 1.063.834 1.62 1.16s1.193.59 1.883.59v-1.5c-.31 0-.674-.12-1.126-.385c-.453-.264-.922-.628-1.448-1.043zm7.006 0c1.426-1.125 3.25-2.413 4.68-4.024c1.457-1.64 2.567-3.673 2.567-6.339h-1.5c0 2.198-.9 3.891-2.188 5.343c-1.315 1.48-2.972 2.647-4.488 3.842zM22.75 9.137c0-2.712-1.535-5.064-3.75-6.077c-2.264-1.035-5.098-.616-7.54 1.92l1.08 1.04c2.058-2.137 4.224-2.333 5.836-1.596c1.659.759 2.874 2.562 2.874 4.713zm-8.176 9.185c-.526.415-.995.779-1.448 1.043s-.816.385-1.126.385v1.5c.69 0 1.326-.265 1.883-.59c.558-.326 1.107-.756 1.62-1.16z"
            />
          </svg>

          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.like_count}</Typography>
        </Stack>

        <Stack direction="row" sx={{ color: 'text.secondary', ml: 4 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024">
            <path
              fill="currentColor"
              d="m174.72 855.68l130.048-43.392l23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352c0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8l-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416s-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"
            />
          </svg>
          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.reply_count}</Typography>
        </Stack>
      </Box>

      <PostsThreadsDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsThreads;
