import { ReactElement, useState } from 'react';

import { Box } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import DateRange from '@/components/input/DateRange';
import MenuTopic from '@/components/Social/MenuTopic';
import Facebook from '@/components/Social/statistics/facebook/Facebook';
import Forum from '@/components/Social/statistics/forum/Forum';
import Newspaper from '@/components/Social/statistics/newspaper/Newspaper';
import Overview from '@/components/Social/statistics/overview/Overview';
import Tiktok from '@/components/Social/statistics/tiktok/Tiktok';
import Youtube from '@/components/Social/statistics/youtube/Youtube';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';

const Statistics = (): ReactElement => {
  const { t } = useTranslation();

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' } }}>
        <MenuTopic />
        <DateRange />
      </Box>

      <Box sx={{}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            value={valueTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <StyledTab label={t('smcc.overview.overview')} />
            <StyledTab label="Facebook" />
            <StyledTab label={t('smcc.overview.forum')} />
            <StyledTab label="TikTok" />
            <StyledTab label="Youtube" />
            <StyledTab label={t('smcc.overview.news')} />
          </StyledTabs>
        </Box>
        <Box sx={{ mt: 3 }}>
          {valueTab === 0 && <Overview />}
          {valueTab === 1 && <Facebook />}
          {valueTab === 2 && <Forum />}
          {valueTab === 3 && <Tiktok />}
          {valueTab === 4 && <Youtube />}
          {valueTab === 5 && <Newspaper />}
        </Box>
      </Box>
    </>
  );
};
export default Statistics;
