import { useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  Box,
  Button,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';

import {
  CertificatesTypes,
  DomainStatTypes,
  IpStatTypes,
  ListTypes,
  RegDomainStatTypes,
  StatsTypes,
  SubDomainTypes,
} from '@/types/Osint';

interface UrlscanProps {
  data: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DomainInformation: React.FC<UrlscanProps> = ({ data }) => {
  const theme = useTheme();

  const {
    lists: dataLists,
    stats: dataStats,
    ipStats: dataIpStats,
  } = useMemo<{
    lists?: ListTypes | undefined;
    stats?: StatsTypes | undefined;
    ipStats?: IpStatTypes[] | undefined;
  }>(() => {
    return {
      lists: data?.lists,
      stats: data?.stats,
      ipStats: data?.stats?.ipStats,
    };
  }, [data]);

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const convertTimestamp = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000); // Chuyển đổi từ giây sang mili giây
    return date.toISOString().split('T')[0]; // Trả về ngày theo định dạng YYYY-MM-DD
  };

  function calculateDuration(validFrom: number, validTo: number) {
    // Chuyển đổi từ Unix timestamp sang Date object
    const startDate = new Date(validFrom * 1000);
    const endDate = new Date(validTo * 1000);

    // Tính toán số ngày giữa hai ngày
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 15) {
      return `${diffDays} ngày`;
    }

    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    // Tính toán số tháng và số năm giữa hai ngày
    let months = (endYear - startYear) * 12 + (endMonth - startMonth);
    if (endDate.getDate() < startDate.getDate()) {
      // eslint-disable-next-line no-plusplus
      months--; // Điều chỉnh nếu ngày của endDate nhỏ hơn ngày của startDate
    }

    if (months < 12) {
      return `${months} tháng`;
    }

    const years = Math.floor(months / 12);
    return `${years} năm`;
  }

  function formatSize(size: number) {
    if (size < 1024 * 1024) {
      // Nếu nhỏ hơn 1 MB, chuyển đổi sang KB
      return `${(size / 1024).toFixed(2)} KB`;
    }
    // Nếu lớn hơn hoặc bằng 1 MB, chuyển đổi sang MB
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="simple tabs example"
          sx={{
            '.MuiTab-root': {
              textTransform: 'initial',
            },
          }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="IP/ASNs" {...a11yProps(0)} />
          <Tab label="IP Details" {...a11yProps(1)} />
          <Tab label="Domains" {...a11yProps(2)} />
          <Tab label="Domain Tree" {...a11yProps(3)} />
          <Tab label="Links" {...a11yProps(4)} />
          <Tab label="Certs" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={valueTab} index={0}>
        {!_.isEmpty(dataIpStats) ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SwapHorizIcon sx={{ fontSize: '2rem' }} />
                  </TableCell>
                  <TableCell align="right">IP Address</TableCell>
                  <TableCell align="left">
                    ASN
                    <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                      Autonomous System
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataIpStats?.map((row: IpStatTypes) => (
                  <TableRow key={row?.ip} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.requests}
                    </TableCell>
                    <TableCell align="right">{row.asn?.ip}</TableCell>
                    <TableCell align="left" sx={{ display: 'flex' }}>
                      <Typography variant="body2" sx={{ fontWeight: 500, color: 'primary.main' }}>
                        {row.asn?.asn}
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1 }}>{`(${row.asn?.name})`}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            No data
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={valueTab} index={1}>
        {!_.isEmpty(dataIpStats) ? (
          <>
            {dataIpStats?.map((row: IpStatTypes, index: number) => (
              <Box
                key={row.ip}
                sx={{
                  padding: 1,
                  border: `1px solid ${theme.palette.divider}`,
                  mt: index > 0 ? 2 : 0,
                }}
              >
                <Box sx={{ display: 'flex', mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {row.requests}
                  </Typography>
                  <SwapHorizIcon sx={{ ml: 1 }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {row.ip}
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {`(${row.geoip?.city} ${row.geoip?.country_name})`}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', mt: 2 }}>
                  <Typography variant="body2" sx={{ color: 'primary.main' }}>{`ASN${row.asn?.asn}`}</Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>{`(${row.asn?.description})`}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  {row.domains?.map((domain: string) => (
                    <Typography variant="body2" key={domain} sx={{ mt: 1, color: 'primary.main' }}>
                      {domain}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            No data
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={valueTab} index={2}>
        {!_.isEmpty(dataStats?.regDomainStats) ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SwapHorizIcon sx={{ fontSize: '2rem' }} />
                  </TableCell>
                  <TableCell align="left" sx={{ display: 'flex', flexDirection: 'column' }}>
                    Apex Domain
                    <Typography variant="caption">Subdomains</Typography>
                  </TableCell>
                  <TableCell align="right">Transfer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataStats?.regDomainStats?.map((row: RegDomainStatTypes) => (
                  <TableRow key={row.regDomain} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.count}
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" sx={{ color: 'primary.main' }}>
                        {row.regDomain}
                      </Typography>
                      {row.subDomains && (
                        <>
                          {row.subDomains.map((subDomain: SubDomainTypes) => (
                            <Box key={subDomain.domain} sx={{ display: 'flex' }}>
                              <Typography variant="caption">{subDomain.country}</Typography>
                              <Typography variant="caption" sx={{ ml: 1 }}>
                                {`${subDomain.domain}.${row.regDomain}`}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">{formatSize(Number(row.encodedSize))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            No data
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={valueTab} index={3}>
        {!_.isEmpty(dataStats?.domainStats) ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SwapHorizIcon sx={{ fontSize: '2rem' }} />
                  </TableCell>
                  <TableCell>Domain</TableCell>
                  <TableCell>Requested by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataStats?.domainStats?.map((row: DomainStatTypes) => (
                  <TableRow key={row.domain} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.count}
                    </TableCell>
                    <TableCell>{row.domain}</TableCell>
                    <TableCell>
                      {row.initiators?.map((initiator: string) => (
                        <Typography variant="body2" key={initiator}>
                          {initiator}
                        </Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            No data
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={valueTab} index={4}>
        {!_.isEmpty(dataLists?.linkDomains) ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Domain</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLists?.linkDomains?.map((row: string) => (
                  <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Link href={row} underline="none">
                        {row}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            No data
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={valueTab} index={5}>
        {!_.isEmpty(dataLists?.certificates) ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ display: 'flex', flexDirection: 'column' }}>
                    Subject
                    <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                      Issuer
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Validity</TableCell>
                  <TableCell align="right">Valid</TableCell>
                  <TableCell align="right"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLists?.certificates?.map((row: CertificatesTypes) => (
                  <TableRow key={row.subjectName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" sx={{ display: 'flex', flexDirection: 'column' }}>
                      {row.subjectName}
                      <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                        {row.issuer}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                        {convertTimestamp(Number(row.validFrom))}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                        {convertTimestamp(Number(row.validTo))}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{calculateDuration(Number(row.validFrom), Number(row.validTo))}</TableCell>
                    <TableCell align="right">
                      <Link href={`https://crt.sh/?CN=${row.subjectName}&match==`} underline="none">
                        <Button
                          variant="contained"
                          color="inherit"
                          onClick={() => {}}
                          sx={{ display: 'flex', textTransform: 'initial', whiteSpace: 'nowrap' }}
                        >
                          <SearchIcon />
                          <Typography variant="body2">crt.sh</Typography>
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            No data
          </Typography>
        )}
      </TabPanel>
    </Box>
  );
};
export default DomainInformation;
