import { ReactElement, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, CircularProgress, debounce, InputBase, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import AddTopic from '@/components/Social/topic/AddTopic';
import TableTopic from '@/components/Social/topic/TableTopic';
import useSocial from '@/Hooks/api/useSocial';
import useSetTopicSocial from '@/Hooks/social/useSetTopicSocial';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

const Topic = (): ReactElement => {
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const theme = useTheme();
  const { t } = useTranslation();
  const { setTopicSocial } = useSetTopicSocial();
  const { handleListTopic } = useSocial();

  const [listTopic, setListTopic] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState(1);
  const [totalTopic, setTotalTopic] = useState(0);
  const [totalTopicFilter, setTotalTopicFilter] = useState(0);
  const [filterTopic, setFilterTopic] = useState<any>([]);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);

  const handleGetListTopic = async (pageValue?: number) => {
    const params = {
      keyword: '',
      page: page ?? pageValue ?? pageFilter,
      page_size: PAGE_SIZE,
    };

    const res = await handleListTopic(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_topic, total } = res;
    setListTopic(list_topic);
    setTotalTopic(total);
    setTopicSocial(list_topic[0]);
  };

  useEffect(() => {
    handleGetListTopic();
  }, [page]);

  const handleFilterTopic = async (searchKey?: string, pageValue?: number) => {
    const params = {
      keyword: searchKey ?? searchValue,
      page: pageValue ?? pageFilter,
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleListTopic(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_topic, total } = dataRes;
    setFilterTopic(list_topic);
    setTotalTopicFilter(total);
    setLoadingFilter(false);
  };

  const fetchFilterTopic = useMemo(
    () =>
      debounce(async (search) => {
        if (search !== '' && search.length > 2) {
          handleFilterTopic(search);
        } else {
          setLoadingFilter(false);
          setFilterTopic([]);
        }
      }, 2000),
    [],
  );

  const handleInputChange = (event: any) => {
    setPageFilter(1);
    setSearchValue(event.target.value);
    fetchFilterTopic(event.target.value);
    if (event.target.value.length > 2) {
      setLoadingFilter(true);
    }
    if (event.target.value === '') {
      setFilterTopic([]);
      handleGetListTopic();
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {t('smcc.titleTopic')}
      </Typography>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            marginBottom: inforUser.role === 'super admin' ? '40px' : 0,
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '12px 12px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={
                <>
                  {loadingFilter && <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />}
                  {searchValue && !loadingFilter && (
                    <ClearIcon
                      onClick={() => {
                        setSearchValue('');
                        setPage(1);
                        setPageFilter(1);
                        setFilterTopic([]);
                      }}
                      sx={{
                        mr: 3,
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              }
              value={searchValue}
              onChange={handleInputChange}
            />
          </Box>
          {inforUser.role !== 'super admin' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="button"
              onClick={() => {
                setOpenDialogAdd(true);
              }}
              sx={{
                width: 'fit-content',
                ml: 2,
                borderRadius: '5px',
                textTransform: 'none',
                backgroundColor: 'action.selected',
                color: '#02bb9f',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                whiteSpace: 'nowrap',
              }}
            >
              <AddIcon sx={{ mr: 0.5 }} />
              {t('action.addTopic')}
            </Button>
          )}
        </Box>
        {searchValue !== '' && searchValue.length > 2 ? (
          <TableTopic
            listTopics={filterTopic}
            onSearchValueChange={setSearchValue}
            onPageChange={setPageFilter}
            page={pageFilter}
            searchValue={searchValue}
            handleGetListTopic={handleGetListTopic}
            handleFilterTopic={handleFilterTopic}
            totalTopic={totalTopicFilter}
            loadingFilter={loadingFilter}
          />
        ) : (
          <TableTopic
            listTopics={listTopic}
            onSearchValueChange={setSearchValue}
            onPageChange={setPage}
            page={page}
            searchValue={searchValue}
            handleGetListTopic={handleGetListTopic}
            handleFilterTopic={handleFilterTopic}
            totalTopic={totalTopic}
            loadingFilter={loadingFilter}
          />
        )}
      </Box>
      <AddTopic openDialog={openDialogAdd} onDialogChange={setOpenDialogAdd} handleGetListTopic={handleGetListTopic} />
    </>
  );
};
export default Topic;
