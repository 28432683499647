import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Box, Typography, TextField, List, ListItemText, ListItem } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import GoogleAuthenticator from '@/assets/image/Google-Authenticator.png';
import AosComponent from '@/components/animation/AosComponent';
import useAuth from '@/Hooks/api/useAuth';

interface Verify2FAProps {
  username: string;
  password: string;
  qrcode: string;
  setVerify2FA: (newValue: boolean) => void;
}

interface FormData {
  verifyCode: string;
}

const Verify2FA: React.FC<Verify2FAProps> = ({ username, password, qrcode, setVerify2FA }) => {
  const { handleVerification } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const navigateSuccess = () => {
    const from = location.state?.from?.pathname || '/';
    navigate(from, { replace: true });
  };

  const validationSchema = yup.object({
    verifyCode: yup
      .string()
      .matches(/^\d{6}$/, t('validation.verifyCode'))
      .required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const params = {
      username,
      password,
      token: data.verifyCode,
    };
    handleVerification(params, navigateSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  return (
    <>
      <AosComponent dataAos="zoom-in">
        <Box
          sx={{
            width: { md: '600px', xs: '100%' },
            mt: { md: '40px', xs: '110px' },
            padding: 2,
            backgroundColor: 'background.main',
            boxShadow: 10,
            borderRadius: '8px',
            mb: { md: 0, xs: 2 },
          }}
        >
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setVerify2FA(false);
            }}
            sx={{ height: 45, alignSelf: 'center', textTransform: 'capitalize', fontSize: '1.4rem', padding: 2 }}
          >
            <ArrowBackIosIcon sx={{ fontSize: '2rem', mr: 0.5 }} />
            {t('action.back')}
          </Button>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary', mt: 2 }}>
            {t('authen.2fa.title')}
          </Typography>
          <Box sx={{ marginBottom: 2 }}>
            <Box sx={{ mt: 2 }}>
              {qrcode !== '' && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="img" src={GoogleAuthenticator} sx={{ width: '45px', height: '30px' }} />
                  <Typography sx={{ ml: 1 }}>Google Authenticator</Typography>
                </Box>
              )}
              <List>
                {qrcode !== '' && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={t('authen.2fa.describeQrcode1')}
                        primaryTypographyProps={{ sx: { fontSize: '1.4rem' } }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={t('authen.2fa.describeQrcode2')}
                        primaryTypographyProps={{ sx: { fontSize: '1.4rem' } }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={t('authen.2fa.describeQrcode3')}
                        primaryTypographyProps={{ sx: { fontSize: '1.4rem' } }}
                      />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}>
                      <Box component="img" src={qrcode} sx={{ height: '200px', width: '200px' }} />
                    </ListItem>
                  </>
                )}
                <Box sx={{ padding: '8px 16px' }}>
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {qrcode !== '' ? t('authen.2fa.verifyCode') : t('authen.2fa.describeVerifyCode')}
                  </Typography>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="verifyCode"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="text"
                          autoFocus
                          variant="outlined"
                          fullWidth
                          style={{ width: '100%', marginTop: '15px' }}
                          label={t('placeholder.verifyCode')}
                          onKeyDown={handleKeyDown}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                        />
                      )}
                    />
                  </form>
                </Box>
              </List>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: '40%',
                mt: 3,
                mb: 2,
                fontWeight: 700,
                height: 45,
                borderRadius: '10px',
                letterSpacing: 1,
                textTransform: 'uppercase',
                fontSize: '1.4rem',
                color: 'common.white',
                backgroundColor: 'rgb(3, 201, 215)',
                transition: 'all .1s ease-in-out',
                '&:hover': {
                  background: 'rgb(5, 178, 189)',
                  transition: 'all .1s ease-in-out',
                },
                '&:active': {
                  transform: 'translate(0, calc(-5 * 1%)) scale(0.95)',
                  transition: 'all .1s ease-in-out',
                },
                whiteSpace: 'nowrap',
              }}
            >
              {t('action.confirm')}
            </Button>
          </Box>
        </Box>
      </AosComponent>
    </>
  );
};
export default Verify2FA;
