import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Box, Typography, TextField, InputAdornment, IconButton, AppBar, Link } from '@mui/material';
import QRCode from 'qrcode';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import BackGroundLight from '@/assets/image/bg-header.png';
import BgLogin from '@/assets/image/BgLogin.png';
import AosComponent from '@/components/animation/AosComponent';
import useAuth from '@/Hooks/api/useAuth';
import useNotify from '@/Hooks/common/useNotify';
import { DARK_MODE_THEME } from '@/utils/constants';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

import ResetPassword from './ResetPassword';
import Verify2FA from './Verify2FA';

const Login = () => {
  const { t } = useTranslation();
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;
  const location = useLocation();
  const { handleLogin } = useAuth();
  const { onNotify } = useNotify();
  const navigate = useNavigate();

  const [isLoginFail, setIsLoginFail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [verify2FA, setVerify2FA] = useState(false);
  const [qrcode, setQrcode] = useState('');

  const state = location.state?.login;
  useEffect(() => {
    if (state === 'mustLogin') {
      onNotify('warning', t('notify.login'), 3000);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [state]);

  const navigateSuccess = () => {
    setVerify2FA(true);
  };

  const validationSchema = yup.object({
    username: yup.string().required(t('validation.fieldRequired')),
    password: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, getValues } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (values: any) => {
    const params = {
      username: values.username.toLowerCase(),
      password: values.password,
    };
    const res = await handleLogin(params, navigateSuccess, setIsLoginFail);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { qr_code } = res || {};
    setQrcode(qr_code ? await QRCode?.toDataURL(qr_code) : '');
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: `url(${themeApp === 'dark' ? BgLogin : BackGroundLight}) center center no-repeat`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          padding: { xs: '0px 20px 0px 20px', md: 0 },
        }}
      >
        <AppBar
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: '90px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            backdropFilter: 'blur(8px)',
          }}
        >
          <Link href="/" rel="noopener" underline="none">
            <Box
              component="img"
              src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
              sx={{
                width: '150px',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
        </AppBar>
        {!isResetPassword ? (
          <>
            {!verify2FA ? (
              <AosComponent dataAos="zoom-in">
                <Box
                  sx={{
                    width: { md: '600px', xs: '100%' },
                    padding: 2,
                    backgroundColor: 'background.main',
                    boxShadow: 10,
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                    {t('action.login')}
                  </Typography>
                  <Box sx={{ marginBottom: 2 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Controller
                        name="username"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type="text"
                            variant="filled"
                            autoFocus
                            style={{ width: '100%', marginTop: '25px' }}
                            label={t('placeholder.username')}
                            onKeyDown={handleKeyDown}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type={showPassword ? 'text' : 'password'}
                            variant="filled"
                            style={{ width: '100%', marginTop: '20px' }}
                            label={t('placeholder.password')}
                            onKeyDown={handleKeyDown}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowPassword((prevShowPassword) => !prevShowPassword);
                                    }}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      {isLoginFail && (
                        <Typography
                          variant="body2"
                          sx={{ marginTop: 2, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
                        >
                          {t('notify.invalidLogin')}
                        </Typography>
                      )}
                    </form>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      variant="body2"
                      onClick={() => {
                        setIsResetPassword(true);
                      }}
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: 'primary.light',
                        },
                      }}
                    >
                      {t('action.forgotPassword')}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      sx={{
                        width: '40%',
                        mt: 3,
                        mb: 2,
                        fontWeight: 700,
                        height: 45,
                        borderRadius: '10px',
                        letterSpacing: 1,
                        textTransform: 'uppercase',
                        fontSize: '1.4rem',
                        color: 'common.white',
                        backgroundColor: 'rgb(3, 201, 215)',
                        transition: 'all .1s ease-in-out',
                        '&:hover': {
                          background: 'rgb(5, 178, 189)',
                          transition: 'all .1s ease-in-out',
                        },
                        '&:active': {
                          transform: 'translate(0, calc(-5 * 1%)) scale(0.95)',
                          transition: 'all .1s ease-in-out',
                        },
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t('action.login')}
                    </Button>
                  </Box>
                </Box>
              </AosComponent>
            ) : (
              <Verify2FA
                username={getValues('username')}
                password={getValues('password')}
                qrcode={qrcode}
                setVerify2FA={setVerify2FA}
              />
            )}
          </>
        ) : (
          <ResetPassword setIsResetPassword={setIsResetPassword} />
        )}
      </Box>
    </>
  );
};
export default Login;
