import { useState } from 'react';

import { Grid, Typography, Button, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useCommonInfo } from '@/contexts/Common';
import useAsm from '@/Hooks/api/useAsm';
import CidrModal from '@/pages/asm/Informations/CidrModal';

interface Props {
  info: any;
}
const AsnDetail: React.FC<Props> = ({ info }) => {
  const { isLoading } = useCommonInfo();
  const { t } = useTranslation();
  const { handleSearchCidr } = useAsm();

  const [cidr, setCidr] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const handleClickAsn = async (asn: string) => {
    const params = {
      domain: asn,
    };
    const dataRes = await handleSearchCidr(params);
    setOpen(true);
    setCidr(dataRes.data);
  };
  const handleCloseCidr = () => setOpen(false);

  return (
    <>
      <Box>
        {isLoading ? (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Skeleton style={{ marginBottom: 10, marginLeft: 10 }} height={40} width="20%" />
            <Skeleton style={{ marginBottom: 10, marginLeft: 10 }} height={40} width="20%" />
          </Box>
        ) : (
          <>
            {!_.isEmpty(info.asn) && (
              <>
                <Grid sx={{ marginTop: '0.5rem', paddingLeft: 3, marginBottom: '1rem' }} container spacing={1}>
                  {info.asn?.map((item: any, index: number) => (
                    <Grid item key={index} sx={{ padding: 2 }}>
                      <Button
                        variant="contained"
                        color="info"
                        sx={{
                          fontSize: '1.2rem',
                          color: 'common.white',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            transition: 'transform 0.3s',
                          },
                        }}
                        onClick={() => {
                          handleClickAsn(item);
                        }}
                      >
                        {item}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            {_.isEmpty(info.asn) && (
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 3,
                  color: 'text.secondary',
                }}
              >
                {t('notify.noResult')}
              </Typography>
            )}
          </>
        )}
      </Box>

      <CidrModal openCidr={open} handleCloseCidr={handleCloseCidr} cidr={cidr} />
    </>
  );
};
export default AsnDetail;
