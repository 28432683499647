import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import useManagenent from '@/Hooks/api/useManagenent';
import { APIKeyType } from '@/types/management';

interface UpdateKeyProps {
  openDialogUpdate: boolean;
  onDialogUpdateChange: (newValue: boolean) => void;
  detail: APIKeyType;
  handleGetListKey: () => void;
}

interface ListStatusProps {
  id: number;
  value: string;
  label: string;
}

const UpdateKey: React.FC<UpdateKeyProps> = ({ openDialogUpdate, onDialogUpdateChange, detail, handleGetListKey }) => {
  const { t } = useTranslation();
  const { handleUpdateAPI } = useManagenent();

  const listStatus: ListStatusProps[] = [
    { id: 1, value: 'active', label: t('management.apiKey.active') },
    { id: 2, value: 'inactive', label: t('management.apiKey.inactive') },
  ];

  const contentRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useState(detail.status);

  const handleChangeType = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (openDialogUpdate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogUpdate]);

  const { handleSubmit } = useForm({});

  const updateSuccess = () => {
    onDialogUpdateChange(false);
    handleGetListKey();
    setStatus(detail.status);
  };

  const onSubmit = async () => {
    const params = {
      id: detail.id,
      status,
    };
    await handleUpdateAPI(params, updateSuccess);
  };

  useEffect(() => {
    setStatus(detail.status);
  }, [detail.status]);

  useEffect(() => {
    if (openDialogUpdate) {
      setStatus(detail.status);
    }
  }, [openDialogUpdate, detail.status]);

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogUpdateChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.apiKey.updateKey')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Id
              </Typography>

              <TextField type="text" variant="outlined" fullWidth defaultValue={detail.id} value={detail.id} disabled />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Key
              </Typography>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                defaultValue={detail.api_key}
                value={detail.api_key}
                disabled
              />
            </Box>

            <Box sx={{ marginTop: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('management.apiKey.status')}
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Select key={detail.status} defaultValue={detail.status} value={status} onChange={handleChangeType}>
                  {listStatus.map((item: ListStatusProps) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </form>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={onSubmit} sx={{ mt: 3 }}>
              {t('action.update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateKey;
