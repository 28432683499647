import { useState } from 'react';

import { Avatar, AvatarGroup, Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import likeIcon from '@/assets/image/facebook-like.png';
import loveIcon from '@/assets/image/facebook-love.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { FacebookPostsTypes } from '@/types/Social';

import PostsFacebookDetail from './PostsFacebookDetail';
import TitlePostsFacebook from './TitlePostsFacebook';

const initialPost: FacebookPostsTypes = {
  comments: 0,
  comments_preview: [],
  group_name: '',
  link: '',
  name: '',
  post_content: '',
  post_time: '',
  reactions: 0,
  sentiment: 'neutral',
  username: '',
};

interface PostsFacebookProps {
  item: FacebookPostsTypes;
}

const PostsFacebook: React.FC<PostsFacebookProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<FacebookPostsTypes>(initialPost);

  return (
    <>
      <Box>
        <TitlePostsFacebook item={item} />
        <Box sx={{ mt: 2, cursor: 'pointer' }}>
          <Typography
            variant="body2"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              letterSpacing: '0.01em',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 5,
              textOverflow: 'ellipsis',
              color: 'text.secondary',
            }}
          >
            <HighlightTextSocial text={item.post_content} keyword={topicSocial?.key_word_main} />
          </Typography>
          <Typography
            variant="caption"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              mt: 0.5,
              width: 'fit-content',
              cursor: 'pointer',
              color: 'text.secondary',
              ':hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
              fontStyle: 'italic',
            }}
          >
            {t('action.showMore')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row">
          <AvatarGroup max={2}>
            <Avatar alt="Like Icon" src={likeIcon} sx={{ width: 19, height: 19 }} />
            <Avatar alt="Love Icon" src={loveIcon} sx={{ width: 19, height: 19 }} />
          </AvatarGroup>
          <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>{item.reactions}</Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
            ':hover p': {
              color: 'primary.main',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            setOpenDialogDetail(true);
            setDetailSelected(item);
          }}
        >
          <Typography sx={{ color: 'text.secondary', fontSize: '14px' }}>{item.comments}</Typography>
          <Typography sx={{ ml: 0.5, color: 'text.secondary', fontSize: '14px' }}>{t('smcc.comment')}</Typography>
        </Box>
      </Box>

      <PostsFacebookDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsFacebook;
