import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { ForumPostsTypes } from '@/types/Social';

import ForumDetail from './ForumDetail';
import TitlePosts from './TitlePosts';

const initialPost: ForumPostsTypes = {
  link: '',
  views: 0,
  replies: 0,
  created_time: '',
  post_content: '',
  author: '',
  threadMessages: [],
  title: '',
  sentiment: '',
};

interface PostsForumProps {
  item: ForumPostsTypes;
}

const PostsForum: React.FC<PostsForumProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<ForumPostsTypes>(initialPost);

  return (
    <>
      <TitlePosts item={item} />
      <Box sx={{ mt: 2, cursor: 'pointer' }}>
        <Typography
          onClick={() => {
            setOpenDialogDetail(true);
            setDetailSelected(item);
          }}
          sx={{
            letterSpacing: '0.01em',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            textOverflow: 'ellipsis',
            fontWeight: 600,
            color: 'text.secondary',
          }}
        >
          <HighlightTextSocial text={item.title} keyword={topicSocial?.key_word_main} />
        </Typography>
      </Box>
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.views}
          </Typography>
          <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
            {t('smcc.views')}
          </Typography>
        </Box>

        <Box
          onClick={() => {
            setOpenDialogDetail(true);
            setDetailSelected(item);
          }}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            color: 'text.secondary',
            ':hover': {
              color: 'primary.main',
              textDecoration: 'underline',
            },
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.replies}
          </Typography>
          <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
            {t('smcc.comment')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 1, cursor: 'pointer' }}>
        <Typography
          variant="body2"
          onClick={() => {
            setOpenDialogDetail(true);
            setDetailSelected(item);
          }}
          sx={{
            letterSpacing: '0.01em',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 5,
            textOverflow: 'ellipsis',
            color: 'text.secondary',
          }}
        >
          <HighlightTextSocial text={item?.post_content} keyword={topicSocial?.key_word_main} />
        </Typography>
        <Typography
          variant="caption"
          onClick={() => {
            setOpenDialogDetail(true);
            setDetailSelected(item);
          }}
          sx={{
            mt: 0.5,
            width: 'fit-content',
            cursor: 'pointer',
            color: 'text.secondary',
            ':hover': {
              color: 'primary.main',
              textDecoration: 'underline',
            },
            fontStyle: 'italic',
          }}
        >
          {t('action.showMore')}
        </Typography>
      </Box>

      <ForumDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsForum;
