import { useEffect, useMemo, useState } from 'react';

import { gapi } from 'gapi-script';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import addNotification from 'react-push-notification';

import Wrapper from './components/common/Wrapper';
import './configs/config-i18n';
import {
  AuthProvider,
  ThemeModeContext,
  CommonProvider,
  ScanSubdomainProvider,
  ScanVulProvider,
  ScanSitemapProvider,
  SocialProvider,
} from './contexts';
import { ReportProvider } from './contexts/Report';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { requestForToken, onMessageListener } from './firebase';
import ListRouter from './routes/routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { DARK_MODE_THEME } from './utils/constants';
import 'animate.css';
import { NEWS_DEFAULT } from './utils/imgBase64';

function App() {
  const { i18n } = useTranslation();
  const theme = localStorage.getItem('theme') || DARK_MODE_THEME;

  const [mode, setMode] = useState(theme);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const fcmToken = JSON.parse(localStorage.getItem('fcmToken') as string);
  const locale = localStorage.getItem('locale') as string;
  const localeNews = localStorage.getItem('locale') || 'vi';

  const handleNotificationClick = () => {
    window.open(`/news/${JSON.parse(notification?.body).path}`, '_blank');
  };

  const handleNotificationScanClick = () => {
    window.open('/sourcescan/results')
  };

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          if (!fcmToken) {
            requestForToken('create');
          }
        } else {
          requestForToken('delete');
        }
      });

      if (notification?.title === 'news') {
        addNotification({
          onClick: handleNotificationClick,
          // title: notification?.title,
          title: 'News - ESS',
          message:
            localeNews === 'vi' ? JSON.parse(notification?.body).title_vi : JSON.parse(notification?.body).title_en,
          icon: JSON.parse(notification?.body).img_url || NEWS_DEFAULT,
          theme: 'darkblue',
          native: true, // when using native, your OS will handle theming.
        });
      }

      if (notification?.title === 'Scan Completed') {
        // Parse the notification body once
        const parsedBody = JSON.parse(notification?.body);
        // Extract just the message ID from the full message string
        const messageId = parsedBody.message.split('-')[0].trim();
        
        addNotification({
          onClick: handleNotificationScanClick,
          title: 'Scan Completed',
          message: localeNews === 'vi'
            ? `Quét thành công cho task ${messageId}`
            : `Scan completed for file ${messageId}`,
          icon: parsedBody.img_url || NEWS_DEFAULT,
          theme: 'darkblue',
          native: true,
        });
      }
    } else {
      console.error('Notifications not supported in this environment.');
    }
  }, [notification]);



  onMessageListener()
    .then((payload: any) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: (themeSelected: string) => {
        setMode(themeSelected);
        localStorage.setItem('theme', themeSelected);
      },
    }),
    [],
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '895399845840-t7kfjumo3j53eucmvhehlgrtqfn5a436.apps.googleusercontent.com',
        scope: '',
      });
    }
    gapi.load('client:auth2', start);
  });

  return (
    <CommonProvider>
      <WebSocketProvider>
        <SocialProvider>
          <ScanVulProvider>
            <ScanSubdomainProvider>
              <ScanSitemapProvider>
                <ReportProvider>
                  <AuthProvider>
                    <ThemeModeContext.Provider value={themeMode}>
                      <ThemeConfig mode={mode}>
                        <GlobalStyles />
                        <SnackbarProvider
                          maxSnack={3}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <Wrapper>
                            <ListRouter />
                          </Wrapper>
                        </SnackbarProvider>
                      </ThemeConfig>
                    </ThemeModeContext.Provider>
                  </AuthProvider>
                </ReportProvider>
              </ScanSitemapProvider>
            </ScanSubdomainProvider>
          </ScanVulProvider>
        </SocialProvider>
      </WebSocketProvider>
    </CommonProvider>
  );
}

export default App;
