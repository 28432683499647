import { useEffect, useRef, useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { NewsDetailType } from '@/types/management';

interface DetailNewsProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detail: NewsDetailType;
}

const DetailNews: React.FC<DetailNewsProps> = ({ openDialogDetail, onDialogDetailChange, detail }) => {
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);
  const [showMessageError, setShowMessageError] = useState(false);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);

  const getKeyName = (key: string) => {
    switch (key) {
      case 'id':
        return 'Id';
      case 'create_time':
        return t('management.news.createTime');
      case 'field':
        return t('management.news.field');
      case 'type_news':
        return t('management.news.typeNews');
      case 'title_vi':
        return t('management.news.titleVi');
      case 'title_en':
        return t('management.news.titleEn');
      case 'excerpt_vi':
        return t('management.news.excerptVi');
      case 'excerpt_en':
        return t('management.news.excerptEn');
      case 'href':
        return t('management.news.href');
      case 'img_url':
        return t('management.news.imgUrl');
      case 'image_url_minio':
        return t('management.news.imgUrlMinio');
      case 'path':
        return t('management.news.path');
      case 'is_status_analytic_news':
        return t('management.news.analytic');
      case 'message_error':
        return t('management.news.messageError');
      default:
        return key;
    }
  };

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogDetailChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.news.detailNews')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          {Object.entries(detail)
            .filter(([key]) => key !== 'is_status_analytic_news' && key !== 'message_error')
            .map(([key, value]) => (
              <Box
                key={key}
                sx={{
                  marginTop: 2,
                  backgroundColor: 'action.hover',
                  padding: 2,
                  borderRadius: 1,
                  display: 'flex',
                }}
              >
                <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                  {`${getKeyName(key)} : `}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2" sx={{ marginLeft: 1.5, fontWeight: 500 }}>
                    {String(value)}
                  </Typography>
                  {(key === 'img_url' || key === 'image_url_minio') && (
                    <Box component="img" src={String(value)} sx={{ height: '100px', width: '180px', mt: 1 }} />
                  )}
                </Box>
              </Box>
            ))}
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${t('management.news.analytic')}:`}
            </Typography>

            <Typography variant="body2" sx={{ marginLeft: 1.5, fontWeight: 500 }}>
              {detail.is_status_analytic_news ? t('management.news.error') : t('management.news.success')}
            </Typography>
          </Box>
          {detail.message_error && (
            <Box
              sx={{
                marginTop: 2,
                backgroundColor: 'action.hover',
                padding: 2,
                borderRadius: 1,
                display: 'flex',
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                {`${t('management.news.messageError')}:`}
              </Typography>

              <Box
                sx={{ marginLeft: 1.5, cursor: 'pointer' }}
                onClick={() => {
                  setShowMessageError(!showMessageError);
                }}
              >
                {showMessageError ? (
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {detail.message_error}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      WebkitLineClamp: 3,
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {detail.message_error}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DetailNews;
