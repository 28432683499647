import { Tabs, TabsProps, styled } from '@mui/material';

interface StyledTabsProps extends TabsProps {
  // Không cần thêm thuộc tính thủ công ở đây trừ khi cần bổ sung gì đặc biệt
}
const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));
export default StyledTabs;
