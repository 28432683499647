/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Avatar, Box, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';

import { DarkwebTypes } from '@/types/dataleak';

interface TitlePostsDarkwebProps {
  item: DarkwebTypes;
}

const TitlePostsDarkweb: React.FC<TitlePostsDarkwebProps> = ({ item }) => {
  const locale = localStorage.getItem('locale') || 'vi';

  return (
    <Stack direction="row" spacing={2}>
      <Avatar />
      <Box>
        <Typography sx={{ fontWeight: 600, color: 'text.primary' }}>{item?.username}</Typography>
        {item?.post_time && (
          <Tooltip
            title={format(new Date(item?.post_time), locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm", {
              locale: locale === 'vi' ? vi : enUS,
            })}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', cursor: 'pointer' }}>
              {format(new Date(item?.post_time), locale === 'vi' ? 'dd MMM' : 'dd MMM ', {
                locale: locale === 'vi' ? vi : enUS,
              })}
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
};
export default TitlePostsDarkweb;
