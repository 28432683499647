import { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField } from '@mui/material';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Validators from '@/helpers/validations/validators';

import { OptionBox, SearchOptionType } from './OptionBox';

interface IProps {
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  register: UseFormRegister<FieldValues>;
  searchOption: SearchOptionType;
  setSearchOption: React.Dispatch<React.SetStateAction<SearchOptionType>>;
}

export default function SearchBoxHandsome({ handleKeyDown, register, searchOption, setSearchOption }: IProps) {
  const { t } = useTranslation();

  const [placeholder, setPlaceholder] = useState<string>(t('placeholder.searchIdentity'));
  const { formatPhone, validateEmail, validateFBId, validatePii, validateUsername, validateBHXH } = Validators();

  useEffect(() => {
    const handleResize = () => {
      setPlaceholder(
        window.innerWidth < 550 ? t('placeholder.searchIdentityMinimum') : t('placeholder.searchIdentity'),
      );
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const updateKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchKeyword(event.target.value);
  // };
  return (
    <TextField
      id="searchKeyword"
      type="text"
      sx={{
        width: '100%',
        borderRadius: '16px',
        backgroundColor: 'action.focus',
        '.MuiInputBase-input': {
          paddingX: '18px',
          paddingY: '18px',
          borderColor: 'transparent',
          fontSize: '16px',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '16px',
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(131 88 255)',
          },
        },
        input: {
          '&::placeholder': {
            opacity: 0.6,
            fontSize: '16px',
          },
        },
      }}
      placeholder={placeholder}
      autoComplete="off"
      onKeyDown={handleKeyDown}
      InputProps={{
        startAdornment: <OptionBox setSearchOption={setSearchOption} searchOption={searchOption} />,
        endAdornment: (
          <Button type="submit" aria-labelledby="submit">
            <SearchIcon sx={{ fontSize: '3rem' }} />
          </Button>
        ),
      }}
      {...register('searchInput', {
        required: t('validation.keywordRequired'),
        validate: {
          validateCustom: (value) => {
            switch (searchOption.value) {
              case 'phone':
                if (formatPhone(value)) return true;
                return t('validation.invalidPhone');
              case 'email':
                if (validateEmail(value)) return true;
                return t('validation.invalidEmail');
              case 'username':
                if (validateUsername(value)) return true;
                return t('validation.invalidUsername');
              case 'pii':
                if (validatePii(value)) return true;
                return t('validation.invalidPII');
              case 'facebook_id':
                if (validateFBId(value)) return true;
                return t('validation.invalidFBId');
              case 'username_twitter':
                if (validateUsername(value)) return true;
                return t('validation.invalidUsername');
              case 'bhxh':
                if (validateBHXH(value)) return true;
                return t('validation.invalidBHXH');
            }
          },
        },
      })}
      // onChange={updateKeyword}
    />
  );
}
