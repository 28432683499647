import { useEffect, useMemo, useState } from 'react';

import { Box, SelectChangeEvent, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import TextFieldOsint from '@/components/common/TextField/TextFieldOsint';
import Behaviour from '@/components/Osint/Urlscan/Behaviour';
import HTTP from '@/components/Osint/Urlscan/HTTP';
import Indicators from '@/components/Osint/Urlscan/Indicators';
import Links from '@/components/Osint/Urlscan/Links';
import Summary from '@/components/Osint/Urlscan/Summary';
import CustomTabPanel from '@/components/Tab/CustomTabPanel';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { formatDate, formatDateAndHours } from '@/configs/date';
import { getToolname } from '@/configs/osintConfig';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useOsint from '@/Hooks/api/useOsint';
import { OsintTypes, ScanUrlTypes, SubmitData } from '@/types/Osint';
import { decrypt, encrypt } from '@/utils/crypto';

const Urlscan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const domainActive = localStorage.getItem('domainActive') && decrypt(localStorage.getItem('domainActive') as string);
  const { dataWebSocketOsint } = useWebSocketContext();
  const [searchParams] = useSearchParams();
  const { handleGetOsint } = useOsint();

  const [datUrlScan, setDataUrlScan] = useState<ScanUrlTypes>();
  const [valueTab, setValueTab] = useState(0);
  const [domain, setDomain] = useState(domainActive || '');

  const filteredListOsint = useMemo(
    () => dataWebSocketOsint.find((item: OsintTypes) => item.domain === domain),
    [dataWebSocketOsint, domain],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (inforUser?.role === 'super admin' && location.search) {
        const res = await handleGetOsint({ domain: String(searchParams.get('domain')) });
        const { data } = res;
        setDataUrlScan(data?.osint?.urlscan);
      } else {
        setDataUrlScan(filteredListOsint?.data?.osint?.urlscan);
      }
    };

    fetchData();
  }, [inforUser?.role, searchParams, filteredListOsint]);

  const { page: dataPage, task: dataTask } = useMemo(() => {
    const urlscan: any = datUrlScan;
    return {
      page: urlscan?.page,
      task: urlscan?.task,
    };
  }, [datUrlScan]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  const submitHandler = async (data: SubmitData) => {
    const params = new URLSearchParams({
      domain: data.searchInput,
    });
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
        {getToolname(location.pathname)}
      </Typography>
      {inforUser?.role === 'super admin' ? (
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextFieldOsint submitHandler={submitHandler} defaultValue={searchParams.get('domain') || ''} />
        </Box>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
          {!_.isEmpty(filteredListOsint?.data?.update_time) && (
            <ScanTime updateTime={filteredListOsint?.data?.update_time} />
          )}
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ fontWeight: 600 }}>{domainActive || searchParams.get('domain')}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{dataPage?.ip}</Typography>
        {dataPage?.ur && (
          <Box sx={{ display: 'flex', mt: 0.5 }}>
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
              URL:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
              {dataPage?.url}
            </Typography>
          </Box>
        )}
        {dataTask?.time && (
          <Box sx={{ display: 'flex', mt: 0.5 }}>
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
              Submission:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
              {`On ${formatDate(dataTask?.time ?? 0)} via ${dataTask?.method} (${formatDateAndHours(
                dataTask?.time ?? 0,
              )})`}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <StyledTab label="Summary" />
          <StyledTab label="HTTP" />
          <StyledTab label="Links" />
          <StyledTab label="Behaviour" />
          <StyledTab label="Indicators" />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={valueTab} index={0}>
        {datUrlScan ? (
          <Summary listUrlscan={datUrlScan} />
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        {datUrlScan ? (
          <HTTP listUrlscan={datUrlScan} />
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={2}>
        {datUrlScan ? (
          <Links listUrlscan={datUrlScan} />
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={3}>
        {datUrlScan ? (
          <Behaviour listUrlscan={datUrlScan} />
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={4}>
        {datUrlScan ? (
          <Indicators listUrlscan={datUrlScan} />
        ) : (
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            No result
          </Typography>
        )}
      </CustomTabPanel>
    </Box>
  );
};
export default Urlscan;
