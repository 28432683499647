import { useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { Box, Chip, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import CustomTabPanel from '@/components/Tab/CustomTabPanel';
import { FofaTypes, ProductTypes } from '@/types/Osint';

interface TabHeaderProductProps {
  fofaItem: FofaTypes;
}

const TabHeaderProduct: React.FC<TabHeaderProductProps> = ({ fofaItem }) => {
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const convertHeaderToMarkdown = (header: string) => {
    return `
  \`\`\`
  ${header.replace(/\r/g, '\n')}
  \`\`\`
    `;
  };

  const groupProductsByCategory = (products: ProductTypes[]) => {
    // Hàm chuyển đổi categoryCode thành tên hiển thị
    const getCategoryName = (categoryCode: string): string => {
      const categoryNames: Record<string, string> = {
        'Y2F0ZWdvcnk9IuacjeWKoSI=': 'Service',
        'Y2F0ZWdvcnk9IuWFtuS7luS8geS4muW6lOeUqCI=': '',
        'Y2F0ZWdvcnk9IuiEmuacrOivreiogCI=': 'Scripting Language',
        'Y2F0ZWdvcnk9Iue7hOS7tiI=': 'Component',
        'Y2F0ZWdvcnk9IuS4remXtOS7tiI=': 'Middleware',
        'Y2F0ZWdvcnk9IuWFtuS7luaUr+aSkeezu+e7nyI=': 'Other Support System',
        'Y2F0ZWdvcnk9IuW8gOWPkeahhuaetiI=': 'Development Framework',
        '//': 'Other Enterprise Application',
        '/': 'Operating System',
        'Y2F0ZWdvcnk9IuaVsOaNruivgeS5piI=': 'Data Certificate',
        '///': 'Electronic Mail System',
      };

      return categoryNames[categoryCode] || 'Unknown Category';
    };
    return Object.values(
      products.reduce((acc, item) => {
        const categoryName = getCategoryName(item.category.code); // Thay thế categoryCode bằng tên hiển thị
        const productName = item.product.name;

        // Nếu chưa có nhóm cho tên category này, tạo một nhóm mới
        if (!acc[categoryName]) {
          acc[categoryName] = {
            categoryCode: categoryName, // Sử dụng tên category thay vì mã code
            products: [],
          };
        }

        // Thêm tên sản phẩm vào nhóm
        acc[categoryName].products.push(productName);

        return acc;
      }, {} as Record<string, { categoryCode: string; products: string[] }>), // Đảm bảo kiểu cho accumulator
    );
  };

  return (
    <>
      <Box
        sx={{
          mt: 2,
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Header" />
          <Tab label="Product" />
        </Tabs>
        {fofaItem.header_hash && (
          <Tooltip title={<Typography variant="caption">{`header_hash: ${fofaItem.header_hash}`}</Typography>}>
            <Chip
              size="small"
              label={fofaItem.header_hash}
              color="primary"
              variant="outlined"
              sx={{
                '.MuiChip-label': {
                  fontSize: '1.2rem',
                },
              }}
            />
          </Tooltip>
        )}
      </Box>
      <CustomTabPanel value={valueTab} index={0}>
        <Typography component="div" variant="body2" sx={{ mt: 2, overflow: 'hidden' }}>
          <ReactMarkdown>{convertHeaderToMarkdown(fofaItem?.header ?? '')}</ReactMarkdown>
        </Typography>
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        {groupProductsByCategory(fofaItem?.products ?? []).map((item: { categoryCode: string; products: string[] }) => (
          <Box key={item.categoryCode} sx={{ mt: 1.5 }}>
            <Typography variant="body2">{item.categoryCode}</Typography>
            <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
              {item.products.map((product: string) => (
                <Chip
                  key={product}
                  size="small"
                  icon={<LockIcon />}
                  label={product}
                  color="default"
                  variant="outlined"
                  sx={{
                    '.MuiChip-icon': {
                      fontSize: '1.5rem',
                    },
                    '.MuiChip-label': {
                      fontSize: '1.2rem',
                    },
                  }}
                />
              ))}
            </Stack>
          </Box>
        ))}
      </CustomTabPanel>
    </>
  );
};

export default TabHeaderProduct;
