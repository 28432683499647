import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Box,
  TextField,
  Tooltip,
  Grid,
  TableCell,
  TableBody,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  FormControl,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Chip,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import usePhishing from '@/Hooks/api/usePhishing';
import useNotify from '@/Hooks/common/useNotify';
import { IFilteredMalware } from '@/interfaces/phishing';

function getStyles(name: any, malware: readonly any[], theme: Theme) {
  return {
    backgroundColor: malware.some((item: any) => item.id === name.id) ? theme.palette.action.hover : 'transparent',
  };
}

interface NewCampaignProps {
  setIsAddCampaign: (newValue: boolean) => void;
  setActiveStep: (newValue: number) => void;
  contentGenerated: string;
  subjectMail: string;
  senderName: string;
  handleGetListCampaign: (arg: number) => Promise<void>;
  listMalware: IFilteredMalware[];
}

const NewCampaign: React.FC<NewCampaignProps> = ({
  setIsAddCampaign,
  setActiveStep,
  contentGenerated,
  subjectMail,
  senderName,
  handleGetListCampaign,
  listMalware,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { onNotify } = useNotify();
  const { handleCreateCampaign } = usePhishing();

  const [listReceiver, setListReceiver] = useState<any[]>([]);
  const [malware, setMalware] = useState<any>([]);

  const listMalwareId = malware?.map((item: any) => {
    return { id: item?.id };
  });

  const createCampaignSuccess = () => {
    setActiveStep(0);
    setValue('name', '');
    setValue('description', '');
    setValue('receiver', '');
    setValue('content', '');
    setValue('landingPage', '');
    setValue('subject', '');
    setValue('senderName', '');
    setListReceiver([]);
    setIsAddCampaign(false);
    handleGetListCampaign(1);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('validation.fieldRequired')),
    description: yup.string().required(t('validation.fieldRequired')),
    receiver: _.isEmpty(listReceiver) ? yup.string().email().required(t('validation.fieldRequired')) : yup.string(),
    content: !contentGenerated ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    landingPage: yup.string().required(t('validation.fieldRequired')),
    subject: !subjectMail ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
    senderName: !senderName ? yup.string().required(t('validation.fieldRequired')) : yup.string(),
  });

  const { control, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    const params = {
      name: data.name,
      description: data.description,
      user_list: [data.receiver],
      phishing_content: data.content || contentGenerated,
      landing_page: data.landingPage,
      subject: data.subject || subjectMail,
      sender_name: data.senderName || senderName,
      attachments: listMalwareId,
    };
    const paramsWithListReceiver = {
      name: data.name,
      description: data.description,
      user_list: listReceiver,
      phishing_content: data.content || contentGenerated,
      landing_page: data.landingPage,
      subject: data.subject || subjectMail,
      sender_name: data.senderName || senderName,
      attachments: listMalwareId,
    };
    // if (_.isEmpty(malware)) {
    //   onNotify('warning', t('notify.attachMalware'), 3000);
    //   return;
    // }
    handleCreateCampaign(_.isEmpty(listReceiver) ? params : paramsWithListReceiver, createCampaignSuccess);
  };

  const handleAddReceiver = () => {
    const emailValue: any = getValues('receiver');
    const isEmailExists = listReceiver.some((email: any) => email === emailValue.trim());
    if (!isEmailExists && getValues('receiver') !== '') {
      setListReceiver((prevList) => [...prevList, getValues('receiver')]);
      setValue('receiver', '');
    } else if (isEmailExists) {
      onNotify('warning', t('notify.duplicateEmail'), 3000);
    }
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  const handleChangeMalware = (event: SelectChangeEvent<typeof malware>) => {
    const {
      target: { value },
    } = event;

    const selectedMalware = listMalware.find((selected: any) => selected.id === value.id);
    if (selectedMalware && !malware.some((item: any) => item.id === selectedMalware.id)) {
      setMalware([...malware, selectedMalware]);
    } else if (selectedMalware) {
      const updatedMalware = malware.filter((item: any) => item.id !== selectedMalware.id);
      setMalware(updatedMalware);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.campaignName')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                multiline
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.description')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Box>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
            backgroundColor: 'action.hover',
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Grid item xs={12} md={!_.isEmpty(listReceiver) ? 5.8 : 12}>
            <Controller
              name="receiver"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  style={{ width: '100%', marginTop: '3px' }}
                  label={t('placeholder.receiver')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    endAdornment: (
                      <Button variant="outlined" color="primary" disabled={!field.value} onClick={handleAddReceiver}>
                        <AddIcon />
                      </Button>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {!_.isEmpty(listReceiver) && (
            <Grid item xs={12} md={5.8}>
              <TableContainer sx={{ backgroundColor: 'action.hover', boxShadow: 'none' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '80%' }}>Email</TableCell>
                      <TableCell sx={{ width: '20%' }}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listReceiver.map((row: string) => (
                      <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{row}</TableCell>
                        <TableCell>
                          <Tooltip title={t('tooltip.delete')} placement="left">
                            <DeleteIcon
                              onClick={() => {
                                setListReceiver((prevList) => prevList.filter((receiver) => receiver !== row));
                              }}
                              sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'error.main',
                                },
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="content"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                key={contentGenerated}
                defaultValue={contentGenerated}
                type="text"
                variant="outlined"
                multiline
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.content')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-standard-label">{t('asm.phishing.malware')}</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={malware}
              onChange={handleChangeMalware}
              label={t('asm.phishing.malware')}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: any) => (
                      <Chip key={value} label={value.name} />
                    ))}
                  </Box>
                );
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'background.main',
                  },
                },
              }}
            >
              {listMalware.map((item: any) => (
                <MenuItem key={item} value={item} style={getStyles(item, malware, theme)}>
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="landingPage"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.landingPage')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="subject"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                key={subjectMail}
                defaultValue={subjectMail}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.subject')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Box>
        <Box sx={{ marginTop: 2, backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
          <Controller
            name="senderName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                key={senderName}
                defaultValue={senderName}
                type="text"
                variant="outlined"
                style={{ width: '100%', marginTop: '3px' }}
                label={t('placeholder.sender')}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </Box>
      </form>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          sx={{
            width: '90px',
            fontWeight: '500',
            borderRadius: '5px',
            textTransform: 'none',
            transition: 'all .1s ease-in-out',
            color: 'common.white',
            '&:active': {
              transform: 'translate(0, calc(-5 * 1%)) scale(0.98)',
              transition: 'all .1s ease-in-out',
            },
            whiteSpace: 'nowrap',
            alignSelf: 'center',
          }}
        >
          {t('action.create')}
        </Button>
      </Box>
    </>
  );
};
export default NewCampaign;
