import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { TwitterPostsTypes } from '@/types/Social';

import PostsFacebookDetail from './PostsTwitterDetail';
import ReactionsTwitter from './ReactionsTwitter';
import TitlePostsTwitter from './TitlePostsTwitter';

const initialPost: TwitterPostsTypes = {
  tweet_link: '',
  author_link: '',
  author_avatar: '',
  author_name: '',
  author_handle: '',
  post_time: '',
  post_content: '',
  image: '',
  comment_count: '0',
  repost_count: '0',
  like_count: '0',
  view_count: '0',
};

interface PostsTwitterProps {
  item: TwitterPostsTypes;
}

const PostsTwitter: React.FC<PostsTwitterProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<TwitterPostsTypes>(initialPost);

  return (
    <>
      <Box>
        <TitlePostsTwitter item={item} />
        <Box sx={{ cursor: 'pointer' }}>
          <Grid container spacing={2} direction="row" sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={8} sm={10}>
              <Typography
                variant="body2"
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  mt: 2,

                  letterSpacing: '0.01em',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 4,
                  textOverflow: 'ellipsis',
                  color: 'text.secondary',
                }}
              >
                <HighlightTextSocial text={item.post_content} keyword={topicSocial?.key_word_main} />
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              {!_.isEmpty(item?.image) && (
                <Box
                  component="img"
                  src={item?.image}
                  onClick={() => {
                    setOpenDialogDetail(true);
                    setDetailSelected(item);
                  }}
                  sx={{
                    height: '90px',
                    width: '100%',
                    borderRadius: 1,
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Typography
            variant="caption"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              mt: 0.5,
              width: 'fit-content',
              cursor: 'pointer',
              color: 'text.secondary',
              ':hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
              fontStyle: 'italic',
            }}
          >
            {t('action.showMore')}
          </Typography>
        </Box>
      </Box>
      <ReactionsTwitter item={item} />

      <PostsFacebookDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsTwitter;
