import { ReactElement, useEffect, useMemo, useState } from 'react';

import { Box, SelectChangeEvent, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanTime from '@/components/common/ScanTime';
import TextFieldOsint from '@/components/common/TextField/TextFieldOsint';
import { getToolname } from '@/configs/osintConfig';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useOsint from '@/Hooks/api/useOsint';
import { MailhunterTypes, OsintTypes, SubmitData } from '@/types/Osint';
import { decrypt, encrypt } from '@/utils/crypto';

const Mailhunter = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const domainActive = localStorage.getItem('domainActive') && decrypt(localStorage.getItem('domainActive') as string);
  const { dataWebSocketOsint } = useWebSocketContext();
  const [searchParams] = useSearchParams();
  const { handleGetOsint } = useOsint();

  const [dataMailhunter, setDataMailhunter] = useState<MailhunterTypes>();
  const [domain, setDomain] = useState(domainActive || '');

  const filteredListOsint = useMemo(
    () => dataWebSocketOsint.find((item: OsintTypes) => item.domain === domain),
    [dataWebSocketOsint, domain],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (inforUser?.role === 'super admin' && location.search) {
        const res = await handleGetOsint({ domain: String(searchParams.get('domain')) });
        const { data } = res;
        setDataMailhunter(data?.osint?.mailhunter);
      } else {
        setDataMailhunter(filteredListOsint?.data?.osint?.mailhunter);
      }
    };

    fetchData();
  }, [inforUser?.role, searchParams, filteredListOsint]);

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  const submitHandler = async (data: SubmitData) => {
    const params = new URLSearchParams({
      domain: data.searchInput,
    });
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
        {getToolname(location.pathname)}
      </Typography>
      {inforUser?.role === 'super admin' ? (
        <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextFieldOsint submitHandler={submitHandler} defaultValue={searchParams.get('domain') || ''} />
        </Box>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
          {!_.isEmpty(filteredListOsint?.data?.update_time) && (
            <ScanTime updateTime={filteredListOsint?.data?.update_time} />
          )}
        </Box>
      )}
      <Typography sx={{ fontWeight: 600, mt: 2 }}>{domainActive || searchParams.get('domain')}</Typography>
      {!_.isEmpty(dataMailhunter?.emails) ? (
        <Box
          sx={{
            mt: 2,
            backgroundColor: 'background.menu',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {dataMailhunter?.emails?.map((item: string) => (
            <Typography
              key={item}
              variant="body2"
              component="a"
              href={`mailto: ${item}`}
              sx={{
                p: 1,
                color: 'text.primary',
                '&:hover': {
                  color: '#02BB9F',
                },
              }}
            >
              {item}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
          No result
        </Typography>
      )}
    </>
  );
};
export default Mailhunter;
