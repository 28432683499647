import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Box, Typography, TextField, CircularProgress } from '@mui/material';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AosComponent from '@/components/animation/AosComponent';
import { useCommonInfo } from '@/contexts/Common';
import useAuth from '@/Hooks/api/useAuth';

import SendCode from './SendCode';

interface IResetPassword {
  setIsResetPassword: any;
}

const ResetPassword: React.FC<IResetPassword> = ({ setIsResetPassword }) => {
  const { handleSendCode } = useAuth();
  const { apiError } = useCommonInfo();
  const { t } = useTranslation();

  const [sendCode, setSendCode] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const navigateSuccess = () => {
    setSendCode(true);
    setLoading(false);
  };

  const validationSchema = yup.object({
    email: yup.string().email().required(t('placeholder.username')),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    setLoading(true);
    setEmail(data.email.trim());
    handleSendCode({ username: data.email.trim() }, navigateSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  return (
    <>
      {!sendCode ? (
        <AosComponent dataAos="fade-right">
          <Box
            sx={{
              width: { md: '600px', xs: '100%' },
              padding: 2,
              backgroundColor: 'background.main',
              boxShadow: 10,
              borderRadius: '8px',
            }}
          >
            <Button
              variant="text"
              color="inherit"
              onClick={() => {
                setIsResetPassword(false);
              }}
              sx={{
                height: 45,
                alignSelf: 'center',
                textTransform: 'capitalize',
                fontSize: '1.4rem',
                padding: 2,
                color: 'text.secondary',
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: '1.4rem', mr: 0.5 }} />
              {t('action.back')}
            </Button>
            <Typography
              variant="h4"
              sx={{
                mt: 2,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            >
              {t('action.forgotPassword')}
            </Typography>

            <Typography sx={{ fontWeight: 400, fontSize: '1.4rem', color: 'text.secondary', mt: 2 }}>
              {t('notify.resetPasswordLabel')}
            </Typography>
            <Box sx={{ marginBottom: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="text"
                      variant="filled"
                      fullWidth
                      style={{ marginTop: '25px' }}
                      label={t('placeholder.email')}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      onKeyDown={handleKeyDown}
                      autoFocus
                    />
                  )}
                />
              </form>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                loading={loading}
                loadingIndicator={<CircularProgress size={30} />}
                variant="outlined"
                color="primary"
                fullWidth
                type="button"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  width: { xs: '100%', sm: '40%' },
                  mt: 3,
                  fontWeight: '400',
                  height: 45,
                  textTransform: 'none',
                  fontSize: '1.4rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('action.resetPassword')}
              </LoadingButton>
            </Box>
          </Box>
        </AosComponent>
      ) : (
        <SendCode email={email} />
      )}
    </>
  );
};
export default ResetPassword;
