import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useDataleakService = () => {
  const { methodGet, methodPost } = useRequest();

  const getListKeywords = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.dataleak.listKeywords, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListAllKeywords = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.dataleak.listAllKeywords, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createKeywords = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.dataleak.createKeywords, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteKeywords = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.dataleak.deleteKeywords, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListDarkweb = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.dataleak.listDarkweb, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getListKeywords,
    getListAllKeywords,
    createKeywords,
    deleteKeywords,
    getListDarkweb,
  };
};
export default useDataleakService;
