import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography, Button, TextField, IconButton, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AosComponent from '@/components/animation/AosComponent';
import { useCommonInfo } from '@/contexts/Common';
import useAuth from '@/Hooks/api/useAuth';

interface ChangePasswordProps {
  setOpenChangePassword: (newValue: boolean) => void;
}

interface FormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ setOpenChangePassword }) => {
  const { t } = useTranslation();
  const { handleChangePass } = useAuth();
  const { apiError } = useCommonInfo();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigateSuccess = () => {
    setLoading(false);
  };
  const validationSchema = yup.object({
    oldPassword: yup.string().required(t('validation.fieldRequired')),
    newPassword: yup
      .string()
      .required(t('validation.fieldRequired'))
      .notOneOf([yup.ref('oldPassword'), undefined], t('validation.comparePassword'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/, t('validation.regexPassword')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), undefined], t('validation.confirmPassword'))
      .required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit } = useForm<FormData>({ resolver: yupResolver(validationSchema) });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    handleChangePass({ password: data.oldPassword.trim(), new_password: data.newPassword.trim() }, navigateSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  return (
    <AosComponent dataAos="flip-left" dataAosDuration="1000">
      <Box
        sx={{
          mt: 2,
          padding: 2,
          boxShadow: 5,
          borderRadius: 2,
          textAlign: '-webkit-center',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700, mt: 1 }}>
          {t('dialog.changePassword')}
        </Typography>
        <Box sx={{ width: '100%', marginBottom: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type={showOldPassword ? 'text' : 'password'}
                  variant="filled"
                  style={{ width: '60%', marginTop: '25px' }}
                  label={t('placeholder.oldPassword')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowOldPassword((prevShowPassword) => !prevShowPassword);
                          }}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="newPassword"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type={showNewPassword ? 'text' : 'password'}
                  variant="filled"
                  style={{ width: '60%', marginTop: '20px' }}
                  label={t('placeholder.newPassword')}
                  onKeyDown={handleKeyDown}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    onCopy: (e) => e.preventDefault(),
                    onPaste: (e) => e.preventDefault(),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowNewPassword((prevShowPassword) => !prevShowPassword);
                          }}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="filled"
                  style={{ width: '60%', marginTop: '20px' }}
                  label={t('placeholder.confirmNewPassword')}
                  onKeyDown={handleKeyDown}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    onCopy: (e) => e.preventDefault(),
                    onPaste: (e) => e.preventDefault(),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
                          }}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        </Box>
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setOpenChangePassword(false);
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: 'text.secondary',
              textTransform: 'initial',
              fontWeight: 400,
              '&:hover': {
                color: 'text.primary',
                bgcolor: 'rgba(255, 255, 255, 0.2)',
              },
              boxShadow: 2,
            }}
          >
            {t('action.backProfile')}
          </Button>
          <LoadingButton
            loading={loading}
            loadingIndicator={<CircularProgress size={20} />}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            sx={{
              ml: 2,
              textTransform: 'initial',
              fontWeight: 400,
              border: '1px solid rgba(255, 255, 255, 0.12)',
              color: '#ffff',
              backgroundColor: '#4689c3',
              boxShadow: 2,
              '&:hover': {
                color: '#ffff',
                backgroundColor: '#146ebb',
              },
            }}
          >
            {t('action.confirm')}
          </LoadingButton>
        </Box>
      </Box>
    </AosComponent>
  );
};
export default ChangePassword;
