import { useState } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { ResultReportTypes } from '@/types/Social';

import SocialTabContent from './SocialTabContent';

interface ResultReportProps {
  results: ResultReportTypes[];
}

const ResultReport: React.FC<ResultReportProps> = ({ results }) => {
  const { t } = useTranslation();

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };
  const TabLabels = [
    { type: 'all_platform', key: t('smcc.overview.allPlatform') },
    { type: 'facebook', key: 'Facebook' },
    { type: 'forum', key: t('smcc.overview.forum') },
    { type: 'tiktok', key: 'TikTok' },
    { type: 'youtube', key: 'Youtube' },
    { type: 'newspaper', key: t('smcc.overview.news') },
    { type: 'twitter', key: 'Twitter' },
    { type: 'reddit', key: 'Reddit' },
    { type: 'thread', key: 'Threads' },
  ];

  const selectedSocialType = TabLabels[valueTab].type; // Lấy social_type từ tab hiện tại
  const filteredData = results.find((item: ResultReportTypes) => item.social_type === selectedSocialType);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="social tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {TabLabels.map((tab, index) => (
              <StyledTab
                key={tab.type}
                label={t(tab.key)}
                sx={{
                  '&.MuiTab-root': {
                    fontSize: '1.4rem',
                  },
                }}
              />
            ))}
          </StyledTabs>
        </Box>
        <Box sx={{ mt: 3 }}>
          <SocialTabContent data={filteredData ?? ({} as ResultReportTypes)} />
        </Box>
      </Box>
    </>
  );
};
export default ResultReport;
