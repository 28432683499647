/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import newsDefault from '@/assets/image/news-default.png';
import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { NewsPostsTypes } from '@/types/Social';

import PostsNewsDetail from './PostsNewsDetail';
import TitlePostsNews from './TitlePostsNews';

const initialPost: NewsPostsTypes = {
  content: '0',
  image: '0',
  keywords: [],
  link: '',
  post_time: '',
  sentiment: 'neutral',
  source: '',
  source_logo: '',
  source_name: '',
  summary: '',
  title: '',
  type: '',
};

interface PostsNewsProps {
  item: NewsPostsTypes;
}

const PostsNews: React.FC<PostsNewsProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<NewsPostsTypes>(initialPost);

  return (
    <>
      <Box>
        <TitlePostsNews item={item} />
        <Box sx={{ mt: 3, cursor: 'pointer' }}>
          <Grid container spacing={2} direction="row" sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={8} sm={10}>
              <Typography
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  fontSize: '1.6rem',
                  letterSpacing: '0.01em',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 2,
                  textOverflow: 'ellipsis',
                  fontWeight: 600,
                  color: 'text.secondary',
                }}
              >
                <HighlightTextSocial text={item.title} keyword={topicSocial?.key_word_main} />
              </Typography>
              <Typography
                variant="body2"
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  mt: 2,

                  letterSpacing: '0.01em',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 4,
                  textOverflow: 'ellipsis',
                  color: 'text.secondary',
                }}
              >
                <HighlightTextSocial text={item.summary} keyword={topicSocial?.key_word_main} />
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Box
                component="img"
                src={item?.image || newsDefault}
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  height: '90px',
                  width: '100%',
                  borderRadius: 1,
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
              />
            </Grid>
          </Grid>

          <Typography
            variant="caption"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              mt: 0.5,
              width: 'fit-content',
              cursor: 'pointer',
              color: 'text.secondary',
              ':hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
              fontStyle: 'italic',
            }}
          >
            {t('action.showMore')}
          </Typography>
        </Box>
      </Box>

      <PostsNewsDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsNews;
