import React, { useState, useEffect, useMemo } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, InputBase, Grid, useTheme, debounce, CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AddAsset from '@/components/Asm/Assets/AddAsset';
import TableAsset from '@/components/Asm/Assets/TableAsset';
import AddButtonCustom from '@/components/common/Button/AddButtonCustom';
import useAsm from '@/Hooks/api/useAsm';
import useLoading from '@/Hooks/common/useLoading';
import { AssetType } from '@/types/Assets';
import { PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';

const Asset = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setLoading } = useLoading();

  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { handleGetListAsset } = useAsm();

  const [listAsset, setListAsset] = useState<AssetType[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [isAddAsset, setIsAddAsset] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState(1);
  const [filterAsset, setFilterAsset] = useState<AssetType[]>([]);

  const handleGetList = async (groupId?: number, groupName?: string, pageValue?: number) => {
    const dataRes = await handleGetListAsset({
      group_id: groupId ?? inforUser?.groupId,
      key_word: '',
      page: pageValue ?? page,
      page_size: PAGE_SIZE,
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_asset } = dataRes;
    setListAsset(list_asset);
  };

  useEffect(() => {
    handleGetList();
  }, [page]);

  const handleFilterAsset = async (searchKey?: string, pageValue?: number, groupId?: number, groupName?: string) => {
    const dataRes = await handleGetListAsset({
      group_id: inforUser?.groupId ?? groupId,
      key_word: searchKey ?? searchValue,
      page: pageValue ?? pageFilter,
      page_size: PAGE_SIZE,
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_asset } = dataRes;
    setFilterAsset(list_asset);
    setLoadingFilter(false);
  };

  const fetchFilterAsset = useMemo(
    () =>
      debounce(async (search) => {
        if (search !== '' && search.length > 2) {
          handleFilterAsset(search);
        } else {
          setLoadingFilter(false);
          setFilterAsset([]);
        }
      }, 2000),
    [],
  );

  const handleInputChange = (event: any) => {
    setPageFilter(1);
    setSearchValue(event.target.value);
    fetchFilterAsset(event.target.value);
    if (event.target.value.length > 2) {
      setLoadingFilter(true);
      setLoading(true);
    }
    if (event.target.value === '') {
      setFilterAsset([]);
      handleGetList();
    }
  };

  const handleClickButton = () => {
    setIsAddAsset(true);
  };

  return (
    <>
      <Helmet>
        <title>Asset Management - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.asset.title')}
      </Typography>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            marginBottom: inforUser.role === 'super admin' ? '40px' : 0,
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '12px 12px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={
                <>
                  {loadingFilter && <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />}
                  {searchValue && !loadingFilter && (
                    <ClearIcon
                      onClick={() => {
                        setSearchValue('');
                        setPage(1);
                        setFilterAsset([]);
                        handleGetList();
                      }}
                      sx={{
                        fontSize: '2rem',
                        mr: 3,
                        color: 'text.disabled',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              }
              value={searchValue}
              onChange={handleInputChange}
            />
          </Box>
          {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
            <AddButtonCustom handleClick={handleClickButton} text={t('action.addAsset')} />
          )}
        </Box>
        {searchValue !== '' && searchValue.length > 2 ? (
          <>
            {!_.isEmpty(filterAsset) ? (
              <>
                {filterAsset.map((asset: AssetType) => (
                  <Grid key={asset.group_id} container spacing={{ xs: 1, md: 3 }} sx={{ mt: { xs: 2, md: 1 } }}>
                    <Grid item xs={12} md={2}>
                      <Box
                        sx={{
                          backgroundColor: 'action.hover',
                          padding: 2,
                          borderRadius: 1,
                          fontWeight: 500,
                          boxShadow: 2,
                        }}
                      >
                        {asset.group_name}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      {inforUser.role !== 'super admin' ? (
                        <TableAsset
                          listVendors={asset?.list_vendor}
                          onSearchValueChange={setSearchValue}
                          onPageChange={setPageFilter}
                          page={pageFilter}
                          searchValue={searchValue}
                          handleFilterAsset={handleFilterAsset}
                          handleGetListAsset={handleGetList}
                          groupId={asset?.group_id}
                          totalVendor={asset.total}
                        />
                      ) : (
                        <TableAsset
                          listVendors={asset?.list_vendor}
                          onSearchValueChange={setSearchValue}
                          onPageChange={setPageFilter}
                          page={pageFilter}
                          searchValue={searchValue}
                          handleFilterAsset={handleFilterAsset}
                          handleGetListAsset={handleGetList}
                          groupId={asset?.group_id}
                          totalVendor={asset.total}
                        />
                      )}
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {!loadingFilter && (
                  <Box
                    sx={{
                      width: '100%',
                      borderRadius: 2,
                      mt: 3,
                      padding: 2,
                      backgroundColor: 'action.hover',
                    }}
                  >
                    <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noResult')}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {listAsset.map((asset: AssetType) => (
              <React.Fragment key={asset.group_id}>
                {inforUser.role !== 'super admin' ? (
                  <Grid container spacing={{ xs: 1, md: 3 }} sx={{ mt: { xs: 2, md: 1 } }}>
                    <Grid item xs={12} md={2}>
                      <Box
                        sx={{
                          boxShadow: 2,
                          padding: 2,
                          borderRadius: 1,
                          fontWeight: 400,
                          backgroundColor: 'action.hover',
                        }}
                      >
                        {asset.group_name}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <TableAsset
                        listVendors={asset?.list_vendor}
                        onSearchValueChange={setSearchValue}
                        onPageChange={setPage}
                        page={page}
                        handleGetListAsset={handleGetList}
                        groupId={asset?.group_id}
                        totalVendor={asset.total}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Box sx={{ mt: { xs: 2, md: 1 } }}>
                    <Accordion
                      sx={{
                        width: '100%',
                        backgroundColor: 'action.hover',
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '2.5rem' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>{asset.group_name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableAsset
                          listVendors={asset?.list_vendor}
                          onSearchValueChange={setSearchValue}
                          onPageChange={setPage}
                          page={page}
                          handleGetListAsset={handleGetList}
                          groupId={asset?.group_id}
                          totalVendor={asset.total}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
      <AddAsset isAddAsset={isAddAsset} setIsAddAsset={setIsAddAsset} handleGetListAsset={handleGetList} />
    </>
  );
};
export default Asset;
