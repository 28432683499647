/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { Transition } from '@/components/common/Transition';
import { useSocialInfo } from '@/contexts/Social';
import { ThreadsPostsTypes, ThreadsReplyTypes } from '@/types/Social';

import TitlePostsThreads from './TitlePostsThreads';

interface PostsThreadsDetailProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detailSelected: ThreadsPostsTypes;
}

const PostsThreadsDetail: React.FC<PostsThreadsDetailProps> = ({
  openDialogDetail,
  onDialogDetailChange,
  detailSelected,
}) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();
  const contentRef = useRef<HTMLDivElement>(null);
  const locale = localStorage.getItem('locale') || 'vi';

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);
  return (
    <Dialog
      open={openDialogDetail}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogDetailChange(false);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { sm: '95vw', md: '60vw' },
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            {t('smcc.originalPosts')}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              href={detailSelected?.link}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',

                fontWeight: 400,
                display: 'flex',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <LinkIcon sx={{ fontSize: '2rem', mr: 1 }} />
              <Typography variant="body2">{t('action.goOriginalPosts')}</Typography>
            </Link>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Box sx={{ mt: 2 }}>
          <TitlePostsThreads item={detailSelected} />
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                letterSpacing: '0.01em',
                color: 'text.secondary',
              }}
            >
              <HighlightTextSocial text={detailSelected?.post_content} keyword={topicSocial?.key_word_main} />
            </Typography>
            {!_.isEmpty(detailSelected?.images) && (
              <Grid container sx={{ mt: 2 }} spacing={2}>
                {detailSelected?.images.map((img: string) => (
                  <Grid key={img} item xs={6} sm={4}>
                    <Box
                      component="img"
                      src={img}
                      sx={{
                        height: '150px',
                        borderRadius: 1,
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          <Box sx={{ mt: 3, display: 'flex' }}>
            <Stack direction="row" sx={{ color: 'text.secondary' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="m8.962 18.91l.464-.588zM12 5.5l-.54.52a.75.75 0 0 0 1.08 0zm3.038 13.41l.465.59zm-5.612-.588C7.91 17.127 6.253 15.96 4.938 14.48C3.65 13.028 2.75 11.335 2.75 9.137h-1.5c0 2.666 1.11 4.7 2.567 6.339c1.43 1.61 3.254 2.9 4.68 4.024zM2.75 9.137c0-2.15 1.215-3.954 2.874-4.713c1.612-.737 3.778-.541 5.836 1.597l1.08-1.04C10.1 2.444 7.264 2.025 5 3.06C2.786 4.073 1.25 6.425 1.25 9.137zM8.497 19.5c.513.404 1.063.834 1.62 1.16s1.193.59 1.883.59v-1.5c-.31 0-.674-.12-1.126-.385c-.453-.264-.922-.628-1.448-1.043zm7.006 0c1.426-1.125 3.25-2.413 4.68-4.024c1.457-1.64 2.567-3.673 2.567-6.339h-1.5c0 2.198-.9 3.891-2.188 5.343c-1.315 1.48-2.972 2.647-4.488 3.842zM22.75 9.137c0-2.712-1.535-5.064-3.75-6.077c-2.264-1.035-5.098-.616-7.54 1.92l1.08 1.04c2.058-2.137 4.224-2.333 5.836-1.596c1.659.759 2.874 2.562 2.874 4.713zm-8.176 9.185c-.526.415-.995.779-1.448 1.043s-.816.385-1.126.385v1.5c.69 0 1.326-.265 1.883-.59c.558-.326 1.107-.756 1.62-1.16z"
                />
              </svg>

              <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>
                {detailSelected?.like_count}
              </Typography>
            </Stack>

            <Stack direction="row" sx={{ color: 'text.secondary', ml: 4 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024">
                <path
                  fill="currentColor"
                  d="m174.72 855.68l130.048-43.392l23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352c0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8l-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416s-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"
                />
              </svg>
              <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>
                {detailSelected?.reply_count}
              </Typography>
            </Stack>
          </Box>

          <Divider sx={{ mt: 3, mb: 2 }} />
        </Box>

        <Box sx={{ mt: 1, ml: 2, mb: 2 }}>
          {!_.isEmpty(detailSelected) && (
            <>
              {detailSelected?.replies.map((item: ThreadsReplyTypes, index: number) => (
                <Stack key={item.id} direction="row" spacing={2} sx={{ mt: 1.5 }}>
                  <Link href={`https://www.threads.net/@${item?.username}`} target="_blank" rel="noopener">
                    <Avatar src={item?.user_avatar} sx={{ width: '30px', height: '30px', mt: 1 }} />
                  </Link>
                  <Box sx={{ padding: 1.5, backgroundColor: 'action.hover', borderRadius: 1, width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Stack direction="row" sx={{ mr: 1 }}>
                        <Link
                          href={`https://www.threads.net/@${item?.username}`}
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: 'none',
                            ':hover': {
                              textDecoration: 'underline',
                            },
                            mr: 0.5,
                          }}
                        >
                          <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{item?.username}</Typography>
                        </Link>
                        {item?.user_verified && (
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                            <path
                              fill="#00a0ee"
                              fillRule="evenodd"
                              d="M15.418 5.643a1.25 1.25 0 0 0-1.34-.555l-1.798.413a1.25 1.25 0 0 1-.56 0l-1.798-.413a1.25 1.25 0 0 0-1.34.555l-.98 1.564c-.1.16-.235.295-.395.396l-1.564.98a1.25 1.25 0 0 0-.555 1.338l.413 1.8a1.25 1.25 0 0 1 0 .559l-.413 1.799a1.25 1.25 0 0 0 .555 1.339l1.564.98c.16.1.295.235.396.395l.98 1.564c.282.451.82.674 1.339.555l1.798-.413a1.25 1.25 0 0 1 .56 0l1.799.413a1.25 1.25 0 0 0 1.339-.555l.98-1.564c.1-.16.235-.295.395-.395l1.565-.98a1.25 1.25 0 0 0 .554-1.34L18.5 12.28a1.25 1.25 0 0 1 0-.56l.413-1.799a1.25 1.25 0 0 0-.554-1.339l-1.565-.98a1.25 1.25 0 0 1-.395-.395zm-.503 4.127a.5.5 0 0 0-.86-.509l-2.615 4.426l-1.579-1.512a.5.5 0 1 0-.691.722l2.034 1.949a.5.5 0 0 0 .776-.107z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </Stack>

                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {item?.post_time
                          ? format(
                              new Date(item?.post_time),
                              locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm",
                              {
                                locale: locale === 'vi' ? vi : enUS,
                              },
                            )
                          : 'NaN'}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                      {item?.text}
                    </Typography>

                    <Stack direction="row" sx={{ color: 'text.secondary', mt: 2 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="m8.962 18.91l.464-.588zM12 5.5l-.54.52a.75.75 0 0 0 1.08 0zm3.038 13.41l.465.59zm-5.612-.588C7.91 17.127 6.253 15.96 4.938 14.48C3.65 13.028 2.75 11.335 2.75 9.137h-1.5c0 2.666 1.11 4.7 2.567 6.339c1.43 1.61 3.254 2.9 4.68 4.024zM2.75 9.137c0-2.15 1.215-3.954 2.874-4.713c1.612-.737 3.778-.541 5.836 1.597l1.08-1.04C10.1 2.444 7.264 2.025 5 3.06C2.786 4.073 1.25 6.425 1.25 9.137zM8.497 19.5c.513.404 1.063.834 1.62 1.16s1.193.59 1.883.59v-1.5c-.31 0-.674-.12-1.126-.385c-.453-.264-.922-.628-1.448-1.043zm7.006 0c1.426-1.125 3.25-2.413 4.68-4.024c1.457-1.64 2.567-3.673 2.567-6.339h-1.5c0 2.198-.9 3.891-2.188 5.343c-1.315 1.48-2.972 2.647-4.488 3.842zM22.75 9.137c0-2.712-1.535-5.064-3.75-6.077c-2.264-1.035-5.098-.616-7.54 1.92l1.08 1.04c2.058-2.137 4.224-2.333 5.836-1.596c1.659.759 2.874 2.562 2.874 4.713zm-8.176 9.185c-.526.415-.995.779-1.448 1.043s-.816.385-1.126.385v1.5c.69 0 1.326-.265 1.883-.59c.558-.326 1.107-.756 1.62-1.16z"
                        />
                      </svg>

                      <Typography sx={{ ml: 1, color: 'text.secondary', fontSize: '14px' }}>
                        {item.like_count}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              ))}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PostsThreadsDetail;
