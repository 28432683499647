import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import CreateReport from '@/components/Management/news/CreateReport';
import { formatDateAndHours } from '@/configs/date';
import useManagenent from '@/Hooks/api/useManagenent';
import { ReportNewsType } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

const ReportNews = (): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleGetListReport } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listReport, setListReport] = useState<ReportNewsType[]>([]);
  const [totalReport, setTotalReport] = useState(0);
  const [openDialogReport, setOpenDialogReport] = useState(false);

  const handleGetList = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
    };
    const res = await handleGetListReport(params);
    const { results, count } = res;
    setListReport(results);
    setTotalReport(count);
  };

  useEffect(() => {
    handleGetList();
  }, [searchParams.get('page')]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  const CvssLabel = (bgColor: string, value: number, label: string) => {
    return (
      <Chip
        sx={{ backgroundColor: bgColor, height: '25px', color: '#fff', fontWeight: 600, fontSize: '1.2rem' }}
        label={label}
      />
    );
  };

  const DisplayCvssV3 = (displayStatus: { status: number }) => {
    const value: number = Object.values(displayStatus)[0];

    if (value === 0) {
      return CvssLabel(grey[600], value, t('management.news.notImplemented'));
    }
    if (value === 1) {
      return CvssLabel('info.main', value, t('management.news.progressing'));
    }
    if (value === 2) {
      return CvssLabel('primary.dark', value, t('management.news.dataExtracted'));
    }
    if (value === 3) {
      return CvssLabel('success.light', value, t('management.news.reportExported'));
    }
    if (value === 4) {
      return CvssLabel('success.dark', value, t('management.news.emailSent'));
    }
    if (value === 5) {
      return CvssLabel('error.main', value, t('management.news.error'));
    }
    return (
      <Chip
        sx={{
          backgroundColor: '#d2d6de',
          height: '22px',
          color: '#161C24',
          fontWeight: 600,
          fontSize: '1rem',
        }}
        label="N/A"
      />
    );
  };

  const displayType = (type: string) => {
    switch (type) {
      case 'both':
        return t('home.label.news.both');
      case 'cyber_security':
        return t('home.label.news.cyberSecurity');
      case 'finance':
        return t('home.label.news.business');
      default:
        return type;
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: { xs: 1, md: 3 } }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: '1.2rem' }}
          onClick={() => {
            setOpenDialogReport(true);
          }}
        >
          {t('management.news.sendReport')}
        </Button>
      </Box>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <TableContainer
          sx={{
            padding: 2,
            borderRadius: 2,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: 'action.hover',
            mt: 3,
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: 'action.hover' }}>
                <TableCell width="10%">Id</TableCell>
                <TableCell>{t('management.news.subject')}</TableCell>
                <TableCell>{t('management.news.email')}</TableCell>
                <TableCell>{t('management.news.createAt')}</TableCell>
                <TableCell>{t('management.news.typeNews')}</TableCell>
                <TableCell>{t('management.news.status')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {!_.isEmpty(listReport) ? (
                  <>
                    {listReport.map((row: ReportNewsType) => (
                      <TableRow
                        key={row.id}
                        hover
                        tabIndex={-1}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.email_subject || '-'}</TableCell>
                        <TableCell>
                          {row.emails.map((email: string) => (
                            <Typography variant="body2" key={email}>
                              {email}
                            </Typography>
                          ))}
                        </TableCell>
                        <TableCell>{formatDateAndHours(row.created_at)}</TableCell>
                        <TableCell>{displayType(row.type_news)}</TableCell>
                        <TableCell>
                          <>
                            {row.log ? (
                              CvssLabel('error.main', 5, `${t('management.news.error')}: ${row.log}`)
                            ) : (
                              <DisplayCvssV3 status={row.status} />
                            )}
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Pagination
              count={Math.ceil(totalReport / PAGE_SIZE)}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={Number(searchParams.get('page') || 1)}
            />
          </Box>
        </TableContainer>
      </Box>
      <CreateReport openDialogReport={openDialogReport} onDialogReportChange={setOpenDialogReport} />
    </>
  );
};
export default ReportNews;
