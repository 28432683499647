import React, { useState, useEffect, useMemo } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ShieldIcon from '@mui/icons-material/Shield';
import {
  Button,
  useTheme,
  Box,
  Grid,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Chip,
  Typography,
  Pagination,
  Stack,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import * as _ from 'lodash';
import queryString from 'query-string';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatTimeCVEList } from '@/components/common/format/FormatTimeCVE';
import useAsm from '@/Hooks/api/useAsm';

import LoadingListCVE from './LoadingListCVE';

interface PropsFilterLabel {
  label: string;
  param: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CVEList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleListCVE } = useAsm();

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      vendor: params.vendor as string,
      product: params.product as string,
      cwe: params.cwe as string,
      cvss: params.cvss as string,
      search: params.search as string,
      page: params.page as string,
    };
  }, [location.search]);

  const [listCVE, setListCVE] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [countPage, setCountPage] = useState(0);
  const [labelVendor, setLabelVendor] = useState('');
  const [labelProduct, setLabelProduct] = useState('');
  const [selectedCvss, setSelectedCvss] = useState('');

  const optionCVSS = [
    { label: `${t('cve.cvss.none')} (0.0)`, value: 'none' },
    { label: `${t('cve.cvss.low')} (0.1 - 3.9)`, value: 'low' },
    { label: `${t('cve.cvss.medium')} (4.0 - 6.9)`, value: 'medium' },
    { label: `${t('cve.cvss.high')} (7.0 - 8.9)`, value: 'high' },
    { label: `${t('cve.cvss.critical')} (9.0 - 10.0)`, value: 'critical' },
  ];

  const handleGetCVE = async () => {
    setLoading(true);
    setCountPage(0);
    // gọi list cve
    const dataRes = await handleListCVE({
      vendor: queryParams.vendor,
      product: queryParams.product,
      cwe: queryParams.cwe,
      cvss: queryParams.cvss,
      search: queryParams.search,
      page: Number.parseInt(queryParams.page || '1', 10),
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_cve, total_cves } = dataRes;
    setListCVE(list_cve);
    setCountPage(total_cves);
    setLoading(false);
  };

  const { control, handleSubmit, setValue } = useForm({});

  const onSubmit = (data: any) => {
    setCountPage(0);
    const filter = {
      ...queryParams,
      cvss: selectedCvss,
      search: data.searchKey?.trim(),
      page: 1,
    };
    navigate(`/cve?${queryString.stringify(filter)}`);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleSearchVendor = (vendor: string) => {
    const filter = {
      vendor,
    };
    navigate(`/cve?${queryString.stringify(filter)}`);
  };

  const handleSearchProduct = (vendor: string, product: string) => {
    const filter = {
      vendor,
      product,
    };
    navigate(`/cve?${queryString.stringify(filter)}`);
  };

  const handleChangePage = (page: number) => {
    const filter = {
      ...queryParams,
      page,
    };
    navigate(`/cve?${queryString.stringify(filter)}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (queryParams.vendor !== undefined) {
      const decodedVendor = decodeURIComponent(queryParams.vendor).replace(/_/g, ' ');
      setLabelVendor(decodedVendor || '');
    } else {
      setLabelVendor('');
    }

    if (queryParams.product !== undefined) {
      const decodedProduct = decodeURIComponent(queryParams.product).replace(/_/g, ' ');
      setLabelProduct(decodedProduct || '');
    } else {
      setLabelProduct('');
    }

    if (queryParams.cvss && queryParams.cvss !== '') {
      const selectedOption = optionCVSS.find((option) => option.value === queryParams.cvss);
      if (selectedOption) {
        setSelectedCvss(queryParams.cvss);
      }
    }

    if (queryParams.search && queryParams.search !== '') {
      setValue('searchKey', queryParams.search);
    }

    handleGetCVE();
  }, [queryParams]);

  const handleChangeCVSS = (event: any) => {
    setSelectedCvss(event.target.value);
  };

  const CvssLabel = (bgColor: string, value: number, label: string) => {
    return (
      <Chip
        sx={{ backgroundColor: bgColor, height: '25px', color: '#fff', fontWeight: 600, fontSize: '1.2rem' }}
        label={`${value} ${label}`}
      />
    );
  };

  const DisplayCvssV3 = (cvssScore: { cvss: number }) => {
    const value: number = Object.values(cvssScore)[0];

    if (value >= 0.1 && value <= 3.9) {
      return CvssLabel('#00c0ef', value, t('cve.cvss.low'));
    }
    if (value >= 4.0 && value <= 6.9) {
      return CvssLabel('#f39c12', value, t('cve.cvss.medium'));
    }
    if (value >= 7.0 && value <= 8.9) {
      return CvssLabel('#dd4b39', value, t('cve.cvss.high'));
    }
    if (value >= 9.0 && value <= 10.0) {
      return CvssLabel('#972b1e', value, t('cve.cvss.critical'));
    }
    return (
      <Chip
        sx={{
          backgroundColor: '#d2d6de',
          height: '25px',
          color: '#161C24',
          fontWeight: 600,
          fontSize: '1.2rem',
        }}
        label="N/A"
      />
    );
  };

  const FilterLabel: React.FC<PropsFilterLabel> = ({ label, param, onClick }) => {
    return (
      <Grid
        item
        md={5.8}
        xs={12}
        sx={{
          backgroundColor: 'background.main',
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: `5px solid ${theme.palette.divider}`,
          padding: 1.5,
          borderRadius: '3px',
          mb: { xs: 1 },
          boxShadow: 1,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <FilterAltIcon sx={{ color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary" sx={{ ml: 1, whiteSpace: 'nowrap' }}>
            {label}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#8DCAFE ', marginLeft: 1, fontWeight: 700, textTransform: 'capitalize' }}
          >
            {param}
          </Typography>
        </Box>
        <Box onClick={onClick}>
          <ClearIcon sx={{ color: 'text.secondary', '&:hover': { color: 'text.primary' } }} />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      {(labelVendor !== '' || labelProduct !== '' || queryParams.cwe) && (
        <Grid container sx={{ marginBottom: { xs: 0, md: 2 }, justifyContent: 'space-between' }}>
          {labelVendor && labelVendor !== '' && (
            <FilterLabel
              label={t('cve.filter.byVendor')}
              param={labelVendor}
              onClick={() => {
                setValue('searchKey', '');
                navigate('/cve');
              }}
            />
          )}
          {queryParams.cwe && queryParams.cwe !== '' && (
            <FilterLabel
              label={t('cve.filter.byCwe')}
              param={queryParams.cwe}
              onClick={() => {
                navigate('/cve');
              }}
            />
          )}
          {labelProduct && (
            <FilterLabel
              label={t('cve.filter.byProduct')}
              param={labelProduct}
              onClick={() => {
                setValue('searchKey', '');
                navigate(`/cve?vendor=${queryParams.vendor}`);
              }}
            />
          )}
        </Grid>
      )}

      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 2,
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 1.5,
            backgroundColor: 'background.main',
            borderTop: `5px solid ${theme.palette.divider}`,
            borderRadius: '3px',
            mb: { xs: 1, md: 0 },
            boxShadow: 4,
          }}
        >
          <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} md={4} sx={{ mb: { xs: 1 } }}>
              <FormControl fullWidth>
                <InputLabel id="cvss-label">{t('cve.cvss.labelFilterScore')}</InputLabel>
                <Select
                  labelId="cvss-label"
                  id="cvss-select"
                  value={selectedCvss}
                  onChange={handleChangeCVSS}
                  label={t('cve.cvss.labelFilterScore')}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: 'background.dark',
                        padding: '10px',
                      },
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '15px',
                    },
                  }}
                >
                  <MenuItem value="" sx={{ fontSize: '1.4rem' }}>
                    <em>{t('cve.filter.noFilter')}</em>
                  </MenuItem>
                  {optionCVSS.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '1.4rem' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6.5} sx={{ mb: { xs: 1 } }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="searchKey"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      style={{ width: '100%' }}
                      label={t('placeholder.searchCVE')}
                      onKeyDown={handleKeyDown}
                      InputProps={{
                        endAdornment: queryParams.search && queryParams.search !== '' && (
                          <ClearIcon
                            onClick={() => {
                              setValue('searchKey', '');
                              navigate(`/cve?${queryString.stringify({ ...queryParams, search: '', page: 1 })}`);
                            }}
                            sx={{
                              color: 'text.secondary',
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>

            <Grid item xs={12} md={1.5} sx={{ textAlign: 'center' }}>
              <Button
                type="submit"
                color="info"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  paddingBlock: '13px',
                  color: 'common.white',
                  fontSize: '1.4rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('action.search')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 1.5,
            backgroundColor: 'background.main',
            borderTop: `5px solid ${theme.palette.divider}`,
            borderRadius: '3px',
            boxShadow: 4,
          }}
        >
          <Stack sx={{ backgroundColor: 'background.main', justifyContent: 'center' }}>
            <ShieldIcon
              sx={{
                fontSize: '50px',
                display: 'flex',
                alignItems: 'center',
                margin: '0px',
                padding: '0px',
                color: 'text.primary',
              }}
            />
          </Stack>
          <Box sx={{ alignSelf: 'center', marginLeft: 1 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
              {t('cve.total')}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ fontSize: '1.8rem', color: 'text.primary', fontWeight: 'bold' }}>
                {countPage}
              </Typography>
              <Typography sx={{ ml: 1, fontSize: '1.8rem', color: 'text.secondary', fontWeight: 'bold' }}>
                CVE
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!_.isEmpty(listCVE) ? (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'background.main',
            padding: 2,
            borderRadius: '3px',
            boxShadow: 4,
          }}
        >
          <TableContainer
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '5px',
            }}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: '10%' }}>CVE</StyledTableCell>
                  <StyledTableCell sx={{ width: '35%' }}>{t('table.cve.vendors')}</StyledTableCell>
                  <StyledTableCell sx={{ width: '35%' }}>{t('table.cve.products')}</StyledTableCell>
                  <StyledTableCell sx={{ width: '10%' }}>{t('table.cve.updated')}</StyledTableCell>
                  <StyledTableCell sx={{ width: '10%', whiteSpace: 'nowrap' }}>CVSS v3.1</StyledTableCell>
                </TableRow>
              </TableHead>
              {!loading ? (
                <TableBody>
                  {listCVE.map((row: any, indexRow: any) => (
                    <React.Fragment key={row.id}>
                      <StyledTableRow
                        sx={{
                          verticalAlign: 'baseline',
                        }}
                      >
                        <Link href={`/cve/${row.id}`} rel="noopener" sx={{ textDecoration: 'none' }}>
                          <StyledTableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              color: 'info.main',

                              '&:hover': {
                                color: 'text.secondary',
                                textDecoration: 'underline',
                              },
                              cursor: 'pointer',
                              fontWeight: 600,
                            }}
                          >
                            {row.id}
                          </StyledTableCell>
                        </Link>
                        <StyledTableCell>
                          {row.vendors.number_vendors > 0 ? (
                            <Badge
                              badgeContent={row.vendors.number_vendors}
                              sx={{
                                marginRight: 1,
                                zIndex: 0,
                                '& .MuiBadge-badge': {
                                  fontSize: '1.2rem',
                                },
                              }}
                              color="primary"
                              max={9999}
                            />
                          ) : null}
                          {row.vendors.list_vendors.map((item: { label: string; params: string }, index: number) => (
                            <React.Fragment key={index}>
                              <Chip
                                onClick={() => {
                                  setCountPage(0);
                                  setValue('searchKey', '');
                                  handleSearchVendor(item.params);
                                }}
                                clickable={false}
                                sx={{
                                  '.MuiChip-label': {
                                    color: '#6bb7fa',
                                    '&:hover': {
                                      color: 'text.secondary',
                                      textDecoration: 'underline',
                                    },
                                    padding: 0,
                                    fontSize: '1.4rem',
                                  },
                                  backgroundColor: 'transparent',
                                  marginLeft: 1.2,
                                  cursor: 'pointer',
                                }}
                                label={index < row.vendors.list_vendors.length - 1 ? `${item.label},` : item.label}
                              />
                            </React.Fragment>
                          ))}
                          {row.vendors.number_vendors > 3 ? ( // Kiểm tra nếu có nhiều hơn 3 item
                            <Chip
                              sx={{
                                '.MuiChip-label': {
                                  color: 'text.secondary',
                                  padding: 0.5,
                                  fontSize: '1.4rem',
                                  fontStyle: 'italic',
                                },
                                backgroundColor: 'transparent',
                                marginLeft: 0.8,
                              }}
                              label={`and ${row.vendors.number_vendors - 3} more`}
                            />
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.products.number_products > 0 ? (
                            <Badge
                              badgeContent={row.products.number_products}
                              sx={{
                                marginRight: 1,
                                zIndex: 0,
                                '& .MuiBadge-badge': {
                                  fontSize: '1.2rem',
                                },
                              }}
                              color="primary"
                              max={9999}
                            />
                          ) : null}
                          {row.products.list_products.map(
                            (item: { label: string; params: { vendor: string; product: string } }, index: number) => (
                              <React.Fragment key={index}>
                                <Chip
                                  onClick={() => {
                                    setCountPage(0);
                                    setValue('searchKey', '');
                                    handleSearchProduct(item.params.vendor, item.params.product);
                                  }}
                                  clickable={false}
                                  sx={{
                                    '.MuiChip-label': {
                                      color: '#6bb7fa',
                                      '&:hover': {
                                        color: 'text.secondary',
                                        textDecoration: 'underline',
                                      },
                                      padding: 0,
                                      fontSize: '1.4rem',
                                    },
                                    backgroundColor: 'transparent',
                                    marginLeft: 1.2,
                                    cursor: 'pointer',
                                  }}
                                  label={index < row.products.list_products.length - 1 ? `${item.label},` : item.label}
                                />
                              </React.Fragment>
                            ),
                          )}
                          {row.products.number_products > 3 ? ( // Kiểm tra nếu có nhiều hơn 3 item
                            <Chip
                              sx={{
                                '.MuiChip-label': {
                                  color: 'text.secondary',
                                  padding: 0.5,
                                  fontSize: '1.4rem',
                                  fontStyle: 'italic',
                                },
                                backgroundColor: 'transparent',
                                marginLeft: 0.8,
                              }}
                              label={`and ${row.products.number_products - 3} more`}
                            />
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                          {formatTimeCVEList(row.updated)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <DisplayCvssV3 cvss={row.cvssV3} />
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow
                        sx={{
                          '&:not(:last-of-type)': {
                            borderBottom: `1px solid ${theme.palette.divider}`,
                          },
                        }}
                      >
                        <StyledTableCell colSpan={6}>{row.description}</StyledTableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <LoadingListCVE />
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Pagination
              count={Math.ceil(countPage / 20)}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={Number.parseInt(queryParams.page || '1', 10)}
            />
          </Box>
        </Box>
      ) : (
        <>
          {loading ? (
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                boxShadow: 4,
              }}
            >
              <TableContainer
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '5px',
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: '10%' }}>CVE</StyledTableCell>
                      <StyledTableCell sx={{ width: '35%' }}>{t('table.cve.vendors')}</StyledTableCell>
                      <StyledTableCell sx={{ width: '35%' }}>{t('table.cve.products')}</StyledTableCell>
                      <StyledTableCell sx={{ width: '10%' }}>{t('table.cve.updated')}</StyledTableCell>
                      <StyledTableCell sx={{ width: '10%', whiteSpace: 'nowrap' }}>CVSS v3.1</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <LoadingListCVE />
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                backgroundColor: `${theme.palette.divider}`,
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                color: 'text.primary',
                fontSize: '1.4rem',
              }}
            >
              {t('cve.noCveFound')}
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default CVEList;
