import React from 'react';

import { Box, Link } from '@mui/material';

import { CourseTypes } from '@/types/management';

interface Props {
  course: CourseTypes;
}

const DocumentCourse: React.FC<Props> = ({ course }) => {
  const cleanFileName = (fileName: string) => {
    return fileName.replace(/^\d+-/, '').trim();
  };

  const getFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'ppt':
      case 'pptx':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 32 32">
            <path
              fill="#d33922"
              d="M18.536 2.321v2.863c3.4.019 7.357-.035 10.754.016c.642 0 .67.568.678 1.064c.054 5.942-.013 12.055.032 18c-.012.234-.006 1.1-.013 1.346c-.022.823-.434.859-1.257.884c-.132 0-.52.006-.648.012c-3.181-.016-6.362-.009-9.546-.009v3.182L2 27.134V4.873z"
            />
            <path
              fill="#fff"
              d="M18.536 6.138h10.5v19.4h-10.5V23h7.634v-1.275h-7.634v-1.59h7.634v-1.272h-7.631q.002-.936-.006-1.87a4.47 4.47 0 0 0 3.82-.375a4.35 4.35 0 0 0 1.959-3.474c-1.4-.01-2.793-.006-4.186-.006c0-1.384.016-2.767-.029-4.148c-.522.1-1.043.21-1.562.321V6.139"
            />
            <path fill="#d33922" d="M20.766 8.324a4.476 4.476 0 0 1 4.186 4.167c-1.4.016-2.793.01-4.189.01V8.324" />
            <path
              fill="#fff"
              d="M7.1 10.726c1.727.083 3.82-.684 5.252.611c1.371 1.664 1.008 4.724-1.024 5.719A4.7 4.7 0 0 1 9 17.348c0 1.244-.006 2.488 0 3.731q-.947-.082-1.893-.159c-.029-3.4-.035-6.8 0-10.2"
            />
            <path
              fill="#d33922"
              d="M8.993 12.446c.627-.029 1.4-.143 1.826.445a2.3 2.3 0 0 1 .041 2.087c-.363.655-1.183.592-1.816.668c-.067-1.066-.06-2.131-.051-3.2"
            />
          </svg>
        );
      case 'xls':
      case 'xlsx':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 32 32">
            <defs>
              <linearGradient
                id="vscodeIconsFileTypeExcel0"
                x1={4.494}
                x2={13.832}
                y1={-2092.086}
                y2={-2075.914}
                gradientTransform="translate(0 2100)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="#18884f" />
                <stop offset={0.5} stopColor="#117e43" />
                <stop offset={1} stopColor="#0b6631" />
              </linearGradient>
            </defs>
            <path
              fill="#185c37"
              d="M19.581 15.35L8.512 13.4v14.409A1.19 1.19 0 0 0 9.705 29h19.1A1.19 1.19 0 0 0 30 27.809V22.5Z"
            />
            <path fill="#21a366" d="M19.581 3H9.705a1.19 1.19 0 0 0-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5Z" />
            <path fill="#107c41" d="M8.512 9.5h11.069V16H8.512Z" />
            <path
              d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2"
              opacity={0.1}
            />
            <path
              d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
              opacity={0.2}
            />
            <path
              d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
              opacity={0.2}
            />
            <path
              d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
              opacity={0.2}
            />
            <path
              fill="url(#vscodeIconsFileTypeExcel0)"
              d="M3.194 8.85h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.19 1.19 0 0 1 2 21.959V10.041A1.19 1.19 0 0 1 3.194 8.85"
            />
            <path
              fill="#fff"
              d="m5.7 19.873l2.511-3.884l-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017q.123-.281.26-.546l1.342-2.447h1.7l-2.359 3.84l2.419 3.905h-1.809l-1.45-2.711A2.4 2.4 0 0 1 9.2 16.8h-.024a1.7 1.7 0 0 1-.168.351l-1.493 2.722Z"
            />
            <path fill="#33c481" d="M28.806 3h-9.225v6.5H30V4.191A1.19 1.19 0 0 0 28.806 3" />
            <path fill="#107c41" d="M19.581 16H30v6.5H19.581Z" />
          </svg>
        );
      default:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 16 16">
            <g fill="none">
              <path
                fill="url(#fluentColorDocument160)"
                d="M8.004 1L9.5 4.5L13 6l-.008 7.5a1.5 1.5 0 0 1-1.5 1.5H4.5A1.5 1.5 0 0 1 3 13.5v-11A1.5 1.5 0 0 1 4.5 1z"
              />
              <path
                fill="url(#fluentColorDocument162)"
                fillOpacity={0.5}
                d="M8.004 1L9.5 4.5L13 6l-.008 7.5a1.5 1.5 0 0 1-1.5 1.5H4.5A1.5 1.5 0 0 1 3 13.5v-11A1.5 1.5 0 0 1 4.5 1z"
              />
              <path fill="url(#fluentColorDocument161)" d="M8 4.5V1l5 5H9.5A1.5 1.5 0 0 1 8 4.5" />
              <defs>
                <linearGradient
                  id="fluentColorDocument160"
                  x1={9.994}
                  x2={11.264}
                  y1={1}
                  y2={12.781}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#6ce0ff" />
                  <stop offset={1} stopColor="#4894fe" />
                </linearGradient>
                <linearGradient
                  id="fluentColorDocument161"
                  x1={10.492}
                  x2={9.242}
                  y1={3.083}
                  y2={5.167}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9ff0f9" />
                  <stop offset={1} stopColor="#b3e0ff" />
                </linearGradient>
                <radialGradient
                  id="fluentColorDocument162"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientTransform="rotate(130.372 6.372 3.818)scale(8.35524 4.87457)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset={0.362} stopColor="#4a43cb" />
                  <stop offset={1} stopColor="#4a43cb" stopOpacity={0} />
                </radialGradient>
              </defs>
            </g>
          </svg>
        );
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', mt: 1 }}>
      {course?.detail_link?.link_document && <> {getFileIcon(course?.detail_link?.link_document)}</>}
      <Link
        href={`${course.url_storage}/${course.bucket_name}/${course.detail_link.link_document}`}
        target="_blank"
        rel="noopener noreferrer"
        fontSize="1.3rem"
        style={{ marginLeft: '10px', textDecoration: 'none', color: 'text.secondary' }}
      >
        {course.detail_link.link_document && cleanFileName(course.detail_link.link_document)}
      </Link>
    </Box>
  );
};

export default DocumentCourse;
