/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AddButton from '@/components/common/Button/AddButton';
import { Transition } from '@/components/common/Transition';
import useSocialApi from '@/Hooks/api/useSocial';
import { decrypt } from '@/utils/crypto';

interface AddTopicProps {
  openDialog: boolean;
  onDialogChange: (newValue: boolean) => void;
  handleGetListTopic: () => void;
}

const AddTopic: React.FC<AddTopicProps> = ({ openDialog, onDialogChange, handleGetListTopic }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleCreateTopic } = useSocialApi();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isEnter, setIsEnter] = useState(false);

  const handleTagsChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    setTags(value);
    setIsEnter(false);
  };

  const createSuccess = () => {
    onDialogChange(false);
    setValue('topicName', '');
    setTags([]);
    handleGetListTopic();
  };

  const validationSchema = yup.object().shape({
    topicName: yup.string().required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const params = {
      topic_name: data.topicName,
      key_word_main: tags,
      group_id: inforUser.groupId,
    };
    if (tags.length > 0) {
      await handleCreateTopic(params, createSuccess);
      setIsEnter(false);
    } else {
      setIsEnter(true);
    }
  };

  const handleBlur = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags((prevTags) => [...prevTags, inputValue]);
      setInputValue('');
    }
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        onDialogChange(false);
        setValue('topicName', '');
        setTags([]);
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
        overflow: 'visible',
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.addTopic')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 1, padding: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="topicName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  style={{ width: '100%' }}
                  sx={{
                    mt: 1,
                  }}
                  label={t('placeholder.topic')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  id="input-with-icon-textfield"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={tags}
              onChange={handleTagsChange}
              renderTags={
                (value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              sx={{
                mt: 3,
                '.MuiChip-label': {
                  fontSize: '1.4rem',
                },
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('placeholder.keyword')}
                  onBlur={handleBlur}
                  error={Boolean(isEnter)}
                />
              )}
            />
            {isEnter ? (
              <Typography sx={{ fontSize: '1.2rem', color: 'error.main', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            ) : (
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            )}
            <Box sx={{ display: 'flex', mt: 3 }}>
              <AddButton handleClick={handleSubmit(onSubmit)} />
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AddTopic;
