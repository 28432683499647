import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AosComponent from '@/components/animation/AosComponent';
import { useCommonInfo } from '@/contexts/Common';
import useAuth from '@/Hooks/api/useAuth';

interface IChangePasswordAfterReset {
  email: string;
}

const EnterPasswordAfterReset: React.FC<IChangePasswordAfterReset> = ({ email }) => {
  const { t } = useTranslation();
  const { handleResetPassword } = useAuth();
  const { apiError } = useCommonInfo();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigateSuccess = () => {
    setLoading(false);
    window.location.reload();
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/, t('validation.regexPassword')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), undefined], t('validation.confirmPassword'))
      .required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    handleResetPassword({ username: email, new_password: data.newPassword.trim() }, navigateSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  return (
    <AosComponent dataAos="fade-right">
      <Box
        sx={{
          width: { md: '600px', xs: '100%' },
          padding: 2,
          backgroundColor: 'background.main',
          boxShadow: 10,
          borderRadius: '8px',
        }}
      >
        <Typography variant="h6" sx={{ mt: 2, fontWeight: 400, color: 'text.primary' }}>
          {t('authen.enterNewPassword')}
        </Typography>
        <Box sx={{ marginBottom: 2, mt: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  autoFocus
                  type={showPassword ? 'text' : 'password'}
                  variant="filled"
                  fullWidth
                  style={{ marginTop: '15px' }}
                  onKeyDown={handleKeyDown}
                  label={t('placeholder.newPassword')}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword((prevShowPassword) => !prevShowPassword);
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type={showConfirmPassword ? 'text' : 'password'}
                  variant="filled"
                  fullWidth
                  style={{ marginTop: '15px' }}
                  label={t('placeholder.confirmNewPassword')}
                  error={!!fieldState.error}
                  onKeyDown={handleKeyDown}
                  helperText={fieldState.error ? fieldState.error.message : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
                          }}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            loading={loading}
            loadingIndicator={<CircularProgress size={30} />}
            variant="outlined"
            color="primary"
            fullWidth
            type="submit"
            onClick={handleSubmit(onSubmit)}
            sx={{
              width: { xs: '100%', sm: '40%' },
              mt: 3,
              fontWeight: '400',
              height: 45,
              textTransform: 'none',
              fontSize: '1.4rem',
              whiteSpace: 'nowrap',
            }}
          >
            {t('action.confirm')}
          </LoadingButton>
        </Box>
      </Box>
    </AosComponent>
  );
};
export default EnterPasswordAfterReset;
