import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, Typography, Box, Grid, Card, CardContent, Chip, Collapse } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import UseAnimations from 'react-useanimations';
import alertCircle from 'react-useanimations/lib/alertCircle';

import ErrorFilterModal from '@/components/Modal/ErrorFilterModal';
import FilterModal from '@/components/Modal/FilterModal';
import { useScanSubdomainInfo } from '@/contexts/ScanSubdomain';
import useAsm from '@/Hooks/api/useAsm';
import { DANGEROUS_PORT, PAGE_SIZE } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';
import { imgArray, OpenSSL, unlock } from '@/utils/imgBase64';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface StatisticsProps {
  countPort: any;
  countTech: any;
  countScheme: any;
  countLocation: any;
  countTotalDomain: number;
  countTotalUniqHost: number;
  countTotalUniqUrl: number;
}
const Statistics: React.FC<StatisticsProps> = ({
  countPort,
  countTech,
  countScheme,
  countLocation,
  countTotalDomain,
  countTotalUniqHost,
  countTotalUniqUrl,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleFilterInfoSubdomain } = useAsm();
  const { subdomain } = useScanSubdomainInfo();

  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));

  const [expandedPort, setExpandedPort] = useState(false);
  const [expandedTech, setExpandedTech] = useState(false);
  const [expandedLocation, setExpandedLocation] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState();
  const [dataResult, setDataResult] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [keyValue, setKeyValue] = useState({
    keyPort: '',
    keyScheme: '',
    keyTech: '',
    keyLocation: '',
  });

  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
    setKeyValue({
      keyPort: '',
      keyScheme: '',
      keyTech: '',
      keyLocation: '',
    });
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const initialRowCountPort = 5;
  const handleExpandClickPort = () => {
    setExpandedPort(!expandedPort);
  };
  const handleExpandClickTech = () => {
    setExpandedTech(!expandedTech);
  };

  const handleExpandClickLocation = () => {
    setExpandedLocation(!expandedLocation);
  };

  // sort port
  const portSort = _.orderBy(countPort, ['count', 'name'], ['desc', 'asc']);

  // sort location
  const locationSort = _.orderBy(countLocation, ['count', 'name'], ['desc', 'asc']);

  // scheme thêm image
  const schemeObjNew = _.map(countScheme, (item) => {
    if (item.name === 'http') {
      return { ...item, image: unlock };
    }
    if (item.name === 'https') {
      return { ...item, image: OpenSSL };
    }
    return { ...item, image: '' };
  });
  const schemeObjSort = _.orderBy(schemeObjNew, ['count', 'name'], ['desc', 'asc']);

  // tech thêm image
  const techObjNew = _.map(countTech, (item) => {
    const replacedName = item.name.replace(/[\s.]/g, '');
    if (typeof imgArray[0] === 'object') {
      const imgObjectArray = imgArray as any;
      const matchingImg = _.find(imgObjectArray, (obj) => replacedName in obj);
      if (matchingImg) {
        return { ...item, image: matchingImg[replacedName] };
      }
    }
    return { ...item, image: '' };
  });
  const techObjSort = _.orderBy(techObjNew, ['count', 'name'], ['desc', 'asc']);

  const handleFilterInfo = async (
    portKey: number | string,
    schemeKey: string,
    techKey: string,
    locationKey: string,
  ) => {
    const params = {
      url: inforUser?.role === 'super admin' ? subdomain.keySearch : domainActive,
      page: pageNumber,
      page_size: PAGE_SIZE,
      port: portKey,
      scheme: schemeKey,
      tech: techKey,
      geo_city: locationKey,
    };
    const dataResDetail = await handleFilterInfoSubdomain(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data, total_item } = dataResDetail;
    setDataResult(data);
    setCount(total_item);
    if (!_.isEmpty(data)) {
      setOpenFilterModal(true);
    } else {
      setOpenErrorModal(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'background.main',
          flexDirection: 'column',
          padding: 2,
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        <Card sx={{ boxShadow: 'none', border: `1px solid ${theme.palette.divider}` }}>
          <CardContent sx={{ padding: 1.5 }}>
            <Typography sx={{ color: 'text.primary' }} gutterBottom component="div">
              {t('asm.subdomain.statistics.title')}
            </Typography>

            <Grid container sx={{ justifyContent: 'space-between', fontSize: '0.95rem', fontWeight: 500 }}>
              <Grid item sx={{ color: 'text.secondary' }}>
                <Typography variant="body2">{t('asm.subdomain.statistics.countIp')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{countTotalUniqHost || 0}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ justifyContent: 'space-between', marginTop: 0.5, fontSize: '0.95rem', fontWeight: 500 }}
            >
              <Grid item sx={{ color: 'text.secondary' }}>
                <Typography variant="body2">{t('asm.subdomain.statistics.internetServices')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{countTotalDomain || 0}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: 2, boxShadow: 'none', border: `1px solid ${theme.palette.divider}` }}>
          <CardContent sx={{ padding: 1.5 }}>
            <Typography sx={{ color: 'text.primary' }} gutterBottom component="div">
              {t('asm.subdomain.statistics.byPort')}
            </Typography>

            {portSort?.slice(0, initialRowCountPort)?.map((item: any, index: number) => {
              return (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Chip
                      onClick={() => {
                        handleFilterInfo(item.name, '', '', '');
                        setKeyValue((prevState) => ({
                          ...prevState,
                          keyPort: item.name,
                        }));
                      }}
                      label={item.name}
                      variant="filled"
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}
                    />
                    {DANGEROUS_PORT.includes(item.name) ? (
                      <UseAnimations strokeColor="#f44336" animation={alertCircle} size={35} />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }}>
                    {item.count}
                  </Grid>
                </Grid>
              );
            })}

            <Collapse in={expandedPort} timeout="auto" unmountOnExit>
              {portSort?.slice(initialRowCountPort)?.map((item: any, index: number) => (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Chip
                      onClick={() => {
                        handleFilterInfo(item.name, '', '', '');
                        setKeyValue((prevState) => ({
                          ...prevState,
                          keyPort: item.name,
                        }));
                      }}
                      label={item.name}
                      variant="filled"
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}
                    />
                    {DANGEROUS_PORT.includes(+item.name) ? (
                      <UseAnimations strokeColor="#f44336" animation={alertCircle} size={35} />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }}>
                    {item.count}
                  </Grid>
                </Grid>
              ))}
            </Collapse>
            {portSort?.length > initialRowCountPort && (
              <Box sx={{ textAlign: 'center' }}>
                <ExpandMore
                  expand={expandedPort}
                  onClick={handleExpandClickPort}
                  aria-expanded={expandedPort}
                  aria-label="show more"
                >
                  <ExpandMoreIcon sx={{ fontSize: '2rem' }} />
                </ExpandMore>
              </Box>
            )}

            <Typography sx={{ color: 'text.primary', marginTop: 2 }} gutterBottom component="div">
              {t('asm.subdomain.statistics.byScheme')}
            </Typography>
            {schemeObjSort?.map((item: any, index: number) => {
              return (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid
                    item
                    onClick={() => {
                      handleFilterInfo('', item.name, '', '');
                      setKeyValue((prevState) => ({
                        ...prevState,
                        keyScheme: item.name,
                      }));
                    }}
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        width: '13px',
                        height: '13px',
                      }}
                    />
                    <Box
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        marginLeft: 0.5,
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}
                    >
                      {item.name}
                    </Box>
                  </Grid>
                  <Grid sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }} item>
                    {item.count}
                  </Grid>
                </Grid>
              );
            })}

            <Typography sx={{ color: 'text.primary', marginTop: 2 }} gutterBottom component="div">
              {t('asm.subdomain.statistics.byTech')}
            </Typography>
            {techObjSort?.slice(0, initialRowCountPort).map((item: any, index: number) => {
              return (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid
                    item
                    onClick={() => {
                      handleFilterInfo('', '', item.name, '');
                      setKeyValue((prevState) => ({
                        ...prevState,
                        keyTech: item.name,
                      }));
                    }}
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        width: '13px',
                        height: '13px',
                      }}
                    />
                    <Box
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        marginLeft: 0.5,
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}
                    >
                      {item.name}
                    </Box>
                  </Grid>
                  <Grid sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }} item>
                    {item.count}
                  </Grid>
                </Grid>
              );
            })}
            <Collapse in={expandedTech} timeout="auto" unmountOnExit>
              {techObjSort?.slice(initialRowCountPort).map((item, index) => (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid
                    item
                    onClick={() => {
                      handleFilterInfo('', '', item.name, '');
                      setKeyValue((prevState) => ({
                        ...prevState,
                        keyTech: item.name,
                      }));
                    }}
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        width: '13px',
                        height: '13px',
                      }}
                    />
                    <Box
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        marginLeft: 0.5,
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}
                    >
                      {item.name}
                    </Box>
                  </Grid>
                  <Grid sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }} item>
                    {item.count}
                  </Grid>
                </Grid>
              ))}
            </Collapse>
            {techObjSort?.length > initialRowCountPort && (
              <Box sx={{ textAlign: 'center' }}>
                <ExpandMore
                  expand={expandedTech}
                  onClick={handleExpandClickTech}
                  aria-expanded={expandedTech}
                  aria-label="show more"
                >
                  <ExpandMoreIcon sx={{ fontSize: '2rem' }} />
                </ExpandMore>
              </Box>
            )}

            <Typography sx={{ color: 'text.primary', marginTop: 2 }} gutterBottom component="div">
              {t('asm.subdomain.statistics.byLocation')}
            </Typography>

            {locationSort?.slice(0, initialRowCountPort)?.map((item: any, index: number) => {
              return (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box
                      onClick={() => {
                        handleFilterInfo('', '', '', item.name);
                        setKeyValue((prevState) => ({
                          ...prevState,
                          keyLocation: item.name,
                        }));
                      }}
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                        cursor: 'pointer',
                      }}
                    >
                      {item.name}
                    </Box>
                  </Grid>
                  <Grid item sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }}>
                    {item.count}
                  </Grid>
                </Grid>
              );
            })}

            <Collapse in={expandedLocation} timeout="auto" unmountOnExit>
              {locationSort?.slice(initialRowCountPort)?.map((item: any, index: number) => (
                <Grid
                  key={index}
                  container
                  sx={{ justifyContent: 'space-between', marginTop: 1, marginLeft: 1, alignItems: 'center' }}
                >
                  <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box
                      onClick={() => {
                        handleFilterInfo('', '', '', item.name);
                        setKeyValue((prevState) => ({
                          ...prevState,
                          keyLocation: item.name,
                        }));
                      }}
                      sx={{
                        '&:hover': {
                          color: `${theme.palette.info.main}`,
                        },
                        fontSize: '1.3rem',
                        fontWeight: 600,
                        color: 'text.secondary',
                        cursor: 'pointer',
                      }}
                    >
                      {item.name}
                    </Box>
                  </Grid>
                  <Grid item sx={{ fontSize: '1.3rem', marginRight: 1.5, fontWeight: 600 }}>
                    {item.count}
                  </Grid>
                </Grid>
              ))}
            </Collapse>
            {locationSort?.length > initialRowCountPort && (
              <Box sx={{ textAlign: 'center' }}>
                <ExpandMore
                  expand={expandedLocation}
                  onClick={handleExpandClickLocation}
                  aria-expanded={expandedLocation}
                  aria-label="show more"
                >
                  <ExpandMoreIcon sx={{ fontSize: '2rem' }} />
                </ExpandMore>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
      <FilterModal
        openModal={openFilterModal}
        dataResult={dataResult}
        count={count}
        handleCloseModal={handleCloseFilterModal}
        keyPort={keyValue.keyPort}
        keyScheme={keyValue.keyScheme}
        keyTech={keyValue.keyTech}
        keyLocation={keyValue.keyLocation}
      />
      <ErrorFilterModal open={openErrorModal} handleClose={handleCloseErrorModal} />
    </>
  );
};
export default Statistics;
