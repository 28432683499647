import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  Box,
  Typography,
  InputBase,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  Tooltip,
  useTheme,
  Avatar,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import InputAdornment from '@mui/material/InputAdornment';
import * as _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import UpdateButton from '@/components/common/Button/UpdateButton';
import { Transition } from '@/components/common/Transition';
import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useAuth from '@/Hooks/api/useAuth';
import useNotify from '@/Hooks/common/useNotify';
import { UserType } from '@/types/management';
import { decrypt } from '@/utils/crypto';

import UserBox from './UserBox';

interface UserManagementProps {
  listUsers: UserType[];
  listUserGroups: UserType[][];
  setIsAddUser: (newValue: boolean) => void;
  handleGetUser: () => Promise<void>;
  handleGetCompany: () => Promise<void>;
}

const UserManagement: React.FC<UserManagementProps> = ({
  listUsers,
  listUserGroups,
  setIsAddUser,
  handleGetUser,
  handleGetCompany,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { onNotify } = useNotify();
  const { handleEditGroup, handleDeleteGroup } = useAuth();

  const [filterUser, setFilterUser] = useState<UserType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ nameGroup: '', expireDate: '', idGroup: 0 });
  const [groupNameDelete, setGroupNameDelete] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    if (inforUser.role === 'super admin') {
      const filteredUsers = listUserGroups.flatMap((group: UserType[]) =>
        group.filter((user: UserType) => user?.user_name.toLowerCase().includes(event.target.value)),
      );
      setFilterUser(filteredUsers);
    } else {
      const filteredUsers = listUsers.filter((user: UserType) =>
        user?.user_name.toLowerCase().includes(event.target.value),
      );
      setFilterUser(filteredUsers);
    }

    if (event.target.value === '') {
      setFilterUser([]);
    }
  };

  const navigateEditSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogEdit(false);
    setSelectedGroup({ nameGroup: '', expireDate: '', idGroup: 0 });
  };

  const validationSchema = yup.object().shape({
    expireDate: yup.string().required(t('validation.fieldRequired')),
    groupName: yup.string().required(t('validation.fieldRequired')),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    if (data.expireDate !== selectedGroup.expireDate || data.groupName.trim() !== selectedGroup.nameGroup) {
      handleEditGroup(
        { expire_date_license: data.expireDate, group_name: data.groupName.trim(), group_id: selectedGroup.idGroup },
        navigateEditSuccess,
      );
    } else {
      onNotify('warning', t('notify.noValueChanged'), 3000);
    }
  };

  const navigateDeleteSuccess = () => {
    handleGetUser();
    handleGetCompany();
    setOpenDialogDelete(false);
  };

  const handleGroupDelete = () => {
    const params = {
      group_name: groupNameDelete,
    };
    handleDeleteGroup(params, navigateDeleteSuccess);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Box
          sx={{
            width: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          }}
        >
          <InputBase
            sx={{
              width: '100%',
              ml: 1,
              flex: 1,
              '.MuiInputBase-input': {
                padding: '10px 10px',
              },
            }}
            placeholder={t('placeholder.typeSearch')}
            inputProps={{
              'aria-label': 'filter company',
            }}
            endAdornment={
              searchValue && (
                <ClearIcon
                  onClick={() => {
                    setSearchValue('');
                    setFilterUser([]);
                  }}
                  sx={{
                    mr: 3,
                    color: 'text.secondary',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                />
              )
            }
            value={searchValue}
            onChange={handleInputChange}
          />
        </Box>
        {inforUser.role !== 'member' && (
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setIsAddUser(true);
            }}
            sx={{
              minWidth: 'fit-content',
              width: 'fit-content',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('action.addUser')}
          </Button>
        )}
      </Box>
      <Box>
        {searchValue ? (
          <>
            {!_.isEmpty(filterUser) ? (
              <UserBox
                item={filterUser}
                handleGetUser={handleGetUser}
                handleGetCompany={handleGetCompany}
                setSearchValue={setSearchValue}
              />
            ) : (
              <Box sx={{ width: '100%', borderRadius: 2, mt: 3, padding: 2, backgroundColor: 'action.hover' }}>
                <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noResult')}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            {inforUser.role !== 'super admin' ? (
              <Box sx={{ mt: 3 }}>
                <UserBox
                  item={listUsers}
                  handleGetUser={handleGetUser}
                  handleGetCompany={handleGetCompany}
                  setSearchValue={setSearchValue}
                />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2 }}>
                {listUserGroups?.map((group: UserType[], indexGroup: number) => (
                  <Box key={indexGroup} sx={{ display: 'flex', mt: 1, alignItems: 'baseline' }}>
                    <Accordion sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 3 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant="body2">{group[0]?.group_name}</Typography>
                          <Avatar
                            sx={{
                              ml: 2,
                              width: 20,
                              height: 20,
                              border: `1px solid ${theme.palette.primary.main}`,
                              bgcolor: theme.palette.action.hover,
                              color: 'primary.dark',
                              fontSize: '1.2rem',
                            }}
                          >
                            {group?.length}
                          </Avatar>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container sx={{ justifyContent: 'space-between' }}>
                          <UserBox
                            item={group}
                            handleGetUser={handleGetUser}
                            handleGetCompany={handleGetCompany}
                            setSearchValue={setSearchValue}
                          />
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Tooltip title={t('tooltip.edit')} placement="bottom">
                      <EditIcon
                        onClick={() => {
                          setOpenDialogEdit(true);
                          setSelectedGroup({
                            nameGroup: group[0]?.group_name,
                            expireDate: group[0]?.expire_date_license,
                            idGroup: group[0]?.group_id,
                          });
                          setValue('expireDate', group[0]?.expire_date_license);
                          setValue('groupName', group[0]?.group_name);
                        }}
                        sx={{
                          marginLeft: 1.5,
                          marginRight: 1.5,
                          fontSize: '2rem',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'info.main',
                          },
                        }}
                      />
                    </Tooltip>

                    <Dialog
                      open={openDialogEdit}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => {
                        setOpenDialogEdit(false);
                      }}
                      aria-describedby="alert-dialog-slide-description"
                      sx={{
                        '& .MuiDialog-container': {
                          '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '400px',
                            backgroundColor: 'background.dark',
                            border: `1px solid ${theme.palette.divider}`,
                          },
                        },
                      }}
                      BackdropProps={{
                        style: {
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        },
                      }}
                    >
                      <DialogTitle>
                        <Typography fontSize={16} sx={{ fontWeight: 600 }}>
                          {t('dialog.editGroup')}
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                              name="groupName"
                              control={control}
                              defaultValue={selectedGroup.nameGroup}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  type="text"
                                  style={{ width: '100%' }}
                                  label={t('management.asm.groups.groupName')}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  id="input-with-icon-textfield"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <GroupsIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  variant="standard"
                                />
                              )}
                            />
                            <Controller
                              name="expireDate"
                              control={control}
                              defaultValue={selectedGroup.expireDate}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  type="date"
                                  style={{ width: '100%', marginTop: '20px' }}
                                  label={t('management.asm.groups.expireDate')}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : null}
                                  id="input-with-icon-textfield"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarMonthIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  // eslint-disable-next-line react/jsx-no-duplicate-props
                                  inputProps={{
                                    min: new Date().toISOString().split('T')[0],
                                  }}
                                  variant="standard"
                                />
                              )}
                            />
                          </form>
                          <UpdateButton handleClick={handleSubmit(onSubmit)} />
                        </Box>
                      </DialogContent>
                    </Dialog>

                    <Tooltip title={t('tooltip.delete')} placement="bottom">
                      <DeleteIcon
                        onClick={() => {
                          setOpenDialogDelete(true);
                          setGroupNameDelete(group[0]?.group_name);
                        }}
                        sx={{
                          fontSize: '2rem',
                          cursor: 'pointer',
                          '&:hover': {
                            color: 'error.main',
                          },
                        }}
                      />
                    </Tooltip>
                    <ConfirmDeleteModal
                      openDialogDelete={openDialogDelete}
                      onOpenDialogDeleteChange={setOpenDialogDelete}
                      handleDelete={handleGroupDelete}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default UserManagement;
