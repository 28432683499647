import { useState, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/Share';
import ShieldIcon from '@mui/icons-material/Shield';
import {
  Button,
  useTheme,
  Box,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableRow,
  TableBody,
  Link,
  Skeleton,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import * as _ from 'lodash';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import useAsm from '@/Hooks/api/useAsm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CWEPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleListCWE } = useAsm();

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      search: params.search as string,
      page: params.page as string,
    };
  }, [location.search]);

  const [listCWE, setListCWE] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (queryParams.search && queryParams.search !== '') {
      setValue('searchKey', queryParams.search);
    }
    handleGetCWE();
  }, [queryParams]);

  const handleGetCWE = async () => {
    setLoading(true);
    const dataRes = await handleListCWE({
      search: queryParams.search,
      page: Number.parseInt(queryParams.page || '1', 10),
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { listCwe, total_page } = dataRes;
    setListCWE(listCwe);
    setTotalPage(total_page);
    setLoading(false);
  };

  const validationSchema = yup.object({
    searchKey: yup.string(),
  });

  const { control, handleSubmit, setValue } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    setTotalPage(0);
    const filter = {
      search: data.searchKey?.trim(),
    };
    navigate(`/cwe?${queryString.stringify(filter)}`);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleChangePage = (page: number) => {
    const filter = {
      ...queryParams,
      page,
    };
    navigate(`/cwe?${queryString.stringify(filter)}`);
  };

  const getNumber = (value: string) => {
    const regex = /^CWE-\d+$/;
    if (regex.test(value)) {
      return parseInt(value.match(/\d+/)?.[0] || '', 10);
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Categories (CWE) - ESS</title>
      </Helmet>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 2,
          backgroundColor: 'background.main',
          borderTop: `5px solid ${theme.palette.divider}`,
          borderRadius: '3px',
          padding: 1.5,
          boxShadow: 2,
        }}
      >
        <Box sx={{ marginBottom: 1.5 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              marginLeft: 0.5,
            }}
          >
            {t('cve.cwe.searchCWE')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="searchKey"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  style={{ width: '100%' }}
                  placeholder={t('placeholder.search')}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        onClick={handleSubmit(onSubmit)}
                        sx={{
                          fontSize: '2.2rem',
                          color: 'text.secondary',
                          mr: 1,
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      />
                    ),
                    endAdornment: queryParams.search && queryParams.search !== '' && (
                      <ClearIcon
                        onClick={() => {
                          setValue('searchKey', '');
                          navigate('/cwe');
                        }}
                        sx={{
                          color: 'text.secondary',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      />
                    ),
                  }}
                />
              )}
            />
          </form>
        </Box>
      </Box>
      {!_.isEmpty(listCWE) ? (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'background.main',
            flexDirection: 'column',
            padding: 2,
            borderRadius: '3px',
            boxShadow: 2,
          }}
        >
          <TableContainer
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '5px',
            }}
          >
            <Table aria-label="customized table">
              {!loading ? (
                <TableBody>
                  {listCWE.map((row: any) => (
                    <>
                      <StyledTableRow
                        key={row.id}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'action.focus',
                          },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: '60%' }}>
                          {row.description ? `${row.description}` : 'No name'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {getNumber(row.id) !== null ? (
                            <Link
                              href={`https://cwe.mitre.org/data/definitions/${getNumber(row.id)}`}
                              target="_blank"
                              sx={{
                                marginLeft: 1,
                                color: 'info.main',
                                '&:hover': {
                                  color: 'text.secondary',
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                  border: `1px solid ${theme.palette.text.primary}`,
                                  '&:hover': {
                                    border: `1px solid ${theme.palette.primary.light}`,
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    textTransform: 'none',
                                    fontWeight: 400,
                                    fontSize: '1rem',
                                    color: 'text.secondary',
                                    fontStyle: 'italic',
                                    '&:hover': {
                                      color: 'primary.main',
                                    },
                                  }}
                                >
                                  <ShareIcon sx={{ verticalAlign: 'text-top', height: '1.4rem' }} />
                                  Mitre
                                </Typography>
                              </Button>
                            </Link>
                          ) : null}
                          <Link
                            href={`/cve?cwe=${row.id}`}
                            rel="noopener"
                            sx={{
                              marginLeft: 1,
                              color: 'info.main',
                              '&:hover': {
                                color: 'text.secondary',
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{
                                mt: { xs: 1, md: 0 },
                                textTransform: 'none',
                                border: `1px solid ${theme.palette.text.primary}`,
                                '&:hover': {
                                  border: `1px solid ${theme.palette.primary.light}`,
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '1rem',
                                  color: 'text.secondary',
                                  '&:hover': {
                                    color: 'primary.main',
                                  },
                                  fontStyle: 'italic',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <ShieldIcon sx={{ verticalAlign: 'text-top', height: '1.4rem' }} />
                                {t('cve.cpe.viewCVE')}
                              </Typography>
                            </Button>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow style={{ width: '100%' }}>
                    <TableCell>
                      <Skeleton height={20} width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={20} width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton height={20} width="80%" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Pagination
              count={totalPage}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={Number.parseInt(queryParams.page || '1', 10)}
            />
          </Box>
        </Box>
      ) : (
        <>
          {loading ? (
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                boxShadow: 2,
              }}
            >
              <TableContainer
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '5px',
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                    <TableRow style={{ width: '100%' }}>
                      <TableCell>
                        <Skeleton height={20} width="80%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={20} width="80%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={20} width="80%" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                backgroundColor: `${theme.palette.divider}`,
                color: 'text.secondary',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                fontSize: '1.4rem',
              }}
            >
              {t('cve.cwe.noCweFound')}
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default CWEPage;
