import { useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  useTheme,
  Box,
  Modal,
  Typography,
  Paper,
  CircularProgress,
  Button,
  TableRow,
  TableCell,
  Drawer,
  Tooltip,
} from '@mui/material';
import * as _ from 'lodash';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useScanSubdomainInfo } from '@/contexts/ScanSubdomain';
import useAsm from '@/Hooks/api/useAsm';
import useSetKeySearch from '@/Hooks/common/useSetKeySearch';
import { PAGE_SIZE } from '@/utils/constants';

import PortModal from './PortModal';

interface Props {
  dataResult: any;
  count: any;
  openModal: boolean;
  handleCloseModal: () => void;
  keyPort: any;
  keyScheme: any;
  keyTech: any;
  keyLocation: any;
}

const FilterModal: React.FC<Props> = ({
  dataResult,
  count,
  openModal,
  handleCloseModal,
  keyPort,
  keyScheme,
  keyTech,
  keyLocation,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { subdomain } = useScanSubdomainInfo();
  const { handleFilterInfoSubdomain } = useAsm();
  const { setKeySearch } = useSetKeySearch();

  const [pageNumber, setPageNumber] = useState(0);
  const [dataFilter, setDataFilter] = useState(dataResult);
  const [loadingChangePage, setLoadingChangePage] = useState(false);
  const [showMoreCVE, setShowMoreCVE] = useState<any>({});
  const [showMoreTech, setShowMoreTech] = useState<any>({});

  const handleClickSubdomain = (url: any) => {
    setKeySearch(url);
    navigate('/info');
  };

  const handleChangeData = async (page = 0) => {
    const params = {
      url: subdomain.keySearch,
      page,
      page_size: PAGE_SIZE,
      port: keyPort,
      scheme: keyScheme,
      tech: keyTech,
    };
    setLoadingChangePage(true);
    const dataResDetail = await handleFilterInfoSubdomain(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data } = dataResDetail;
    setDataFilter(data);
    setLoadingChangePage(false);
  };

  const filterName = () => {
    let filterType;
    let filterValue;

    switch (true) {
      case keyPort !== '':
        filterType = 'Port';
        filterValue = keyPort;
        break;
      case keyScheme !== '':
        filterType = 'Scheme';
        filterValue = keyScheme;
        break;
      case keyTech !== '':
        filterType = 'Tech';
        filterValue = keyTech;
        break;
      default:
        filterType = 'Location';
        filterValue = keyLocation;
    }

    return (
      <>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'center', color: 'text.secondary' }}>
          {t('asm.subdomain.filter')} {filterType} :
        </Typography>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'center', color: 'text.primary', marginLeft: 1 }}>
          {filterValue}
        </Typography>
      </>
    );
  };

  const columns = [
    {
      name: 'url',
      label: 'URL',
      options: {
        customBodyRender: (value: any) => {
          return (
            <Typography
              variant="body2"
              onClick={() => handleClickSubdomain(value)}
              sx={{
                color: `${theme.palette.info.main}`,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'host',
      label: 'Host',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'port',
      label: 'Port',
      options: {
        sort: false,
        filter: false,
        download: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value?.length !== 0 ? (
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{
                    textTransform: 'none',
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    fontSize: '1.2rem',
                  }}
                >
                  {t('asm.subdomain.showPort')}
                </Button>
              ) : (
                <Typography sx={{ color: `${theme.palette.text.primary}` }}> - </Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'scheme',
      label: 'Scheme',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'tech',
      label: 'Tech',
      options: {
        customBodyRender: (value: [], rowIndex: any) =>
          !_.isEmpty(value) ? (
            <>
              {value.map((tech, index) => (
                <Typography
                  variant="body2"
                  key={index}
                  sx={{
                    display: index > 2 && !showMoreTech[rowIndex.rowIndex] ? 'none' : 'block',
                    padding: 0.4,
                  }}
                >
                  - {tech}
                </Typography>
              ))}
              {value.length > 3 && (
                <Typography
                  sx={{ fontSize: '2rem', color: '#7b8ff7', '&:hover': { color: 'rgba(255, 255, 255,0.8)' } }}
                  onClick={() => toggleShowMoreTech(rowIndex.rowIndex)}
                >
                  {showMoreTech[rowIndex.rowIndex] ? (
                    <Tooltip title={t('tooltip.showLess')}>
                      <ExpandLessIcon sx={{ fontSize: '2rem' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('tooltip.showMore')}>
                      <MoreHorizIcon sx={{ fontSize: '2rem' }} />
                    </Tooltip>
                  )}
                </Typography>
              )}
            </>
          ) : (
            '-'
          ),
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'waf',
      label: 'Waf',
      options: {
        searchable: false,
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'cve',
      label: 'CVE',
      options: {
        customBodyRender: (value: [], rowIndex: any) =>
          !_.isEmpty(value) ? (
            <>
              {value.map((cveNumber, index) => (
                <Typography
                  variant="body2"
                  key={index}
                  onClick={() => {
                    navigate(`/cve/${cveNumber}`);
                  }}
                  sx={{
                    display: index > 2 && !showMoreCVE[rowIndex.rowIndex] ? 'none' : 'block',
                    color: `${theme.palette.info.main}`,
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                    whiteSpace: 'nowrap',
                    padding: 0.4,
                  }}
                >
                  - {cveNumber}
                </Typography>
              ))}
              {value.length > 3 && (
                <Typography
                  sx={{ fontSize: '2rem', color: '#7b8ff7', '&:hover': { color: 'rgba(255, 255, 255, 0.8)' } }}
                  onClick={() => toggleShowMoreCVE(rowIndex.rowIndex)}
                >
                  {showMoreCVE[rowIndex.rowIndex] ? (
                    <Tooltip title={t('tooltip.showLess')}>
                      <ExpandLessIcon sx={{ fontSize: '2rem' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('tooltip.showMore')}>
                      <MoreHorizIcon sx={{ fontSize: '2rem' }} />
                    </Tooltip>
                  )}
                </Typography>
              )}
            </>
          ) : (
            '-'
          ),
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },
    search: false,
    serverSide: true,
    count,
    page: pageNumber,
    rowsPerPage: PAGE_SIZE,
    rowsPerPageOptions: [],
    onTableChange: (action: any, tableState: any) => {
      switch (action) {
        case 'changePage':
          setPageNumber(tableState.page);
          handleChangeData(tableState.page);
          break;
        case 'sort':
          // eslint-disable-next-line no-case-declarations
          const { sortColumn, sortDirection } = tableState;

          if (dataFilter.length === 0) {
            // eslint-disable-next-line no-case-declarations
            const sortedData = [...dataResult].sort((a, b) => {
              const aValue = a[sortColumn];
              const bValue = b[sortColumn];
              if (sortDirection === 'asc') {
                return aValue > bValue ? 1 : -1;
              }
              return aValue < bValue ? 1 : -1;
            });
            setDataFilter(sortedData);
          } else {
            // eslint-disable-next-line no-case-declarations
            const sortedData = [...dataFilter].sort((a, b) => {
              const aValue = a[sortColumn];
              const bValue = b[sortColumn];
              if (sortDirection === 'asc') {
                return aValue > bValue ? 1 : -1;
              }
              return aValue < bValue ? 1 : -1;
            });
            setDataFilter(sortedData);
          }
          break;
        default:
          break;
      }
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    rowsExpanded: keyPort ? (dataFilter.length === 0 ? dataResult : dataFilter).map((el: any, i: any) => i) : [],
    isRowExpandable: (dataIndex: any) => {
      return dataFilter.length === 0
        ? dataResult[dataIndex]?.port.length !== 0
        : dataFilter[dataIndex]?.port.length !== 0;
    },
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const colSpan = rowData.length + 1;
      const { dataIndex } = rowMeta;
      const expandPort = dataFilter.length === 0 ? dataResult[dataIndex]?.port : dataFilter[dataIndex].port;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <PortModal port={expandPort} />
          </TableCell>
        </TableRow>
      );
    },
    fixedHeader: false,
    tableBodyHeight: '78vh',
  };
  const componentsResult = {
    ExpandButton(props: any) {
      if (
        props.dataIndex !== undefined &&
        props.dataIndex !== null &&
        dataResult[props.dataIndex] &&
        dataResult[props.dataIndex].port.length !== 0
      )
        return <ExpandButton {...props} />;
      return <div style={{ width: '24px' }} />;
    },
  };
  const componentsFilter = {
    ExpandButton(props: any) {
      if (
        props.dataIndex !== undefined &&
        props.dataIndex !== null &&
        dataFilter[props.dataIndex] &&
        dataFilter[props.dataIndex].port.length !== 0
      )
        return <ExpandButton {...props} />;
      return <div style={{ width: '24px' }} />;
    },
  };

  const toggleShowMoreCVE = (id: number) => {
    setShowMoreCVE((prevShowMoreCVE: any) => ({
      ...prevShowMoreCVE,
      [id]: !prevShowMoreCVE[id],
    }));
  };

  const toggleShowMoreTech = (id: number) => {
    setShowMoreTech((prevShowMoreTech: any) => ({
      ...prevShowMoreTech,
      [id]: !prevShowMoreTech[id],
    }));
  };

  return (
    <Drawer
      open={openModal}
      onClose={() => {
        handleCloseModal();
        setPageNumber(0);
        setDataFilter([]);
      }}
      anchor="right"
    >
      <Box
        sx={{
          width: '60vw',
          bgcolor: 'background.main',
        }}
      >
        <Box
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {filterName()}
        </Box>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <MUIDataTable
            title={
              loadingChangePage && (
                <div style={{ color: 'text.primary' }}>
                  <CircularProgress color="inherit" />
                </div>
              )
            }
            data={dataFilter.length === 0 ? dataResult : dataFilter}
            columns={columns}
            options={options as any}
            components={dataFilter.length === 0 ? componentsResult : componentsFilter}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
export default FilterModal;
