/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';

import { ThreadsPostsTypes } from '@/types/Social';

import { DisplaySentiment } from '../ButtonSentiment';

interface TitlePostsThreadsProps {
  item: ThreadsPostsTypes;
}

const TitlePostsThreads: React.FC<TitlePostsThreadsProps> = ({ item }) => {
  const locale = localStorage.getItem('locale') || 'vi';

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={2}>
        <Link href={`https://www.threads.net/@${item?.username}`} target="_blank" rel="noopener">
          <Avatar
            alt="image"
            variant="rounded"
            src={item?.user_avatar}
            sx={{
              borderRadius: '50%',
              '.MuiAvatar-img': {
                objectFit: 'contain',
              },
            }}
          />
        </Link>
        <Box>
          <Stack direction="row">
            <Link
              href={`https://www.threads.net/@${item?.username}`}
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                ':hover': {
                  textDecoration: 'underline',
                },
                mr: 0.5,
              }}
            >
              <Typography sx={{ fontWeight: 600, color: 'text.primary' }}>{item?.username}</Typography>
            </Link>
            {item?.user_verified && (
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                <path
                  fill="#00a0ee"
                  fillRule="evenodd"
                  d="M15.418 5.643a1.25 1.25 0 0 0-1.34-.555l-1.798.413a1.25 1.25 0 0 1-.56 0l-1.798-.413a1.25 1.25 0 0 0-1.34.555l-.98 1.564c-.1.16-.235.295-.395.396l-1.564.98a1.25 1.25 0 0 0-.555 1.338l.413 1.8a1.25 1.25 0 0 1 0 .559l-.413 1.799a1.25 1.25 0 0 0 .555 1.339l1.564.98c.16.1.295.235.396.395l.98 1.564c.282.451.82.674 1.339.555l1.798-.413a1.25 1.25 0 0 1 .56 0l1.799.413a1.25 1.25 0 0 0 1.339-.555l.98-1.564c.1-.16.235-.295.395-.395l1.565-.98a1.25 1.25 0 0 0 .554-1.34L18.5 12.28a1.25 1.25 0 0 1 0-.56l.413-1.799a1.25 1.25 0 0 0-.554-1.339l-1.565-.98a1.25 1.25 0 0 1-.395-.395zm-.503 4.127a.5.5 0 0 0-.86-.509l-2.615 4.426l-1.579-1.512a.5.5 0 1 0-.691.722l2.034 1.949a.5.5 0 0 0 .776-.107z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </Stack>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item?.post_time
              ? format(new Date(item?.post_time), locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm", {
                  locale: locale === 'vi' ? vi : enUS,
                })
              : 'NaN'}
          </Typography>
        </Box>
      </Stack>
      <DisplaySentiment sentiment={item?.sentiment} />
    </Stack>
  );
};
export default TitlePostsThreads;
