/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Avatar, Box, Link, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';

import { RedditPostsTypes } from '@/types/Social';

import { DisplaySentiment } from '../ButtonSentiment';

interface TitlePostsRedditProps {
  item: RedditPostsTypes;
}

const TitlePostsReddit: React.FC<TitlePostsRedditProps> = ({ item }) => {
  const locale = localStorage.getItem('locale') || 'vi';

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={2}>
        <Link href={`https://www.reddit.com/user/${item?.author}`}>
          <Avatar
            alt="image"
            variant="rounded"
            // src={item?.author_avatar}
            sx={{
              borderRadius: '50%',
              '.MuiAvatar-img': {
                objectFit: 'contain',
              },
            }}
          />
        </Link>
        <Box sx={{}}>
          <Link
            href={`https://www.reddit.com/user/${item?.author}`}
            target="_blank"
            rel="noopener"
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {item?.author}
            </Typography>
          </Link>

          <Tooltip
            title={
              item?.time
                ? format(new Date(item?.time), locale === 'vi' ? "dd MMM 'lúc' HH:mm" : "dd MMM 'at' HH:mm", {
                    locale: locale === 'vi' ? vi : enUS,
                  })
                : 'NaN'
            }
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', cursor: 'pointer' }}>
              {item?.time
                ? format(new Date(item?.time), locale === 'vi' ? 'dd MMM' : 'dd MMM ', {
                    locale: locale === 'vi' ? vi : enUS,
                  })
                : 'NaN'}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
      <DisplaySentiment sentiment={item?.sentiment} />
    </Stack>
  );
};
export default TitlePostsReddit;
