/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';

import { Box, Grid, Pagination, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import SkeletonPostsSocial from '@/components/Skeleton/SkeletonPostsSocial';
import { useCommonInfo } from '@/contexts/Common';
import { useSocialInfo } from '@/contexts/Social';
import useSocial from '@/Hooks/api/useSocial';
import { SentimentStateTypes } from '@/types/Social';
import { PAGE_SIZE, SEARCH_YOUTUBE, TYPE_SEARCH } from '@/utils/constants';

import PostsYoutube from './PostsYoutube';

interface YoutubeProps {
  sentimentState: SentimentStateTypes;
}

const Youtube: React.FC<YoutubeProps> = ({ sentimentState }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleGetPostSocial } = useSocial();
  const { topicSocial, timeScan } = useSocialInfo();
  const { apiError } = useCommonInfo();

  const [dataYoutube, setDataYoutube] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (topicSocial && timeScan) {
      handleRequest(1);
      if (page !== 1) {
        setPage(1);
      }
    }
  }, [sentimentState, topicSocial, timeScan]);

  useEffect(() => {
    if (topicSocial && timeScan && page !== 1) {
      handleRequest();
    }
  }, [page]);

  const handleRequest = async (pageValue?: number) => {
    const listSentiments = (Object.keys(sentimentState) as (keyof SentimentStateTypes)[]).filter(
      (key) => sentimentState[key] === true,
    );
    setLoading(true);
    const params = {
      keyword: topicSocial?.key_word_main,
      social: SEARCH_YOUTUBE,
      search_type: TYPE_SEARCH,
      time_range: timeScan,
      page: pageValue ?? page,
      page_size: PAGE_SIZE,
      sentiments: listSentiments,
    };
    if (!_.isEmpty(listSentiments)) {
      const res = await handleGetPostSocial(params, handleLoading);
      const { data } = res;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { results, total_results } = data;
      setDataYoutube(results);
      setTotal(total_results);
    } else {
      setDataYoutube([]);
      setTotal(0);
      setLoading(false);
    }
  };

  const handleChangePage = (pageValue: number) => {
    setPage(pageValue);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        {!loading ? (
          <>
            {!_.isEmpty(dataYoutube) ? (
              <Grid container spacing={2}>
                {dataYoutube.map((item: any, index: number) => (
                  <Grid key={index} item xs={12} md={6}>
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                        padding: 2,
                        minHeight: '280px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PostsYoutube item={item} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {' '}
                  {t('notify.noData')}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ borderRadius: 1, padding: 2, minHeight: '235px' }}>
                <SkeletonPostsSocial />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ borderRadius: 1, padding: 2, minHeight: '235px' }}>
                <SkeletonPostsSocial />
              </Box>
            </Grid>
          </Grid>
        )}
        {!_.isEmpty(dataYoutube) && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
            <Pagination
              count={Math.ceil(total / PAGE_SIZE)}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={page}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
export default Youtube;
