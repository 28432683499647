import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';

interface IProps {
  children: React.ReactNode;
}

const NewsManagement = ({ children }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/management/news/list');
        break;
      case 1:
        navigate('/management/news/report');
        break;
      default:
        navigate('/management/news/list');
        break;
    }
  };
  const path = location.pathname;
  useEffect(() => {
    switch (path) {
      case '/management/news/list':
        setValue(0);
        break;
      case '/management/news/report':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [path]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.news.titleNews')}
      </Typography>

      <Box sx={{ mt: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <StyledTab label={t('management.news.management')} />
            <StyledTab label={t('management.news.report')} />
          </StyledTabs>
        </Box>
        <Box sx={{ mt: 3 }}>{children}</Box>
      </Box>
    </>
  );
};
export default NewsManagement;
