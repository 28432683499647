import React, { useEffect, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  useTheme,
  Box,
  Grid,
  Chip,
  Typography,
  TableRow,
  TableCell,
  Stack,
  Breadcrumbs,
  Link,
  Table,
  TableHead,
  TableBody,
  Skeleton,
  Divider,
} from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { formatTimeCVEDetail } from '@/components/common/format/FormatTimeCVE';
import useBreakpoints from '@/helpers/useBreakpoints';
import useAsm from '@/Hooks/api/useAsm';
import {
  CNATypes,
  CreditsTypes,
  CveMetadataType,
  CvssType,
  CweTypes,
  ProblemType,
  ReferencesTypes,
  VendorAffectedTypes,
  VersionVendorTypes,
} from '@/types/CVE';

import Header from '../HomePage/Header';
import HeaderMobile from '../HomePage/HeaderMobile';

interface PropsCvssV3Label {
  point: number;
}

const CVEDetails = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoints();
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleListCVE } = useAsm();
  const { id } = useParams();

  const [cna, setCna] = useState<CNATypes>();
  const [references, setReferences] = useState<ReferencesTypes[]>([]);
  const [vendors, setVendors] = useState<VendorAffectedTypes[]>([]);
  const [credits, setCredits] = useState<CreditsTypes[]>([]);
  const [cwe, setCwe] = useState<CweTypes[]>([]);
  const [cvss, setCvss] = useState<CvssType[]>([]);
  const [cveMetadata, setCveMetadata] = useState<CveMetadataType>();

  const [loading, setLoading] = useState(false);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => {
        navigate('/cve');
      }}
      sx={{ cursor: 'pointer' }}
    >
      CVE
    </Link>,
    <Typography key="2" color="text.primary" sx={{ fontSize: isMobile ? '1.4rem' : '1.6rem', fontWeight: '500' }}>
      {id}
    </Typography>,
  ];

  useEffect(() => {
    handleDetailCVE();
  }, [id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleDetailCVE = async () => {
    setLoading(true);
    // gọi list cve
    const dataRes = await handleListCVE({ id });
    const { detailCve } = dataRes;
    setCna(detailCve?.containers?.cna);
    setReferences(detailCve?.containers?.cna?.references);
    setVendors(detailCve?.containers?.cna?.affected);
    setCredits(detailCve?.containers?.cna?.credits);
    setCwe(detailCve?.containers?.cna?.problemTypes?.flatMap((item: ProblemType) => item.descriptions));
    setCvss(getCweDataFromMetrics(detailCve?.containers?.cna?.metrics));
    setCveMetadata(detailCve?.cveMetadata);

    setLoading(false);
  };

  const CvssLabelTab: React.FC<PropsCvssV3Label> = ({ point }) => {
    const stateCvss = (value: number) => {
      if (value >= 0.1 && value <= 3.9) {
        return { label: t('cve.cvss.low'), bgr: '#00c0ef' };
      }
      if (value >= 4.0 && value <= 6.9) {
        return { label: t('cve.cvss.medium'), bgr: '#f39c12' };
      }
      if (value >= 7.0 && value <= 8.9) {
        return { label: t('cve.cvss.high'), bgr: '#dd4b39' };
      }
      if (value >= 9.0 && value <= 10.0) {
        return { label: t('cve.cvss.critical'), bgr: '#972b1e' };
      }
      return { label: 'N/A', bgr: '#d2d6de' };
    };
    const cvssState = stateCvss(point);
    return (
      <Typography
        sx={{
          color: '#fff',
          backgroundColor: cvssState.bgr,
          fontWeight: 700,
          paddingInline: 1,
          borderRadius: '4px',
          alignSelf: 'center',
          marginLeft: 1,
          fontSize: '1.2rem',
          whiteSpace: 'nowrap',
          textTransform: 'uppercase',
          width: 'fit-content',
        }}
      >
        {point} {cvssState.label}
      </Typography>
    );
  };

  const getCweDataFromMetrics = (
    metricsArray: any[],
  ): { baseScore: number; baseSeverity: string; vectorString: string; version: string }[] => {
    if (!Array.isArray(metricsArray)) {
      return [];
    }
    return metricsArray
      .map((metric) => {
        const possibleCvssVersions = ['cvssV4_0', 'cvssV3_1', 'cvssV3_0'];

        const versionKey = possibleCvssVersions.find((key) => metric[key]);

        if (versionKey) {
          const { baseScore, baseSeverity, vectorString, version } = metric[versionKey];
          return { baseScore, baseSeverity, vectorString, version };
        }

        return undefined;
      })
      .filter(
        (item): item is { baseScore: number; baseSeverity: string; vectorString: string; version: string } => !!item,
      ); // Lọc bỏ undefined
  };

  return (
    <>
      <Helmet>
        <title>{id} - ESS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.dark',
          zIndex: `${theme.zIndex.drawer + 1}`,
          color: `${theme.palette.grey[600]}`,
          boxShadow: 'none',
          borderBottom: 'none',
          padding: 2,
        }}
      >
        {isMobile || isTablet ? <HeaderMobile /> : <Header />}

        <Grid
          container
          direction="column"
          sx={{ mt: '70px', left: 0, right: 0, minHeight: '100vh', padding: { xs: 0, md: 2 } }}
        >
          <Grid item>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
              {cna?.title && (
                <Typography
                  key="2"
                  color="text.secondary"
                  sx={{ fontSize: isMobile ? '1.2rem' : '1.4rem', fontStyle: 'italic' }}
                >
                  {`-  ${cna?.title}`}
                </Typography>
              )}
            </Stack>
          </Grid>
          {!loading ? (
            <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item md={9.3} xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      borderRadius: '3px',
                      borderTop: `5px solid ${theme.palette.divider}`,
                      backgroundColor: 'background.main',
                      padding: 2,
                      color: `${theme.palette.text.secondary}`,
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <span style={{ float: 'left', fontSize: '3rem', lineHeight: '36px', marginRight: '10px' }}>
                      {cna?.descriptions[0].value[0]}
                    </span>
                    {cna?.descriptions[0].value.slice(1)}
                  </Typography>

                  {/* CVSS */}
                  <Stack
                    sx={{
                      borderTop: `5px solid ${theme.palette.divider}`,
                      marginTop: 2,
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                      CVSS
                    </Typography>
                    {!_.isEmpty(cvss) ? (
                      <Table sx={{ marginTop: 2, width: '100%' }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('cve.cveDetail.score')}</TableCell>
                            <TableCell>{t('cve.cveDetail.severity')}</TableCell>
                            <TableCell>{t('cve.cveDetail.version')}</TableCell>
                            <TableCell>Vector string</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cvss.map((item: CvssType, index: number) => (
                            <TableRow key={item.version}>
                              <TableCell>
                                <Typography variant="body2" sx={{ padding: '10px 8px' }}>
                                  {item?.baseScore}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" sx={{ padding: '10px 8px' }}>
                                  <CvssLabelTab point={item?.baseScore} />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" sx={{ padding: '10px 8px' }}>
                                  {item?.version}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" sx={{ wordBreak: 'break-all', padding: '10px 8px' }}>
                                  {item?.vectorString}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Box
                        sx={{
                          marginTop: 1,
                          padding: 1,
                          backgroundColor: `${theme.palette.divider}`,
                          fontSize: '1.4rem',
                          color: 'text.secondary',
                        }}
                      >
                        {t('cve.cveDetail.noCVSS')}
                      </Box>
                    )}
                  </Stack>

                  {/*  Vendors & Products */}
                  <Stack
                    sx={{
                      borderTop: `5px solid ${theme.palette.divider}`,
                      marginTop: 2,
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                      {t('cve.cveDetail.affectedVendors')}
                    </Typography>
                    {!_.isEmpty(vendors) ? (
                      <Table sx={{ marginTop: 2, width: '100%' }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('cve.cveDetail.vendor')}</TableCell>
                            <TableCell>{t('cve.cveDetail.product')}</TableCell>
                            <TableCell>{t('cve.cveDetail.version')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {vendors.map((item: VendorAffectedTypes) => (
                            <TableRow key={item.vendor}>
                              <TableCell sx={{ padding: '10px 8px' }}>{item.vendor}</TableCell>
                              <TableCell sx={{ padding: '10px 8px' }}>{item.product}</TableCell>
                              <TableCell sx={{ padding: '10px 8px' }}>
                                <Stack direction="column">
                                  {item.versions?.map((version: VersionVendorTypes) => (
                                    <Box key={version.version} sx={{ padding: 0.5, mt: 1 }}>
                                      {item.defaultStatus && (
                                        <Stack direction="row">
                                          <Typography
                                            variant="body2"
                                            sx={{ color: 'text.secondary', fontWeight: 600, fontStyle: 'italic' }}
                                          >
                                            {t('cve.cveDetail.defaultStatus')}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 1, color: 'text.primary', fontStyle: 'italic' }}
                                          >
                                            {item.defaultStatus}
                                          </Typography>
                                        </Stack>
                                      )}
                                      <Stack direction="row" sx={{ mt: 0.5 }}>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                          {version.status}
                                        </Typography>
                                        {version.lessThanOrEqual ? (
                                          <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
                                            from
                                          </Typography>
                                        ) : (
                                          <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
                                            at
                                          </Typography>
                                        )}
                                        <Typography
                                          variant="body2"
                                          sx={{ ml: 0.5, color: 'info.main', fontWeight: 600 }}
                                        >
                                          {version.version}
                                        </Typography>
                                        {version.lessThanOrEqual && (
                                          <>
                                            <Typography variant="body2" sx={{ ml: 0.5, color: 'text.secondary' }}>
                                              through
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{ ml: 0.5, color: 'info.main', fontWeight: 600 }}
                                            >
                                              {version.lessThanOrEqual}
                                            </Typography>
                                          </>
                                        )}
                                      </Stack>
                                    </Box>
                                  ))}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Box
                        sx={{
                          marginTop: 1,
                          padding: 1,
                          backgroundColor: `${theme.palette.divider}`,
                          fontSize: '1.4rem',
                          color: 'text.secondary',
                        }}
                      >
                        {t('cve.cveDetail.noAffectedVendors')}
                      </Box>
                    )}
                  </Stack>

                  {/* CWE */}
                  <Stack
                    sx={{
                      borderTop: `5px solid ${theme.palette.divider}`,
                      marginTop: 2,
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400', mb: 1 }}>
                      CWEs
                    </Typography>
                    {!_.isEmpty(cwe) ? (
                      <>
                        {cwe.map((cweItem: CweTypes) => (
                          <>
                            <Stack key={cweItem.cweId} direction="row" sx={{ mt: 1, ml: 2 }}>
                              <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 500 }}>
                                {cweItem.cweId}
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1, color: 'primary.main', fontWeight: 500 }}>
                                :
                              </Typography>
                              <Typography variant="body2" sx={{ ml: 1, color: 'primary.main', fontWeight: 500 }}>
                                {cweItem.description}
                              </Typography>
                            </Stack>
                            <Divider sx={{ mt: 1 }} />
                          </>
                        ))}
                      </>
                    ) : (
                      <Box
                        sx={{
                          marginTop: 1,
                          padding: 1,
                          backgroundColor: `${theme.palette.divider}`,
                          fontSize: '1.4rem',
                          color: 'text.secondary',
                        }}
                      >
                        {t('cve.cveDetail.noCwe')}
                      </Box>
                    )}
                  </Stack>

                  {/* References */}
                  <Stack
                    sx={{
                      borderTop: `5px solid ${theme.palette.divider}`,
                      marginTop: 2,
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                      {t('cve.cveDetail.references')}
                    </Typography>
                    {!_.isEmpty(references) ? (
                      <Table sx={{ marginTop: 2, width: '100%' }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('cve.cveDetail.link')}</TableCell>
                            <TableCell>Tags</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {references.map((item: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell sx={{ padding: '10px 8px' }}>
                                <Link
                                  href={item.url}
                                  target="_blank"
                                  color="text.primary"
                                  sx={{
                                    textDecoration: 'none',
                                    fontSize: '1.4rem',
                                    wordBreak: 'break-word',
                                    color: `${theme.palette.info.light}`,
                                    '&:hover': {
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    },
                                  }}
                                >
                                  {item.url}
                                </Link>
                              </TableCell>
                              <TableCell width="40%" sx={{ padding: '10px 8px' }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                  }}
                                >
                                  {item.tags?.map((tag: string) => (
                                    <Box key={tag} sx={{ padding: 0.5 }}>
                                      <Chip
                                        sx={{
                                          backgroundColor: '#d2d6de',
                                          height: '20px',
                                          color: '#161C24',
                                          fontWeight: 600,
                                          '& .MuiChip-label': {
                                            fontSize: { xs: '1rem', md: '1.1rem' },
                                            padding: { xs: '5px', md: '0px 12px' },
                                          },
                                        }}
                                        label={tag}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Box
                        sx={{
                          marginTop: 1,
                          padding: 1,
                          backgroundColor: `${theme.palette.divider}`,
                          fontSize: '1.4rem',
                          color: 'text.secondary',
                        }}
                      >
                        {t('cve.cveDetail.noReferences')}
                      </Box>
                    )}
                  </Stack>

                  {/* Credits */}
                  <Stack
                    sx={{
                      borderTop: `5px solid ${theme.palette.divider}`,
                      marginTop: 2,
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                      {t('cve.cveDetail.credits')}
                    </Typography>
                    {!_.isEmpty(credits) ? (
                      <Table sx={{ marginTop: 2, width: '100%' }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('cve.cveDetail.nameCredits')}</TableCell>
                            <TableCell>{t('cve.cveDetail.typeCredits')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {credits.map((credit: CreditsTypes) => (
                            <TableRow key={credit.value}>
                              <TableCell sx={{ padding: '10px 8px' }}>{credit?.value}</TableCell>
                              <TableCell width="40%" sx={{ padding: '10px 8px' }}>
                                <Chip
                                  sx={{
                                    backgroundColor: '#d2d6de',
                                    height: '20px',
                                    color: '#161C24',
                                    fontWeight: 600,
                                    '& .MuiChip-label': {
                                      fontSize: { xs: '1rem', md: '1.1rem' },
                                      padding: { xs: '5px', md: '0px 12px' },
                                    },
                                  }}
                                  label={credit?.type}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Box
                        sx={{
                          marginTop: 1,
                          padding: 1,
                          backgroundColor: `${theme.palette.divider}`,
                          fontSize: '1.4rem',
                          color: 'text.secondary',
                        }}
                      >
                        {t('cve.cveDetail.noCredits')}
                      </Box>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={2.4} xs={12} sx={{ marginTop: { md: 0, xs: '20px' }, width: '100%' }}>
                  <Stack
                    sx={{
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderTop: `5px solid ${theme.palette.divider}`,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Typography color="text.primary" sx={{ fontSize: '1.8rem', fontWeight: '400' }}>
                      MITRE
                    </Typography>
                    <Box sx={{ marginTop: 1, paddingBottom: 2 }}>
                      <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                        >
                          {t('cve.cveDetail.status')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                          {cveMetadata?.state}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                        >
                          {t('cve.cveDetail.assigner')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                          {cveMetadata?.assignerShortName}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                        >
                          {t('cve.cveDetail.published')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                          {cveMetadata?.datePublished && formatTimeCVEDetail(cveMetadata?.datePublished)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                        >
                          {t('cve.cveDetail.updated')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                          {cveMetadata?.dateUpdated && formatTimeCVEDetail(cveMetadata?.dateUpdated)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                        >
                          {t('cve.cveDetail.reserved')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
                          {cveMetadata?.dateReserved && formatTimeCVEDetail(cveMetadata?.dateReserved)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginTop: 1.5 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
                        >
                          {t('cve.cveDetail.cveLink')}
                        </Typography>
                        <Link
                          href={`https://www.cve.org/CVERecord?id=${id}`}
                          target="_blank"
                          sx={{
                            marginLeft: 1,
                            color: 'info.main',
                            fontSize: '1.4rem',
                            '&:hover': {
                              color: 'text.secondary',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {id}
                        </Link>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item md={9.3} xs={12}>
                  <Stack
                    sx={{
                      borderTop: `5px solid ${theme.palette.divider}`,
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Skeleton width="50%" height={30} />
                    <Skeleton width="80%" height={30} />
                  </Stack>
                </Grid>
                <Grid item md={2.4} xs={12} sx={{ mt: { xs: 2, md: 0 } }}>
                  <Stack
                    sx={{
                      backgroundColor: 'background.main',
                      padding: 2,
                      borderRadius: '3px',
                      borderTop: `5px solid ${theme.palette.divider}`,
                      boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                    }}
                  >
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={30} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
export default CVEDetails;
