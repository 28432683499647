/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, useRef, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  useTheme,
  Box,
  Paper,
  TableContainer,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  CardMedia,
  Chip,
} from '@mui/material';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import failedImg from '@/assets/image/failed.gif';
import Chart from '@/components/Chart';
import ScanButton from '@/components/common/Button/ScanButton';
import StopButton from '@/components/common/Button/StopButton';
import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanDoneNotice from '@/components/common/Notice/ScanDoneNotice';
import ScanTime from '@/components/common/ScanTime';
import { useCommonInfo } from '@/contexts/Common';
import { useScanVulInfo } from '@/contexts/ScanVul';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useBreakpoints from '@/helpers/useBreakpoints';
import useAsm from '@/Hooks/api/useAsm';
import useNotify from '@/Hooks/common/useNotify';
import useScanError from '@/Hooks/common/useScanError';
import useVul from '@/Hooks/useVul';
import { PAGE_SIZE, REGEX_DOMAIN } from '@/utils/constants';
import { decrypt, encrypt } from '@/utils/crypto';

import PdfView from './PdfView';

interface Count {
  crit: number;
  high: number;
  info: number;
  low: number;
  medium: number;
}

const Vulnerability = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const theme = useTheme();
  const { onNotify } = useNotify();
  const { isScanError } = useCommonInfo();
  const { vulnerability } = useScanVulInfo();
  const { handleScanVul } = useAsm();
  const { setScanError } = useScanError();
  const { setVulnerability } = useVul();
  const intervalRef = useRef<any>();

  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();

  const [vulState, setVulState] = useState({
    vul: vulnerability?.dataVul,
    count: vulnerability?.severityCounts,
    isRunning: vulnerability?.stateRunning,
    isFirstCall: vulnerability?.isFirstCall,
    isSuccessApi: vulnerability?.isSuccessApi,
    prog: vulnerability?.progress || 1,
    reportPdf: vulnerability?.report || 'null',
  });

  // const [count, setCount] = useState<Count>(vulnerability?.severityCounts);
  const [speed, setSpeed] = useState('slow');
  // const [profile, setProfile] = useState('11111111-1111-1111-1111-111111111112');
  const [animation, setAnimation] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [domain, setDomain] = useState(domainActive);
  const [updateTime, setUpdateTime] = useState('');
  const [statusScanWebsocket, setStatusScanWebsocket] = useState(false);

  const filteredList = dataWebSocket.filter((item: any) => item.domain === domain);

  useEffect(() => {
    if (isScanError) {
      setVulState((prevState) => ({
        ...prevState,
        isRunning: false,
        isSuccessApi: false,
      }));
      clearInterval(intervalRef.current);
      clearInterval(vulnerability?.intervalId);
      setVulnerability({
        ...vulnerability,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
      });
      setScanError(false);
    }
  }, [isScanError]);

  useEffect(() => {
    if (vulnerability?.intervalId) {
      clearInterval(vulnerability?.intervalId);
    }
  }, []);

  useEffect(() => {
    if (vulState.isRunning && !vulnerability?.status) {
      if (vulState.isFirstCall) {
        handleScan();
      }
      if (vulState.isSuccessApi) {
        intervalRef.current = setInterval(() => {
          handleScan();
        }, 10000);
      } else {
        clearInterval(intervalRef.current);
        handleCacheVul();
      }
    } else {
      clearInterval(intervalRef.current);
      setVulnerability({
        ...vulnerability,
        dataVul: vulState.vul,
        progress: vulState.prog,
        severityCounts: vulState.count,
        report: vulState.reportPdf,
        stateRunning: false,
        isSuccessApi: vulState.isSuccessApi,
        isFirstCall: vulState.isFirstCall,
        keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
        intervalId: intervalRef.current,
      });
    }
    return () => {
      handleCacheVul();
    };
  }, [vulState.isRunning, vulState.isFirstCall, vulState.isSuccessApi]);

  const handleScan = async () => {
    const searchKey = inforUser?.role === 'super admin' ? getValues('searchKey') : domain;
    const params = {
      target: searchKey,
      speed,
      scan_profile: '11111111-1111-1111-1111-111111111111',
      // scan_profile: profile,
    };
    const dataRes = await handleScanVul(params);
    const { data, html_report } = dataRes;
    const { vulnerabilities, severity_counts, progress, status } = data;
    setVulState((prevState) => ({
      ...prevState,
      isFirstCall: false,
      isSuccessApi: true,
      vul: vulnerabilities,
      count: severity_counts,
    }));
    setAnimation(false);
    if (progress >= 1) {
      setVulState((prevState) => ({
        ...prevState,
        prog: progress,
      }));
    }
    if (status === 'completed') {
      setVulnerability({
        ...vulnerability,
        dataVul: vulnerabilities,
        progress,
        severityCounts: severity_counts,
        report: html_report,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
        status: 'completed',
      });
      clearInterval(intervalRef.current);
      clearInterval(vulnerability?.intervalId);
      setVulState((prevState) => ({
        ...prevState,
        isRunning: false,
        reportPdf: html_report,
        isSuccessApi: false,
      }));
      onNotify('success', t('notify.scanVulnerabilityDone'), null);
      return;
    }
    if (status === 'aborting') {
      setVulnerability({
        ...vulnerability,
        dataVul: vulnerabilities,
        progress,
        severityCounts: severity_counts,
        report: html_report,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
        status: 'aborting',
      });
      clearInterval(intervalRef.current);
      clearInterval(vulnerability?.intervalId);
      setVulState((prevState) => ({
        ...prevState,
        isRunning: false,
        isSuccessApi: false,
      }));
      onNotify('error', t('notify.scanVulnerabilityFailed'), null);
      return;
    }
    setVulnerability({
      ...vulnerability,
      dataVul: vulnerabilities,
      progress,
      severityCounts: severity_counts,
      report: html_report,
      stateRunning: vulState.isRunning,
      isSuccessApi: vulState.isSuccessApi,
      isFirstCall: vulState.isFirstCall,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
    });
  };

  const handleCacheVul = () =>
    setVulnerability({
      ...vulnerability,
      dataVul: vulState.vul,
      progress: vulState.prog,
      severityCounts: vulState.count,
      report: vulState.reportPdf,
      stateRunning: vulState.isRunning,
      isSuccessApi: vulState.isSuccessApi,
      isFirstCall: vulState.isFirstCall,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
    });

  // validate search
  const validationSchema = yup.object({
    searchKey: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(REGEX_DOMAIN, t('validation.invalidDomain')),
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      searchKey: vulnerability?.keySearch,
    },
  });

  const onSubmit = () => {
    setVulnerability(null);
    setVulState((prevState) => ({
      ...prevState,
      vul: [],
      count: {} as Count,
      prog: 1,
      isRunning: true,
      isFirstCall: true,
    }));
  };

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      const dataVulWebsocket = filteredList.find((item: any) => item.title === 'scanVul')?.data;
      const { data, html_report } = dataVulWebsocket || {};
      const { vulnerabilities, severity_counts, progress } = data || {};
      if (!_.isEmpty(vulnerabilities)) {
        setVulState((prevState) => ({
          ...prevState,
          vul: vulnerabilities,
          count: severity_counts,
          prog: progress,
          reportPdf: html_report,
        }));
        setUpdateTime(filteredList.find((item: any) => item.title === 'scanVul')?.update_time);
        setStatusScanWebsocket(false);
      } else {
        setVulState((prevState) => ({
          ...prevState,
          vul: [],
          count: {} as Count,
          reportPdf: 'null',
        }));
        setStatusScanWebsocket(true);
      }
    }
  }, [domain, dataWebSocket]);

  const handleChangeSpeed = (event: SelectChangeEvent) => {
    setSpeed(event.target.value as string);
  };
  // const handleChangeProfile = (event: SelectChangeEvent) => {
  //   setProfile(event.target.value as string);
  // };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  function FacebookCircularProgress(props: CircularProgressProps) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: () => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: () => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  const ApexChart = useCallback(() => {
    return <Chart count={vulState.count || {}} animation={animation} />;
  }, [!animation, vulState.count]);

  const SeverityLabel = (bgColor: string, label: string) => {
    return <Chip sx={{ backgroundColor: bgColor, height: '25px', color: '#fff', fontWeight: 600 }} label={label} />;
  };

  const DisplaySeverity = (severityScore: number) => {
    switch (severityScore) {
      case 0:
        return SeverityLabel('#20C997FF', 'Info');
      case 1:
        return SeverityLabel('#0DCAF0FF', 'Low');
      case 2:
        return SeverityLabel('#FFC107FF', 'Medium');
      case 3:
        return SeverityLabel('#f44336', 'High');
      case 4:
        return SeverityLabel('#9c27b0', 'Critical');
      default:
        return SeverityLabel('rgb(173, 177, 176)', 'N/A');
    }
  };

  const columns = [
    {
      name: 'affects_detail',
      label: 'Affects Detail',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'affects_url',
      label: 'Affects Url',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'confidence',
      label: 'Confidence',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: number) => value || '-',
      },
    },
    {
      name: 'criticality',
      label: 'Criticality',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: number) => value || '-',
      },
    },
    {
      name: 'last_seen',
      label: 'Last Seen',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'severity',
      label: 'Severity',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => {
          return DisplaySeverity(value) || '-';
        },
      },
    },
    {
      name: 'tags',
      label: 'Tags',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: []) =>
          !_.isEmpty(value) ? value.map((tagItem, tagIndex) => <p key={tagIndex}>- {tagItem}</p>) : '-',
      },
    },
    {
      name: 'vt_name',
      label: 'Vulnerability',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string) => value || '-',
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },
  };

  const optionsNotSuperAdmin = {
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: false,
    selectableRowsHideCheckboxes: true,
    count: vulState.vul?.length,
    rowsPerPage: PAGE_SIZE,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Scan Vulnerabilities - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.vulnerabilities.title')}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {showPdf ? (
          <PdfView data={vulState.reportPdf} setShowPdf={setShowPdf} />
        ) : (
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.main',
              flexDirection: 'column',
              padding: 2,
              borderRadius: 1,
              boxShadow: 3,
            }}
          >
            {inforUser?.role === 'super admin' ? (
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: isMobile ? '100%' : '40%' }}>
                  <Controller
                    name="searchKey"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoFocus
                        variant="outlined"
                        helperText={errors.searchKey?.message?.toString()}
                        error={Boolean(errors.searchKey)}
                        style={{ width: '100%' }}
                        placeholder={t('placeholder.mainDomain')}
                        disabled={!!vulState.isRunning}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  />
                </form>
                <Box sx={{ marginLeft: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="speed-label">{t('asm.vulnerabilities.speed')}</InputLabel>
                    <Select
                      labelId="speed-label"
                      value={speed}
                      label={t('asm.vulnerabilities.speed')}
                      onChange={handleChangeSpeed}
                    >
                      <MenuItem sx={{ fontSize: '1.4rem' }} value="sequential">
                        {t('asm.vulnerabilities.sequential')}
                      </MenuItem>
                      <MenuItem sx={{ fontSize: '1.4rem' }} value="slow">
                        {t('asm.vulnerabilities.slow')}
                      </MenuItem>
                      <MenuItem sx={{ fontSize: '1.4rem' }} value="moderate">
                        {t('asm.vulnerabilities.moderate')}
                      </MenuItem>
                      <MenuItem sx={{ fontSize: '1.4rem' }} value="fast">
                        {t('asm.vulnerabilities.fast')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* <Box sx={{ marginLeft: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="profile-label">Profile</InputLabel>
                  <Select labelId="profile-label" value={profile} label="Profile" onChange={handleChangeProfile}>
                    <MenuItem value="11111111-1111-1111-1111-111111111111">Full Scan</MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111112">High Risk Vulnerabilities</MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111113">SQL Injection Vulnerabilities</MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111114">Continuous Full</MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111115">Weak Passwords</MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111116">
                      Cross-site Scripting Vulnerabilities
                    </MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111117">Crawl Only</MenuItem>
                    <MenuItem value="11111111-1111-1111-1111-111111111118">Continuous Quick</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}
                {vulState.isRunning ? (
                  <StopButton
                    handleClick={() => {
                      setVulState((prevState) => ({
                        ...prevState,
                        isSuccessApi: false,
                        isRunning: false,
                      }));
                    }}
                  />
                ) : (
                  <ScanButton handleClick={handleSubmit(onSubmit)} />
                )}
              </Box>
            ) : (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
                {!_.isEmpty(vulState.vul && updateTime) && <ScanTime updateTime={updateTime} />}
              </Box>
            )}

            <Box sx={{ flexGrow: 1, marginTop: 5 }}>
              {(vulState.isRunning || statusScanWebsocket) && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ marginTop: 3, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
                  >
                    {t('notify.scanInProgress')}
                  </Typography>
                  <br />
                  <Box sx={{ width: '100%', marginTop: 1, marginBottom: 3 }}>
                    <FacebookCircularProgress />
                  </Box>
                </>
              )}
              {vulnerability?.status === 'completed' && vulState.isRunning !== undefined && <ScanDoneNotice />}

              {vulnerability?.status === 'aborting' && (
                <Box
                  sx={{
                    color: `${theme.palette.text.primary}`,
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2,
                  }}
                >
                  <CardMedia component="img" sx={{ width: 70 }} image={failedImg} alt="green iguana" />
                  <Typography sx={{ color: 'text.primary' }} component="div">
                    {t('notify.scanFailed')}
                  </Typography>
                </Box>
              )}
              <br />
              <BorderLinearProgress variant="determinate" value={vulState.prog} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ApexChart />
            </Box>
            {inforUser?.role === 'super admin' ? (
              <>
                {vulnerability?.status === 'completed' && vulState.reportPdf !== 'null' && (
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      setShowPdf(true);
                    }}
                  >
                    {t('action.showReport')}
                  </Button>
                )}
              </>
            ) : (
              <>
                {vulnerability?.status === 'completed' ||
                  (vulState.reportPdf !== 'null' && (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        setShowPdf(true);
                      }}
                    >
                      {t('action.showReport')}
                    </Button>
                  ))}
              </>
            )}
            <TableContainer
              component={Paper}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                marginTop: 3,
              }}
            >
              <MUIDataTable
                title=""
                data={vulState.vul}
                columns={columns}
                options={inforUser?.role === 'super admin' ? (options as any) : (optionsNotSuperAdmin as any)}
              />
            </TableContainer>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Vulnerability;
