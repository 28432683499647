import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  useTheme,
  Box,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
} from '@mui/material';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import useAsm from '@/Hooks/api/useAsm';

const AnalysisKeyword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleAnalysisCVE } = useAsm();

  const [inputValueKeyword, setInputValueKeyword] = useState('');
  const [inputValueEmail, setInputValueEmail] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const schema = yup.object().shape({
    keyword: yup.array().of(yup.string().required()).min(1, t('validation.fieldRequired')),
    emails: yup
      .array()
      .of(yup.string().email(t('validation.invalidEmail')))
      .min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      keyword: [],
      emails: [],
    },
  });

  const navigateSuccess = () => {
    setValue('keyword', []);
    setValue('emails', []);
    setOpenDialog(true);
  };

  const onSubmit = async (data: any) => {
    const params = { list_cve: data.keyword, list_user: data.emails };
    await handleAnalysisCVE(params, navigateSuccess);
  };

  const handleInputKeyDownKeyword = (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: string[],
    onChange: (newValues: string[]) => void,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValueKeyword) {
        const isValidKeyword = schema.isValidSync(inputValueKeyword);

        if (isValidKeyword) {
          onChange([...value, inputValueKeyword]);
          setInputValueKeyword('');
        }
      }
    }
  };

  const handleInputKeyDownEmail = (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: string[],
    onChange: (newValues: string[]) => void,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValueKeyword) {
        const isValidEmail = schema.isValidSync(inputValueEmail);

        if (!isValidEmail) {
          setError('emails', { message: t('validation.invalidEmail') });
        } else {
          clearErrors('emails');
          onChange([...value, inputValueEmail]);
          setInputValueEmail('');
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Analysis By Keyword - ESS</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {t('smcc.analysisKeyword')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ justifyContent: 'space-between', marginTop: 3 }}>
          <Grid item xs={12} md={5.9} sx={{ mb: { xs: 2 } }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                boxShadow: 2,
              }}
            >
              <Controller
                name="keyword"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    freeSolo
                    inputValue={inputValueKeyword}
                    onInputChange={(event, newValue) => {
                      if (event?.type !== 'blur') {
                        setInputValueKeyword(newValue);
                      }
                    }}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '1.2rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('placeholder.keyword')}
                        error={!!errors.keyword}
                        helperText={errors.keyword?.message}
                        onKeyDown={
                          (event: React.KeyboardEvent<HTMLInputElement>) =>
                            handleInputKeyDownKeyword(
                              event,
                              (field.value ?? []).filter((val: string): val is string => val !== undefined),
                              (newValue) => field.onChange(newValue),
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5.9} sx={{ mb: { xs: 2 } }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                boxShadow: 2,
              }}
            >
              <Controller
                name="emails"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={[]}
                    freeSolo
                    inputValue={inputValueEmail}
                    onInputChange={(event, newValue) => {
                      if (event?.type !== 'blur') {
                        setInputValueEmail(newValue);
                      }
                    }}
                    onChange={(event, value) => {
                      const validValues = value.filter((val) => yup.string().email().isValidSync(val));
                      field.onChange(validValues);
                      if (validValues.length !== value.length) {
                        setError('emails', { message: t('validation.invalidEmail') });
                      } else {
                        clearErrors('emails');
                      }
                    }}
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '1.2rem',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('placeholder.email')}
                        error={!!errors.emails}
                        helperText={errors.emails?.message}
                        onKeyDown={
                          (event: React.KeyboardEvent<HTMLInputElement>) =>
                            handleInputKeyDownEmail(
                              event,
                              (field.value ?? []).filter((val): val is string => val !== undefined),
                              (newValue) => field.onChange(newValue),
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    )}
                  />
                )}
              />
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          {t('action.send')}
        </Button>
      </form>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              backgroundColor: 'background.dark',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
        }}
        BackdropProps={{
          style: {
            opacity: 0.2,
            boxShadow: 'none',
          },
        }}
      >
        <DialogContent>
          <Typography fontSize={16}>{t('notify.analysisCVE')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            variant="outlined"
            sx={{ color: 'text.primary', fontWeight: 500 }}
          >
            {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AnalysisKeyword;
