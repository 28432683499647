import { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { LOGO_ESS_COLOR } from '@/utils/imgBase64';

interface PdfViewProps {
  data: any;
  setShowPdf: (newValue: boolean) => void;
}
const PdfView: React.FC<PdfViewProps> = ({ data, setShowPdf }) => {
  const { t } = useTranslation();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      // Giải mã dãy Base64 thành dữ liệu nhị phân
      const binaryData = atob(data);

      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Tạo một Blob từ dữ liệu nhị phân
      const blob = new Blob([uint8Array.buffer], { type: 'text/html' });

      // Tạo một URL đối tượng (Object URL) để hiển thị tệp PDF
      const blobUrl: any = URL.createObjectURL(blob);

      if (iframeRef.current && 'src' in iframeRef.current) {
        iframeRef.current.src = blobUrl;

        iframeRef.current.onload = () => {
          if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument;

            if (iframeDocument) {
              // Tìm và thay thế chữ "Acunetix" hoặc "acunetix" bằng "ESS"
              const replaceTextNodes = (node: any) => {
                if (node.nodeType === Node.TEXT_NODE && node.textContent) {
                  node.textContent = node.textContent.replace(/Acunetix/gi, 'ESS');
                } else {
                  node.childNodes.forEach(replaceTextNodes);
                }
              };

              // Áp dụng hàm đệ quy cho mỗi node trong body
              iframeDocument.body.childNodes.forEach(replaceTextNodes);

              const logoImages = Array.from(iframeDocument.getElementsByClassName('logo'));
              logoImages.forEach((img) => {
                if (img.tagName === 'IMG' && 'src' in img) {
                  img.src = `data:image/png;base64,${LOGO_ESS_COLOR}`;
                }
              });
              setLoading(false);
            }
          }
        };
      }
    }
  }, [data]);

  const handleDownload = () => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        const htmlContent = iframeDocument.documentElement.outerHTML;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'vulnerabilities.html';
        link.click();

        URL.revokeObjectURL(downloadUrl);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'background.main',
          flexDirection: 'column',
          padding: 2,
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              setShowPdf(false);
            }}
          >
            <ArrowBackIcon sx={{ fontSize: '2rem', mr: 1 }} /> Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, textTransform: 'capitalize' }}
            onClick={handleDownload}
            disabled={!iframeRef.current}
          >
            <DownloadIcon sx={{ mr: 1 }} />
            {t('action.download')}
          </Button>
        </Box>
        <Box sx={{ marginTop: 3, height: '100vh' }}>
          <iframe
            ref={iframeRef}
            title="Base64 to HTML"
            width="100%"
            style={{ minHeight: '100vh', display: loading ? 'none' : 'block' }}
          />
        </Box>
      </Box>
    </>
  );
};
export default PdfView;
