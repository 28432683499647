import PATH from '../Enums/api';

// const AUTH_PREFIX = 'api/v1/auth';
const META_PREFIX = 'api/v1';

const HOST = process.env.REACT_APP_URL;

const getBaseUrl = (prefix: string) => {
  return `${HOST}/${prefix}`;
};

export const urlApi = {
  HOST,
  auth: {
    login: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.LOGIN}`,
    loginGoogle: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.LOGIN_GOOGLE}`,
    logoutAccount: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.LOGOUT_GOOGLE}`,
    loginLocal: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.LOGIN_LOCAL}`,
    verify2FA: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.VERIFY_2FA}`,
    resetPassword: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.RESET_PASSWORD}`,
    changePassword: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.CHANGE_PASSWORD}`,
    sendCode: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.SEND_PASS_CODE}`,
    createUser: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.CREATE_USER}`,
    getInfo: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.GET_INFO_USER}`,
    checkUser: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.CHECK_USER_EXIST}`,
    getListUsers: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.GET_LIST_USERS}`,
    getListCompanies: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.GET_LIST_COMPANIES}`,
    editCompany: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.EDIT_COMPANY}`,
    editGroup: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.EDIT_GROUP}`,
    deleteGroup: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.DELETE_GROUP}`,
    changePermission: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.CHANGE_PERMISSION}`,
    checkSuperAdmin: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.CHECK_SUPERADMIN}`,
    createSuperAdmin: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.CREATE_SUPERADMIN}`,
    deleteUser: `${getBaseUrl(META_PREFIX)}/${PATH.AUTH.DELETE_USER}`,
  },
  search: `${getBaseUrl(META_PREFIX)}/statistics/attitude/time-based`,
  chartCommunityAttitude: `${getBaseUrl(META_PREFIX)}/statistics/keyword/time-based`,
  remainingTokens: `${getBaseUrl(META_PREFIX)}/user/tokens/remaining-token/`,
  scanSubdomain: `${getBaseUrl(META_PREFIX)}/search_amp`,
  searchSubdomain: `${getBaseUrl(META_PREFIX)}/subdomain`,
  searchDns: `${getBaseUrl(META_PREFIX)}/dns_domain`,
  searchInfo: `${getBaseUrl(META_PREFIX)}/owner_domain`,
  searchCidr: `${getBaseUrl(META_PREFIX)}/cidr_domain`,
  searchPort: `${getBaseUrl(META_PREFIX)}/port_domain`,
  searchSsl: `${getBaseUrl(META_PREFIX)}/ssl_domain`,
  scanVul: `${getBaseUrl(META_PREFIX)}/scan_vul_domain`,
  siteMap: `${getBaseUrl(META_PREFIX)}/crawl_domain`,
  statistics: `${getBaseUrl(META_PREFIX)}/statistical_infor_domain`,
  filterInfoSubdomain: `${getBaseUrl(META_PREFIX)}/filter_infor_domain`,
  invicti: `${getBaseUrl(META_PREFIX)}/invicti`,
  notification: {
    sendFcmToken: `${getBaseUrl(META_PREFIX)}/${PATH.NOTIFICATION_TOKEN}`,
  },
  scoreSystem: `${getBaseUrl(META_PREFIX)}/score_system`,
  asset: {
    createAsset: `${getBaseUrl(META_PREFIX)}/create_product`,
    editAsset: `${getBaseUrl(META_PREFIX)}/edit_product`,
    deleteAsset: `${getBaseUrl(META_PREFIX)}/delete_product`,
    listAsset: `${getBaseUrl(META_PREFIX)}/list_product`,
    listCVE: `${getBaseUrl(META_PREFIX)}/list-cve-vendor`,
  },
  weather: {
    ipLookup: `${getBaseUrl(META_PREFIX)}/location_ip`,
    listWeather: `${getBaseUrl(META_PREFIX)}/weather`,
  },
  cve: {
    listCVE: `${getBaseUrl(META_PREFIX)}/cve`,
    listCWE: `${getBaseUrl(META_PREFIX)}/cwe`,
    listVendors: `${getBaseUrl(META_PREFIX)}/vendor`,
    analysisCVE: `${getBaseUrl(META_PREFIX)}/analysis-cve`,
  },
  news: {
    listNews: `${getBaseUrl(META_PREFIX)}/list_news`,
    detailNews: `${getBaseUrl(META_PREFIX)}/detail_news`,
    filterNews: `${getBaseUrl(META_PREFIX)}/filter_news`,
    topNews: `${getBaseUrl(META_PREFIX)}/top_news`,
  },
  phishing: {
    listCampaign: `${getBaseUrl(META_PREFIX)}/phishing`,
    campaign: `${getBaseUrl(META_PREFIX)}/phishing/`,
    getContent: `${getBaseUrl(META_PREFIX)}/phishing/content_generating`,
    landingPage: `${getBaseUrl(META_PREFIX)}/phishing/landing_page_create`,
    listMalware: `${getBaseUrl(META_PREFIX)}/malware/attachments`,
    createMalware: `${getBaseUrl(META_PREFIX)}/malware/attachments/`,
    malware: `${getBaseUrl(META_PREFIX)}/malware/`,
  },
  identity: {
    search: `${getBaseUrl(META_PREFIX)}/user/search/`,
  },
  social: {
    createTopic: `${getBaseUrl(META_PREFIX)}/social-media/create-topic`,
    editTopic: `${getBaseUrl(META_PREFIX)}/social-media/edit-topic`,
    deleteTopic: `${getBaseUrl(META_PREFIX)}/social-media/delete-topic`,
    listTopic: `${getBaseUrl(META_PREFIX)}/social-media/list-topic`,
    detailTopic: `${getBaseUrl(META_PREFIX)}/social-media/detail-topic`,
    overview: `${getBaseUrl(META_PREFIX)}/social_listening/overview`,
    search: `${getBaseUrl(META_PREFIX)}/social_listening/search`,
    trend: `${getBaseUrl(META_PREFIX)}/social_listening/trend`,
    statistical: `${getBaseUrl(META_PREFIX)}/social_listening/statistical`,
    report: `${getBaseUrl(META_PREFIX)}/social-media/analysis-list`,
  },
  manage: {
    listNews: `${getBaseUrl(META_PREFIX)}/list-news-manage`,
    editNews: `${getBaseUrl(META_PREFIX)}/edit-news-manage`,
    deleteNews: `${getBaseUrl(META_PREFIX)}/delete-news-manage`,
    listPermissionIdentity: `${getBaseUrl(META_PREFIX)}/authentication/list_permission_investigate/`,
    permissionIdentity: `${getBaseUrl(META_PREFIX)}/authentication/permission_investigate/`,
    list2fa: `${getBaseUrl(META_PREFIX)}/authentication/list-2fa-enable/`,
    disable2fa: `${getBaseUrl(META_PREFIX)}/authentication/disable-2fa/`,
    uploadCourse: `${getBaseUrl(META_PREFIX)}/academy-cyber/upload-file`,
    editCourse: `${getBaseUrl(META_PREFIX)}/academy-cyber/edit-course`,
    listCourses: `${getBaseUrl(META_PREFIX)}/academy-cyber/list-course`,
    deleteCourse: `${getBaseUrl(META_PREFIX)}/academy-cyber/delete-course`,
    viewCourse: `${getBaseUrl(META_PREFIX)}/academy-cyber/views-course`,
    reportNews: `${getBaseUrl(META_PREFIX)}/summarize`,
    sessionTelegram: `${getBaseUrl(META_PREFIX)}/telegram_sessions`,
    uploadSession: `${getBaseUrl(META_PREFIX)}/telegram_sessions/upload`,
    addAPI: `${getBaseUrl(META_PREFIX)}/social_listening/add_apikey_serper`,
    updateAPI: `${getBaseUrl(META_PREFIX)}/social_listening/update_apikey_serper`,
    deleteAPI: `${getBaseUrl(META_PREFIX)}/social_listening/delete_apikey_serper`,
    detailAPI: `${getBaseUrl(META_PREFIX)}/social_listening/get_apikey_serper`,
    getAllAPI: `${getBaseUrl(META_PREFIX)}/social_listening/get_all_apikey_serper`,
  },
  malware: {
    analyze: `${process.env.REACT_APP_URL_MALWARE}/scan_file`,
  },
  osint: {
    listOsint: `${getBaseUrl(META_PREFIX)}/osint/osint_domain`,
  },
  securityAssessment: {
    listTool: `${getBaseUrl(META_PREFIX)}/security-assessment/list-file-security-assessment`,
    download: `${getBaseUrl(META_PREFIX)}/security-assessment/download-file-security-assessment`,
  },
  source: {
    file: `${getBaseUrl(META_PREFIX)}/submit_scan`,
    repo: `${getBaseUrl(META_PREFIX)}/submit_scan`,
    snippet: `${getBaseUrl(META_PREFIX)}/submit_scan`,
    safety: `${process.env.REACT_APP_URL_MALWARE}/quick_file_check`,
    result: `${getBaseUrl(META_PREFIX)}/get_scan_result`,
    gl_oauth_callback: `${getBaseUrl(META_PREFIX)}/gitlab_oauth_callback`,
    gh_oauth_callback: `${getBaseUrl(META_PREFIX)}/github_oauth_callback`,
  },
  dataleak: {
    listKeywords: `${getBaseUrl(META_PREFIX)}/data-leak/list_key_word`,
    listAllKeywords: `${getBaseUrl(META_PREFIX)}/data-leak/download_total_data_keyword`,
    createKeywords: `${getBaseUrl(META_PREFIX)}/data-leak/create_key_word`,
    deleteKeywords: `${getBaseUrl(META_PREFIX)}/data-leak/delete_key_word`,
    listDarkweb: `${getBaseUrl(META_PREFIX)}/data-leak/list_data_darkweb`,
  },
};
