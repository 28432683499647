import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AuthGuard from '@/components/Auth/AuthGuard';
import Logged from '@/components/Auth/Logged';
import SuperAdminGuard from '@/components/Auth/SuperAdminGuard';
import AsmLayout from '@/layouts/asm/AsmLayout';
import DataleakLayout from '@/layouts/Dataleak/DataleakLayout';
import IdentityLayout from '@/layouts/Identity/IdentityLayout';
import LearningLayout from '@/layouts/Learning/LearningLayout';
import APIKeyManagementLayout from '@/layouts/Management/APIKeyManagementLayout';
import ManagementLayout from '@/layouts/Management/ManagementLayout';
import NewsManagementLayout from '@/layouts/Management/NewsManagementLayout';
import NewsLayout from '@/layouts/news/NewsLayout';
import PhishingMailLayout from '@/layouts/PhishingMail/PhishingMailLayout';
import SecurityAssessmentLayout from '@/layouts/SecurityAssessment/SecurityAssessmentLayout';
import SocialLayout from '@/layouts/Social/SocialLayout';
import Asset from '@/pages/asm/Asset/Asset';
import Info from '@/pages/asm/Informations/Info';
import Report from '@/pages/asm/ScanVul/Report';
import Vulnerability from '@/pages/asm/ScanVul/Vulnerability';
import Score from '@/pages/asm/ScoreCVSS/ScoreCVSS';
import Spider from '@/pages/asm/SiteMap/Spider';
import Subdomains from '@/pages/asm/Subdomains/Subdomains';
import Login from '@/pages/authentication/Login';
import SuperAdmin from '@/pages/authentication/SuperAdmin';
import CodescanResults from '@/pages/Code/CodescanResult';
import GitHubCallback from '@/pages/Code/GitHubCallback';
import GitLabCallback from '@/pages/Code/GitLabCallback';
import SourceCodeScan from '@/pages/Code/SourceCodeScan';
import Contact from '@/pages/Contact/Contact';
import CPEPage from '@/pages/CVE/CPEPage';
import CVEDetails from '@/pages/CVE/CVEDetails';
import CVELayout from '@/pages/CVE/CVELayout';
import CVEList from '@/pages/CVE/CVEList';
import CWEPage from '@/pages/CVE/CWEPage';
import Darkweb from '@/pages/Dataleak/Darkweb';
import Dataleak from '@/pages/Dataleak/Dataleak';
import HomePage from '@/pages/HomePage/HomePage';
import { Identity } from '@/pages/Identity/Identity';
import Learning from '@/pages/Learning/Learning';
import MalwarePage from '@/pages/Malware/Malware';
import Facebook from '@/pages/Management/apiKey/Facebook';
import Serper from '@/pages/Management/apiKey/Serper';
import Zalo from '@/pages/Management/apiKey/Zalo';
import AsmManagement from '@/pages/Management/AsmManagement';
import IdentityManagement from '@/pages/Management/IdentityManagement';
import TrainingManagement from '@/pages/Management/LearningManagement';
import News from '@/pages/Management/news/News';
import ReportNews from '@/pages/Management/news/ReportNews';
import SessionTelegramManagement from '@/pages/Management/SessionTelegramManagement';
import TwoFAManagement from '@/pages/Management/TwoFAManagement';
import Business from '@/pages/News/Business';
import CyberSecurity from '@/pages/News/CyberSecurity';
import NewsDetail from '@/pages/News/NewsDetail';
import NotFound from '@/pages/NotFound/NotFound';
import Fofa from '@/pages/Osint/Fofa';
import Harvester from '@/pages/Osint/Harvester';
import Mailhunter from '@/pages/Osint/Mailhunter';
import Urlscan from '@/pages/Osint/Urlscan';
import Whois from '@/pages/Osint/Whois';
import Phishing from '@/pages/Phishing/Phishing';
import SecurityAssessment from '@/pages/SecurityAssessment/SecurityAssessment';
import AnalysisKeyword from '@/pages/Social/AnalysisKeyword';
import Posts from '@/pages/Social/Posts';
import ReportTopic from '@/pages/Social/ReportTopic';
import Statistics from '@/pages/Social/Statistics';
import StatisticsOverview from '@/pages/Social/StatisticsOverview';
import Topic from '@/pages/Social/Topic';
import Trending from '@/pages/Social/Trending';

const ListRouter = () => {
  const cyberSecurityNewsRoutes = [
    '/news/cyber-security',
    '/news/cyber-crime',
    '/news/cyber-warfare',
    '/news/apt',
    '/news/data-breach',
    '/news/deep-web',
    '/news/digital-id',
    '/news/hacking',
    '/news/hacktivism',
    '/news/phishing',
  ];

  const businessNewsRoutes = [
    '/news/business',
    '/news/finance',
    '/news/stock',
    '/news/real-estate',
    '/news/business-summary',
  ];

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/score"
          element={
            <AuthGuard>
              <AsmLayout>
                <Score />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/info"
          element={
            <AuthGuard>
              <AsmLayout>
                <Info />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/subdomains"
          element={
            <AuthGuard>
              <AsmLayout>
                <Subdomains />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/vul"
          element={
            <AuthGuard>
              <AsmLayout>
                <Vulnerability />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/spider"
          element={
            <AuthGuard>
              <AsmLayout>
                <Spider />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/cve"
          element={
            <CVELayout>
              <CVEList />
            </CVELayout>
          }
        />
        <Route
          path="/vendors"
          element={
            <CVELayout>
              <CPEPage />
            </CVELayout>
          }
        />
        <Route
          path="/cwe"
          element={
            <CVELayout>
              <CWEPage />
            </CVELayout>
          }
        />
        <Route path="/cve/:id" element={<CVEDetails />} />

        {cyberSecurityNewsRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <NewsLayout>
                <CyberSecurity />
              </NewsLayout>
            }
          />
        ))}

        {businessNewsRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <NewsLayout>
                <Business />
              </NewsLayout>
            }
          />
        ))}
        <Route
          path="/news/:title"
          element={
            <NewsLayout>
              <NewsDetail />
            </NewsLayout>
          }
        />
        <Route
          path="/report"
          element={
            <AuthGuard>
              <AsmLayout>
                <Report />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/asset"
          element={
            <AuthGuard>
              <AsmLayout>
                <Asset />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/phishing"
          element={
            <AuthGuard>
              <PhishingMailLayout>
                <Phishing />
              </PhishingMailLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/identity"
          element={
            <SuperAdminGuard>
              <IdentityLayout>
                <Identity />
              </IdentityLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/osint/urlscan"
          element={
            <AuthGuard>
              <AsmLayout>
                <Urlscan />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/fofa"
          element={
            <AuthGuard>
              <AsmLayout>
                <Fofa />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/theharvester"
          element={
            <AuthGuard>
              <AsmLayout>
                <Harvester />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/whois"
          element={
            <AuthGuard>
              <AsmLayout>
                <Whois />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/osint/mailhunter"
          element={
            <AuthGuard>
              <AsmLayout>
                <Mailhunter />
              </AsmLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social"
          element={
            <AuthGuard>
              <SocialLayout>
                <Topic />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/overview"
          element={
            <AuthGuard>
              <SocialLayout>
                <Statistics />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/posts"
          element={
            <AuthGuard>
              <SocialLayout>
                <Posts />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/trending"
          element={
            <AuthGuard>
              <SocialLayout>
                <Trending />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/statistics-overview"
          element={
            <AuthGuard>
              <SocialLayout>
                <StatisticsOverview />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/analysis-keyword"
          element={
            <AuthGuard>
              <SocialLayout>
                <AnalysisKeyword />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/social/report-topic"
          element={
            <AuthGuard>
              <SocialLayout>
                <ReportTopic />
              </SocialLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/login"
          element={
            <Logged>
              <Login />
            </Logged>
          }
        />
        <Route
          path="/malware"
          element={
            <AuthGuard>
              <MalwarePage />
            </AuthGuard>
          }
        />
        <Route
          path="/management/asm"
          element={
            <AuthGuard>
              <ManagementLayout>
                <AsmManagement />
              </ManagementLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/management/news/list"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <NewsManagementLayout>
                  <News />
                </NewsManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/news/report"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <NewsManagementLayout>
                  <ReportNews />
                </NewsManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/identity"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <IdentityManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/session-telegram"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <SessionTelegramManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/two-factor"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <TwoFAManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/training"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <TrainingManagement />
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />

        <Route
          path="/management/api-key/serper"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <APIKeyManagementLayout>
                  <Serper />
                </APIKeyManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/api-key/zalo"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <APIKeyManagementLayout>
                  <Zalo />
                </APIKeyManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />
        <Route
          path="/management/api-key/facebook"
          element={
            <SuperAdminGuard>
              <ManagementLayout>
                <APIKeyManagementLayout>
                  <Facebook />
                </APIKeyManagementLayout>
              </ManagementLayout>
            </SuperAdminGuard>
          }
        />

        <Route
          path="/learning"
          element={
            <LearningLayout>
              <Learning />
            </LearningLayout>
          }
        />
        <Route
          path="/sourcescan"
          element={
            <AuthGuard>
              <SourceCodeScan />
            </AuthGuard>
          }
        />
        <Route
          path="/sourcescan/results"
          element={
            <AuthGuard>
              <CodescanResults />
            </AuthGuard>
          }
        />
        <Route path="/github/callback" element={<GitHubCallback />} />
        <Route path="/gitlab/callback" element={<GitLabCallback />} />

        <Route
          path="/security-assessment"
          element={
            <AuthGuard>
              <SecurityAssessmentLayout>
                <SecurityAssessment />
              </SecurityAssessmentLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/dataleak"
          element={
            <AuthGuard>
              <DataleakLayout>
                <Dataleak />
              </DataleakLayout>
            </AuthGuard>
          }
        />
        <Route
          path="/darkweb"
          element={
            <AuthGuard>
              <DataleakLayout>
                <Darkweb />
              </DataleakLayout>
            </AuthGuard>
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin" element={<SuperAdmin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default ListRouter;
