import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import RandomAvatar from '@/assets/image/avatar_1.png';

export default function CardHandsome() {
  const { t } = useTranslation();
  const theme = useTheme();
  // Mảng chứa các họ tên ngẫu nhiên
  const randomNames = [
    'Nguyễn Văn Thuận',
    'Nguyễn Thị An',
    'Trần Văn Nam',
    'Trần Thị Dung',
    'Lê Văn Em',
    'Lê Thị Phụng',
    'Phạm Văn Giang',
    'Phạm Thị Hương',
    'Hoàng Văn Hùng',
    'Hoàng Thị Khánh',
    'Vũ Văn Lâm',
    'Vũ Thị Mỹ',
    'Đặng Văn Minh',
    'Đặng Thị Ngọc',
    'Bùi Văn Nghĩa',
    'Bùi Thị Oanh',
    'Đỗ Văn Phúc',
    'Đỗ Thị Quỳnh',
    'Hồ Văn Sơn',
    'Hồ Thị Tâm',
    'Ngô Văn Tú',
    'Ngô Thị Uyên',
    'Mai Văn Vượng',
    'Mai Thị Xinh',
    'Trịnh Văn Yên',
    'Trịnh Thị Yến',
    'Đào Văn Đức',
    'Đào Thị Hương',
    'Lý Văn Đức',
    'Lý Thị Hương',
    'Chử Văn Đức',
    'Chử Thị Hương',
    'Lương Văn Đức',
    'Lương Thị Hương',
    'Lục Văn Đức',
    'Lục Thị Hương',
    'Vương Văn Đức',
    'Vương Thị Hương',
    'Tạ Văn Đức',
    'Tạ Thị Hương',
    'Trương Văn Đức',
    'Trương Thị Hương',
    'Từ Văn Đức',
    'Từ Thị Hương',
    'Phan Văn Đức',
    'Phan Thị Hương',
    'Đinh Văn Đức',
  ];

  // Mảng chứa các thời gian ngẫu nhiên tính theo phút
  const randomTimes = ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '6', '12', '14', '6'];

  // Mảng chứa các đối tượng sau khi được tạo ngẫu nhiên
  const items = [];
  const range = Array.from({ length: 12 }, (i) => i);

  // Vòng lặp để tạo các đối tượng và đưa vào mảng items
  for (let i = 0; i < range.length; i++) {
    const randomName = randomNames[Math.floor(Math.random() * randomNames.length)];
    const randomTime = randomTimes[Math.floor(Math.random() * randomTimes.length)];
    items.push({ title: randomName, timeUpdated: randomTime });
  }
  return (
    <Box sx={{ paddingY: '6em', backgroundColor: 'background.bgFooterUp', fontSize: '16px' }}>
      <Container className="container">
        <Box sx={{ mb: '3em', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h4">
            {t('identity.footer.topCollections')}
            <span style={{ color: 'rgb(131 88 255)' }}> {t('identity.footer.last1hour')}</span>
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {items.map((i, index) => (
            <Grid item key={index} md={3} sm={6} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  py: '1em',
                  px: '1.75em',
                  border: '1px solid transparent',
                  borderRadius: '1em',
                  backgroundColor: 'background.main',
                  transitionProperty: 'box-shadow',
                  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                  transitionDuration: '150ms',
                  boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <img
                    src={RandomAvatar}
                    alt="avatar 1"
                    style={{ height: '2.5em', width: '2.5em', borderRadius: '50%' }}
                    loading="lazy"
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '-1em',
                      top: '50%',
                      height: '24px',
                      width: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.75em',
                      borderRadius: '50%',
                      border: `2px solid ${theme.palette.divider}`,
                      backgroundColor: 'background.dark',
                      transform: 'translate(0, -50%)',
                      color: 'text.secondary',
                    }}
                  >
                    1
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '-1em',
                      top: '60%',
                      height: '24px',
                      width: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.75em',
                      borderRadius: '50%',
                      border: `2px solid ${theme.palette.divider}`,
                      backgroundColor: 'rgb(16 185 129)',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      style={{ height: '0.875em', width: '0.875em', fill: '#ffff' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </Box>
                </Box>
                <Box sx={{ marginLeft: 1.5 }}>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: '600',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'rgb(131 88 255)',
                      },
                    }}
                  >
                    {i.title}
                  </Typography>
                  <Typography sx={{ fontSize: '0.875em', color: 'rgb(161 162 179)' }}>
                    {i.timeUpdated} {t('identity.footer.minutesAgo')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
