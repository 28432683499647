import React, { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  useTheme,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import { useCommonInfo } from '@/contexts/Common';
import useDataleak from '@/Hooks/api/useDataleak';
import { DataleakTypes, KeywordTypes } from '@/types/dataleak';
import { PAGE_SIZE } from '@/utils/constants';

import Download from '../../components/Dataleak/Download';
import Keyword from '../../components/Dataleak/Keyword';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

const Dataleak = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleListKeywords } = useDataleak();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoadingGif } = useCommonInfo();

  const [listKeywords, setListKeywords] = useState<KeywordTypes[]>([]);
  const [listDataleak, setListDataleak] = useState<DataleakTypes[]>([]);
  const [totalDataleak, setTotalDataleak] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/dataleak');
        break;
      case 1:
        navigate('/darkweb');
        break;
      default:
        navigate('/dataleak');
        break;
    }
  };

  const path = location.pathname;
  useEffect(() => {
    switch (path) {
      case '/dataleak':
        setValue(0);
        break;
      case '/darkweb':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [path]);

  const handleGetListKeywords = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const res = await handleListKeywords(params);
    setListDataleak(res.list_data);
    setListKeywords(res.list_key_word);
    setTotalDataleak(res.total);
  };

  useEffect(() => {
    handleGetListKeywords();
  }, [searchParams.get('page')]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Keyword
          listKeywords={listKeywords}
          setListKeywords={setListKeywords}
          handleGetListKeywords={handleGetListKeywords}
        />

        <Box sx={{ mt: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <StyledTab label="Dataleak" />
              <StyledTab label="Darkweb" />
            </StyledTabs>
            <Download listData={listDataleak} />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                padding: 2,
                borderRadius: '3px',
                mt: 3,
                boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
              }}
            >
              <TableContainer
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '5px',
                }}
              >
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>URL</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Password</StyledTableCell>
                      <StyledTableCell>Raw</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!_.isEmpty(listDataleak) || !isLoadingGif ? (
                      <>
                        {listDataleak?.map((row: DataleakTypes) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              verticalAlign: 'baseline',
                            }}
                            hover
                          >
                            <StyledTableCell>{row.email?.match(/(?<=@)[^@]+/)?.[0] || '-'}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>{row.password}</StyledTableCell>
                            <StyledTableCell sx={{ whiteSpace: 'wrap' }}>{row.raw_data}</StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography
                            variant="body2"
                            sx={{ paddingY: 2, textAlign: 'center', color: 'text.secondary' }}
                          >
                            {t('notify.noResult')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Pagination
                  count={Math.ceil(totalDataleak / PAGE_SIZE)}
                  color="primary"
                  onChange={(event, valuePage) => {
                    handleChangePage(valuePage);
                  }}
                  page={Number(searchParams.get('page') || 1)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Dataleak;
