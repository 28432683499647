import { useEffect, useRef } from 'react';

import { Box, CardMedia, Dialog, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Transition } from '@/components/common/Transition';
import { CourseTypes } from '@/types/management';

interface DetailCourseProps {
  openDialogDetail: boolean;
  onDialogDetailChange: (newValue: boolean) => void;
  detail: CourseTypes;
}

const DetailCourse: React.FC<DetailCourseProps> = ({ openDialogDetail, onDialogDetailChange, detail }) => {
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDialogDetail && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogDetail]);

  return (
    <>
      <Dialog
        open={openDialogDetail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogDetailChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.training.detailCourse')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              Id:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ml: 2,
              }}
            >
              {detail.id}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${t('management.training.courseName')}:`}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ml: 2,
              }}
            >
              {detail.title}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${t('management.training.typeCourse')}:`}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ml: 2,
              }}
            >
              {detail.type_course}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${t('management.training.thumbnail')}:`}
            </Typography>
            <Box sx={{ ml: 2 }}>
              {detail?.detail_link?.link_thumbnail ? (
                <>
                  <Link
                    href={`${detail.url_storage}/${detail.bucket_name}/${detail.detail_link.link_thumbnail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontSize: '1.4rem' }}
                  >
                    {detail.detail_link.link_thumbnail}
                  </Link>

                  <Box sx={{ marginTop: 2 }}>
                    <CardMedia
                      component="img"
                      image={`${detail.url_storage}/${detail.bucket_name}/${detail.detail_link.link_thumbnail}`}
                      alt=""
                      sx={{ height: 100, width: 'auto', borderRadius: 1 }}
                    />
                  </Box>
                </>
              ) : (
                '-'
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              Video:
            </Typography>
            <Box sx={{ ml: 2 }}>
              {detail?.detail_link?.link_video ? (
                <Link
                  href={`${detail.url_storage}/${detail.bucket_name}/${detail.detail_link.link_video}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontSize: '1.4rem' }}
                >
                  {detail.detail_link.link_video}
                </Link>
              ) : (
                '-'
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${t('management.training.document')}:`}
            </Typography>
            <Box sx={{ ml: 2 }}>
              {detail?.detail_link?.link_document ? (
                <Link
                  href={`${detail.url_storage}/${detail.bucket_name}/${detail.detail_link.link_document}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontSize: '1.4rem' }}
                >
                  {detail.detail_link.link_document}
                </Link>
              ) : (
                '-'
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: 'action.hover',
              padding: 2,
              borderRadius: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${t('management.training.description')}:`}
            </Typography>
            <Box sx={{ ml: 2 }}>
              {detail.description ? (
                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: detail.description }} />
              ) : (
                '-'
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DetailCourse;
