import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Button, Box } from '@mui/material';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useDataleak from '@/Hooks/api/useDataleak';
import useLoadingGif from '@/Hooks/common/useLoadingGif';
import { DataleakAllTypes } from '@/types/dataleak';

interface LayoutProps {
  listData: any[];
}

const Download: React.FC<LayoutProps> = ({ listData }) => {
  const { t } = useTranslation();

  const { handleListAllKeywords } = useDataleak();

  const { setLoadingGif } = useLoadingGif();

  const [listDataAll, setListDataAll] = useState<DataleakAllTypes[]>([]);
  const [isDownload, setIsDownload] = useState(false);

  const handleGetListAll = async () => {
    const res = await handleListAllKeywords({});
    const { data, status } = res || {};
    setListDataAll(data.list_data_download);
    if (status === httpStatus.StatusOK) {
      setIsDownload(true);
      setLoadingGif(false);
    }
  };

  const handleDownloadExcel = () => {
    const workbookDataleak = XLSX.utils.book_new();
    const workbookDarkweb = XLSX.utils.book_new(); // Tạo workbook cho breached_data

    const splitData = (data: string, chunkSize: number) => {
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      return chunks;
    };

    if (!_.isEmpty(listDataAll)) {
      listDataAll.forEach((item) => {
        const sheetDataList = item.list_data.map((res) => ({
          URL: res.email?.match(/(?<=@)[^@]+/)?.[0] || '-',
          Email: res.email,
          Password: res.password,
          'Raw Data': res.raw_data,
        }));

        const worksheetList = XLSX.utils.json_to_sheet(sheetDataList);
        XLSX.utils.book_append_sheet(workbookDataleak, worksheetList, item.key_word);
      });

      listDataAll.forEach((item) => {
        const sheetDataBreached = item.breached_data.flatMap((res) => {
          const postTextChunks = splitData(res.post_text || '', 32767);
          return postTextChunks.map((chunk, index) => ({
            Thread: index === 0 ? res.thread : '',
            Username: index === 0 ? res.username : '',
            'Post text': chunk,
            URL: index === 0 ? res.url : '',
          }));
        });

        const worksheetBreached = XLSX.utils.json_to_sheet(sheetDataBreached);
        XLSX.utils.book_append_sheet(workbookDarkweb, worksheetBreached, item.key_word);
      });

      const excelBufferList = XLSX.write(workbookDataleak, { bookType: 'xlsx', type: 'array' });
      const blobList = new Blob([excelBufferList], { type: 'application/octet-stream' });
      saveAs(blobList, 'dataleak.xlsx');

      const excelBufferBreached = XLSX.write(workbookDarkweb, { bookType: 'xlsx', type: 'array' });
      const blobBreached = new Blob([excelBufferBreached], { type: 'application/octet-stream' });
      saveAs(blobBreached, 'darkweb.xlsx');
    }
  };

  useEffect(() => {
    isDownload && handleDownloadExcel();
  }, [isDownload]);

  return (
    <>
      {!_.isEmpty(listData) && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ ml: 2, textTransform: 'capitalize' }}
            onClick={() => {
              handleGetListAll();
            }}
          >
            <DownloadIcon sx={{ mr: 1 }} />
            {t('action.download')}
          </Button>
        </Box>
      )}
    </>
  );
};
export default Download;
