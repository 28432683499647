import { ReactElement, useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import FilterNews from '@/components/Management/news/FilterNews';
import TableNews from '@/components/Management/news/TableNews';
import useManagenent from '@/Hooks/api/useManagenent';
import { NewsState, UpdateNewsStateFunction } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

const News = (): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleListNews } = useManagenent();

  const [listTopic, setListTopic] = useState([]);
  const [page, setPage] = useState(1);
  const [totalTopic, setTotalTopic] = useState(0);
  const [openDialogFilter, setOpenDialogFilter] = useState(false);

  const initalValue = {
    type: '',
    field: '',
    keyword: '',
    analyticStatus: 0,
    selectedStartDate: null,
    selectedEndDate: null,
  };

  const [state, setState] = useState<NewsState>(initalValue);

  const [stateFilter, setStateFilter] = useState<NewsState>(state);

  const updateState: UpdateNewsStateFunction = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateStateFilter: UpdateNewsStateFunction = (key, value) => {
    setStateFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleGetListNews = async () => {
    const params = {
      keyword: state.keyword,
      page,
      page_size: PAGE_SIZE,
      start_time: state.selectedStartDate,
      end_time: state.selectedEndDate,
      type_news: state.type,
      field: state.field,
      is_error_analytic_news: state.analyticStatus,
    };

    const res = await handleListNews(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_news, total } = res;
    setListTopic(list_news);
    setTotalTopic(total);
  };

  useEffect(() => {
    handleGetListNews();
  }, [page, state]);

  const handleClearFilter = () => {
    setState(initalValue);
    setStateFilter(initalValue);
    setPage(1);
  };

  const isValidValue = (value: any) => {
    return value !== '' && value !== null && value !== undefined && value !== 0;
  };

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {isValidValue(state.type) ||
          isValidValue(state.field) ||
          isValidValue(state.keyword) ||
          isValidValue(state.selectedEndDate) ||
          isValidValue(state.selectedStartDate) ||
          isValidValue(state.analyticStatus) ? (
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: 'flex', border: `1px solid ${theme.palette.divider}`, padding: 1, borderRadius: 1 }}
            >
              <ClearIcon
                onClick={() => {
                  handleClearFilter();
                }}
                sx={{
                  fontSize: '2rem',
                  alignSelf: 'center',
                  '&:hover': {
                    color: 'error.main',
                    cursor: 'pointer',
                  },
                }}
              />
              {isValidValue(state.type) && (
                <Stack spacing={1} sx={{ backgroundColor: 'action.hover', padding: 1, borderRadius: 1 }}>
                  <Typography variant="caption" sx={{ alignSelf: 'center' }}>
                    {t('management.news.typeNews')}
                  </Typography>
                  <Chip
                    label={state.type}
                    onDelete={() => {
                      updateState('type', '');
                      updateStateFilter('type', '');
                      setPage(1);
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      '& .MuiChip-label': {
                        fontSize: '1.2rem',
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
              {isValidValue(state.field) && (
                <Stack spacing={1} sx={{ backgroundColor: 'action.hover', padding: 1, borderRadius: 1 }}>
                  <Typography variant="caption" sx={{ alignSelf: 'center' }}>
                    {t('management.news.field')}
                  </Typography>
                  <Chip
                    label={state.field}
                    onDelete={() => {
                      updateState('field', '');
                      updateStateFilter('field', '');
                      setPage(1);
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      '& .MuiChip-label': {
                        fontSize: '1.2rem',
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
              {isValidValue(state.keyword) && (
                <Stack spacing={1} sx={{ backgroundColor: 'action.hover', padding: 1, borderRadius: 1 }}>
                  <Typography variant="caption" sx={{ alignSelf: 'center' }}>
                    {t('management.news.keyword')}
                  </Typography>
                  <Chip
                    label={state.keyword}
                    onDelete={() => {
                      updateState('keyword', '');
                      updateStateFilter('keyword', '');
                      setPage(1);
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      '& .MuiChip-label': {
                        fontSize: '1.2rem',
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
              {isValidValue(state.selectedStartDate) && (
                <Stack spacing={1} sx={{ backgroundColor: 'action.hover', padding: 1, borderRadius: 1 }}>
                  <Typography variant="caption" sx={{ alignSelf: 'center' }}>
                    {t('management.news.startDate')}
                  </Typography>
                  <Chip
                    label={state.selectedStartDate}
                    onDelete={() => {
                      updateState('selectedStartDate', null);
                      updateStateFilter('selectedStartDate', null);
                      setPage(1);
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      '& .MuiChip-label': {
                        fontSize: '1.2rem',
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
              {isValidValue(state.selectedEndDate) && (
                <Stack spacing={1} sx={{ backgroundColor: 'action.hover', padding: 1, borderRadius: 1 }}>
                  <Typography variant="caption" sx={{ alignSelf: 'center' }}>
                    {t('management.news.endDate')}
                  </Typography>
                  <Chip
                    label={state.selectedEndDate}
                    onDelete={() => {
                      updateState('selectedEndDate', null);
                      updateStateFilter('selectedEndDate', null);
                      setPage(1);
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      '& .MuiChip-label': {
                        fontSize: '1.2rem',
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
              {isValidValue(state.analyticStatus) && (
                <Stack spacing={1} sx={{ backgroundColor: 'action.hover', padding: 1, borderRadius: 1 }}>
                  <Typography variant="caption" sx={{ alignSelf: 'center' }}>
                    {t('management.news.analytic')}
                  </Typography>
                  <Chip
                    label={state.analyticStatus}
                    onDelete={() => {
                      updateState('analyticStatus', 0);
                      updateStateFilter('analyticStatus', 0);
                      setPage(1);
                    }}
                    color="primary"
                    variant="outlined"
                    sx={{
                      '& .MuiChip-label': {
                        fontSize: '1.2rem',
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              )}
            </Stack>
          ) : (
            <Box />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
            onClick={() => {
              setOpenDialogFilter(true);
            }}
          >
            <FilterListIcon sx={{ fontSize: '2rem', mr: 1 }} />
            <Typography variant="body2" sx={{ alignContent: 'center' }}>
              {t('management.news.filter')}
            </Typography>
          </Box>
        </Box>
        <TableNews
          listNews={listTopic}
          onPageChange={setPage}
          page={page}
          handleGetListNews={handleGetListNews}
          totalNews={totalTopic}
        />
      </Box>
      <FilterNews
        openDialogFilter={openDialogFilter}
        onDialogFilterChange={setOpenDialogFilter}
        setState={setState}
        stateFilter={stateFilter}
        updateStateFilter={updateStateFilter}
        onPageChange={setPage}
      />
    </>
  );
};
export default News;
