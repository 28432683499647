import { useEffect, useMemo, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, CircularProgress, InputBase, Typography, debounce, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useManagenent from '@/Hooks/api/useManagenent';
import { PAGE_SIZE } from '@/utils/constants';

import ListAuthentication2FA from '../../components/Management/2FA/ListAuthentication2FA';

const TwoFAManagement = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleGetList2FA } = useManagenent();

  const [listUsers, setListUsers] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const fetchFilterAsset = useMemo(
    () =>
      debounce(async (search) => {
        if (search !== '' && search.length > 2) {
          handleGetList(search);
        } else {
          setLoadingFilter(false);
        }
      }, 1500),
    [],
  );

  const handleInputChange = (event: any) => {
    setPage(1);
    setSearchValue(event.target.value);
    fetchFilterAsset(event.target.value);
    if (event.target.value.length > 2) {
      setLoadingFilter(true);
    }
    if (event.target.value === '') {
      handleGetList('', 1);
      setLoadingFilter(false);
    }
  };

  const handleGetList = async (searchKey?: string, pageValue?: number) => {
    const params = { keyword: searchKey ?? searchValue, page: pageValue ?? page, page_size: PAGE_SIZE };
    const res = await handleGetList2FA(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_2FA_enable_ouput, total } = res;
    setListUsers(list_2FA_enable_ouput);
    setTotalRow(total);
    setLoadingFilter(false);
  };

  useEffect(() => {
    handleGetList();
  }, [page]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.2fa.title2fa')}
      </Typography>

      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
            }}
          >
            <InputBase
              sx={{
                width: '100%',
                ml: 1,
                flex: 1,
                '.MuiInputBase-input': {
                  padding: '10px 10px',
                },
              }}
              placeholder={t('placeholder.typeSearch')}
              inputProps={{
                'aria-label': 'filter company',
              }}
              endAdornment={
                <>
                  {loadingFilter && <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />}
                  {searchValue && !loadingFilter && (
                    <ClearIcon
                      onClick={() => {
                        setSearchValue('');
                        if (page === 1) {
                          handleGetList('', 1);
                        } else {
                          setPage(1);
                        }
                      }}
                      sx={{
                        mr: 3,
                        color: 'text.secondary',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  )}
                </>
              }
              value={searchValue}
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box>
          <ListAuthentication2FA
            listUsers={listUsers}
            totalRow={totalRow}
            page={page}
            onPageChange={setPage}
            onSearchValueChange={setSearchValue}
            handleGetList={handleGetList}
          />
        </Box>
      </Box>
    </>
  );
};
export default TwoFAManagement;
