import { ReactElement, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AddKey from '@/components/Management/apiKey/AddKey';
import TableKey from '@/components/Management/apiKey/TableKey';
import useManagenent from '@/Hooks/api/useManagenent';
import { APIKeyType } from '@/types/management';

const Zalo = (): ReactElement => {
  const { t } = useTranslation();
  const { handleGetAPI } = useManagenent();

  const [listAPI, setListAPI] = useState<APIKeyType[]>([]);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const handleGetListKey = async () => {
    const params = {};
    const res = await handleGetAPI(params);
    const { details } = res;
    setListAPI(details);
  };

  useEffect(() => {
    handleGetListKey();
  }, []);

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              width: '180px',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('management.apiKey.addKey')}
          </Button>
        </Box>
        <TableKey listAPI={listAPI} handleGetList={handleGetListKey} />
      </Box>
      <AddKey
        openDialogCreate={openDialogCreate}
        onDialogCreateChange={setOpenDialogCreate}
        handleGetListKey={handleGetListKey}
      />
    </>
  );
};
export default Zalo;
