import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { RedditPostsTypes } from '@/types/Social';

import PostsRedditDetail from './PostsRedditDetail';
import TitlePostsReddit from './TitlePostsReddit';

const initialPost: RedditPostsTypes = {
  url: '',
  title: '',
  content: '',
  author: '',
  time: '',
  description: '',
  sentiment: 'neutral',
};

interface PostsRedditProps {
  item: RedditPostsTypes;
}

const PostsReddit: React.FC<PostsRedditProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<RedditPostsTypes>(initialPost);

  return (
    <>
      <Box>
        <TitlePostsReddit item={item} />
        <Box sx={{ cursor: 'pointer' }}>
          <Box sx={{ mt: 2 }}>
            <Typography
              onClick={() => {
                setOpenDialogDetail(true);
                setDetailSelected(item);
              }}
              sx={{
                letterSpacing: '0.01em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis',
                fontWeight: 600,
                color: 'text.secondary',
              }}
            >
              <HighlightTextSocial text={item.title} keyword={topicSocial?.key_word_main} />
            </Typography>
            <Typography
              variant="body2"
              onClick={() => {
                setOpenDialogDetail(true);
                setDetailSelected(item);
              }}
              sx={{
                mt: 2,
                letterSpacing: '0.01em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 4,
                textOverflow: 'ellipsis',
                color: 'text.secondary',
              }}
            >
              <HighlightTextSocial text={item.content} keyword={topicSocial?.key_word_main} />
            </Typography>
          </Box>

          <Typography
            variant="caption"
            onClick={() => {
              setOpenDialogDetail(true);
              setDetailSelected(item);
            }}
            sx={{
              mt: 0.5,
              width: 'fit-content',
              cursor: 'pointer',
              color: 'text.secondary',
              ':hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
              fontStyle: 'italic',
            }}
          >
            {t('action.showMore')}
          </Typography>
        </Box>
      </Box>

      <PostsRedditDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsReddit;
