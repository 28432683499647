import { useCallback, useEffect, useRef, useState } from 'react';

import { Typography, Dialog, DialogTitle, DialogContent, Box, Button } from '@mui/material';
import * as _ from 'lodash';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Transition } from '@/components/common/Transition';
import { useCommonInfo } from '@/contexts/Common';
import useManagenent from '@/Hooks/api/useManagenent';

interface AddSessionProps {
  openDialogCreate: boolean;
  onDialogCreateChange: (newValue: boolean) => void;
  handleGetSessions: (arg: number) => void;
}

const AddSession: React.FC<AddSessionProps> = ({ openDialogCreate, onDialogCreateChange, handleGetSessions }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { handleUploadSession } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();
  const { apiError } = useCommonInfo();

  const [files, setFiles] = useState<FileWithPath[]>([]);

  useEffect(() => {
    if (openDialogCreate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogCreate]);

  // Sử dụng react-dropzone để xử lý session
  const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } = useDropzone({
    accept: { 'application/session': ['.session'] },
    multiple: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFiles((prevList) => [...prevList, ...acceptedFiles]);
      }
    },
  });

  const handleClose = useCallback(() => {
    setFiles([]);
    onDialogCreateChange(false);
    setSearchParams({ page: '1' });
  }, []);

  const uploadSessionSuccess = () => {
    handleClose();
    if (searchParams.get('page') === '1') {
      handleGetSessions(1);
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('session', file); // Tất cả file có key là "session"
    });
    await handleUploadSession(formData, uploadSessionSuccess);
  };

  useEffect(() => {
    apiError && handleClose();
  }, [apiError, handleClose]);

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogCreateChange(false);
          setFiles([]);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('action.addSession')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <Box sx={{ backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Session
            </Typography>
            <Box
              {...getVideoRootProps()}
              sx={{
                border: '2px dashed grey',
                borderRadius: 2,
                padding: 2,
                textAlign: 'center',
                marginTop: 2,
                cursor: 'pointer',
                backgroundColor: 'background.paper',
              }}
            >
              <input {...getVideoInputProps()} />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('placeholder.dropzone')}
              </Typography>
              {!_.isEmpty(files) && (
                <>
                  {files.map((file: any) => (
                    <Typography key={file.name} variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                      {file.name}
                    </Typography>
                  ))}
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={_.isEmpty(files)} sx={{ mt: 3 }}>
              {t('action.upload')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddSession;
