import { US_FLAG, VN_FLAG } from './imgBase64';

// APP TEXT
export const APP_TITLE = 'ESS';
export const APP_DESCRIPTION =
  'Esecure Solution Limited Company, Multi-sector technology solutions and services provider';
export const FOOTER_TEXT = `${new Date().getFullYear()} ETC Technology Systems JSC`;

// FONT
export const FONT_PRIMARY = '"Roboto", sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_GH_PRIVATE = 'GitHub Private';
export const PAGE_TITLE_GH_PUBLIC = 'GitHub Public';
export const PAGE_TITLE_CODE = 'Code Editor';
export const PAGE_TITLE_SETTINGS = 'Settings';
export const PAGE_TITLE_PREFERENCES = 'Preferences';
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 240;
// APP THEME
export const DARK_MODE_THEME = 'dark';
export const LIGHT_MODE_THEME = 'light';
// PAGINATION
export const PAGE_SIZE = 10;
export const PAGE_INDEX_DEFAULT = 1;
// AMP DETAIL
export const NUMBER_SUBDOMAIN_PREVIEW = 1;
export const NUMBER_SUBDOMAIN_EMPTY = 0;
// REGEX
export const REGEX_DOMAIN = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const REGEX_IP = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

export const DANGEROUS_PORT = [
  7, 21, 22, 23, 25, 465, 587, 43, 49, 53, 69, 79, 88, 110, 995, 111, 113, 123, 135, 137, 138, 139, 139, 445, 143, 993,
  161, 162, 10161, 10162, 194, 6667, 6660, 7000, 264, 389, 636, 3268, 3269, 500, 502, 512, 513, 514, 515, 548, 554,
  8554, 623, 631, 873, 1026, 1080, 1098, 1099, 1050, 1433, 1521, 1522, 1529, 1723, 1883, 2049, 2301, 2381, 2375, 2376,
  3128, 3260, 3299, 3306, 3389, 3632, 3690, 3702, 4369, 4786, 5000, 5353, 5432, 5433, 5439, 5555, 5601, 5671, 5672,
  5800, 5801, 5900, 5901, 5984, 6984, 5985, 5986, 5985, 5986, 6000, 6379, 8009, 8086, 8089, 8333, 18333, 38333, 18444,
  9000, 9001, 9042, 9160, 9100, 9200, 10000, 11211, 15672, 24007, 24008, 24009, 49152, 27017, 27018, 44134, 44818,
  47808, 50030, 50060, 50070, 50075, 50090,
];

export const LIST_LOCALES = [
  { name: 'vi', src: VN_FLAG },
  { name: 'en', src: US_FLAG },
];

// News
export const TYPE_FINANCE = 'finance';
export const TYPE_CYBER_SECURITY = 'cyber_security';

// Sicial
export const TYPE_SEARCH = 'exact';
export const SEARCH_ALL = 'all';
export const SEARCH_FACEBOOK = 'facebook';
export const SEARCH_FORUM = 'forum';
export const SEARCH_TIKTOK = 'tiktok';
export const SEARCH_NEWS = 'newspaper';
export const SEARCH_YOUTUBE = 'youtube';
export const SEARCH_TWITTER = 'twitter';
export const SEARCH_REDDIT = 'reddit';
export const SEARCH_THREADS = 'thread';
export const MIN_KEYWORD = 10;
export const MAX_KEYWORD = 100;
export const MIDDLE_KEYWORD = 30;
