import { useState } from 'react';

import { Link, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import useAsm from '@/Hooks/api/useAsm';
import { CVEType } from '@/types/Assets';
import { PAGE_SIZE } from '@/utils/constants';

const CvssLabel = (bgColor: string, value: number, label: string) => {
  return (
    <Chip
      sx={{ backgroundColor: bgColor, height: '22px', color: '#fff', fontWeight: 600, fontSize: '1rem' }}
      label={`${value} ${label}`}
    />
  );
};

const DisplayCvssV3 = (cvssScore: { cvss: number }) => {
  const { t } = useTranslation();
  const value: number = Object.values(cvssScore)[0];

  if (value >= 0.1 && value <= 3.9) {
    return CvssLabel('#00c0ef', value, t('cve.cvss.low'));
  }
  if (value >= 4.0 && value <= 6.9) {
    return CvssLabel('#f39c12', value, t('cve.cvss.medium'));
  }
  if (value >= 7.0 && value <= 8.9) {
    return CvssLabel('#dd4b39', value, t('cve.cvss.high'));
  }
  if (value >= 9.0 && value <= 10.0) {
    return CvssLabel('#972b1e', value, t('cve.cvss.critical'));
  }
  return (
    <Chip
      sx={{
        backgroundColor: '#d2d6de',
        height: '22px',
        color: '#161C24',
        fontWeight: 600,
        fontSize: '1rem',
      }}
      label="N/A"
    />
  );
};

interface TableCVEProps {
  listCVEs: CVEType[];
  totalCVE: number;
  vendor: string;
}

interface StateTypes {
  CVEs: CVEType[];
  hasMore: boolean;
  currentPage: number;
}

const TableCVE = (props: TableCVEProps) => {
  const { listCVEs, totalCVE, vendor } = props;
  const { t } = useTranslation();
  const { handleGetListCVE } = useAsm();

  const [state, setState] = useState<StateTypes>({
    CVEs: listCVEs,
    hasMore: true,
    currentPage: 2,
  });

  const updateState = (newState: Partial<StateTypes>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const fetchMoreData = async () => {
    const params = {
      page: state.currentPage,
      page_size: PAGE_SIZE,
      vendor,
    };
    const res = await handleGetListCVE(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_asset } = res;
    if (list_asset && list_asset.length > 0) {
      updateState({
        CVEs: [...state.CVEs, ...list_asset],
        currentPage: state.currentPage + 1,
      });
    } else {
      updateState({
        hasMore: false,
      });
    }
  };

  return (
    <TableContainer id={`scrollableTable-${vendor}`} sx={{ maxHeight: '340px', overflow: 'auto' }}>
      <InfiniteScroll
        dataLength={state.CVEs.length}
        next={fetchMoreData}
        hasMore={state.hasMore}
        loader=""
        endMessage=""
        style={{ overflow: 'hidden' }}
        scrollableTarget={`scrollableTable-${vendor}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="35%" sx={{ whiteSpace: 'nowrap', padding: '10px', fontSize: '1.2rem' }}>
                {`${t('table.asset.vulnerability')} (${totalCVE})`}
              </TableCell>
              <TableCell width="35%" sx={{ fontSize: '1.2rem', padding: '10px' }}>
                CVSS v3.1
              </TableCell>
              <TableCell width="30%" sx={{ whiteSpace: 'nowrap', padding: '10px', fontSize: '1.2rem' }}>
                {t('table.asset.update')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.CVEs.map((cve: CVEType) => (
              <TableRow key={cve.id} hover>
                <TableCell sx={{ padding: '10px', fontSize: '1.2rem' }}>
                  <Link
                    underline="none"
                    sx={{
                      padding: 0.5,
                      color: 'primary.main',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.dark',
                      },
                    }}
                    href={`/cve/${cve.id}`}
                    rel="noopener noreferrer"
                  >
                    {cve.id}
                  </Link>
                </TableCell>
                <TableCell sx={{ padding: '10px' }}>
                  <DisplayCvssV3 cvss={cve.cvssv3} />
                </TableCell>
                <TableCell sx={{ padding: '10px', fontSize: '1.2rem' }}>{cve.updated}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};
export default TableCVE;
