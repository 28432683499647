import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomTabPanel from '@/components/Tab/CustomTabPanel';
import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';
import usePhishing from '@/Hooks/api/usePhishing';
import { IMalware } from '@/interfaces/phishing';

import Campaign from './Campaign/Campaign';
import Malware from './Malware/Malware';

const Phishing = () => {
  const { t } = useTranslation();

  const { handleListCampaign, handleListMalware } = usePhishing();

  const [value, setValue] = useState(0);
  const [listCampaign, setListCampaign] = useState<any>([]);
  const [countCampaign, setCountCampaign] = useState(1);
  const [listMalware, setListMalware] = useState<IMalware[]>([]);
  const [countMalware, setCountMalware] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleGetListCampaign = async (pageValue: number) => {
    const dataRes = await handleListCampaign({ page: pageValue });
    const { count, results } = dataRes;
    setListCampaign(results);
    setCountCampaign(count);
  };

  const handleGetListMalware = async (pageValue: number) => {
    const dataRes = await handleListMalware({ page: pageValue });
    const { count, results } = dataRes;
    setListMalware(results);
    setCountMalware(count);
  };

  useEffect(() => {
    handleGetListCampaign(1);
    handleGetListMalware(1);
  }, []);

  const filteredMalware = listMalware?.map((item) => {
    return { id: item?.id, name: item?.name };
  });

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
          {t('asm.phishing.title')}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <StyledTab label={t('asm.phishing.campaign')} />
                <StyledTab label={t('asm.phishing.malware')} />
              </StyledTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Campaign
                listCampaign={listCampaign}
                listMalware={filteredMalware}
                countCampaign={countCampaign}
                handleGetListCampaign={handleGetListCampaign}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Malware
                listMalware={listMalware}
                countMalware={countMalware}
                handleGetListMalware={handleGetListMalware}
              />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Phishing;
