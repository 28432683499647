import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { ResultReportTypes } from '@/types/Social';

interface ResultReportProps {
  data: ResultReportTypes;
}

const ResultReport: React.FC<ResultReportProps> = ({ data }) => {
  const { t } = useTranslation();
  if (!data) return <Box>No data available</Box>;

  const handleDownload = () => {
    // Chuyển đổi base64 sang Blob
    const byteCharacters = atob(data.pdf);
    const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = String(data.social_type);
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      {data.pdf && (
        <Button variant="outlined" color="primary" onClick={handleDownload} sx={{ mb: 2 }}>
          <DownloadIcon sx={{ mr: 1 }} />
          {t('smcc.download')}
        </Button>
      )}
      <ReactMarkdown>{data.markdown}</ReactMarkdown>
    </Box>
  );
};
export default ResultReport;
