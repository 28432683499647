import { useEffect, useState } from 'react';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Card, CardContent, CardMedia, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import useManagenent from '@/Hooks/api/useManagenent';
import { CourseTypes, GroupedCourseTypes } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

import Category from './Category';
import DetailCourse from './DetailCourse';

const Learning = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleGetListCourses, handleViewCourse } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listCourses, setListCourses] = useState<GroupedCourseTypes[]>([]);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [courseSelected, setCourseSelected] = useState<CourseTypes>();
  const [groupedSelected, setGroupedSelected] = useState<GroupedCourseTypes>();
  const [videoDurations, setVideoDurations] = useState<{ [key: string]: string }>({});
  const [typeSelected, setTypeSelected] = useState<string>('');

  const handleGetCourses = async () => {
    const params = {
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const dataRes = await handleGetListCourses(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_course } = dataRes;
    const groupByTypeCourse = (courses: CourseTypes[]): GroupedCourseTypes[] => {
      return Object.values(
        courses.reduce((acc: Record<string, GroupedCourseTypes>, course) => {
          const type = course.type_course;
          if (!acc[type]) {
            acc[type] = { type, list_course: [] };
          }
          acc[type].list_course.push(course);
          return acc;
        }, {}),
      );
    };

    setListCourses(groupByTypeCourse(list_course));

    // Lấy thời lượng cho tất cả các video trong danh sách khóa học
    list_course.forEach((course: CourseTypes) => {
      const videoElement = document.createElement('video');
      videoElement.src = `${course.url_storage}/${course.bucket_name}/${course.detail_link.link_video}`;
      videoElement.addEventListener('loadedmetadata', () => {
        const { duration } = videoElement;
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);

        const formattedDuration = `${String(hours)}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
          2,
          '0',
        )}`;

        setVideoDurations((prevDurations) => ({
          ...prevDurations,
          [course.id]: formattedDuration,
        }));
      });
    });
  };

  const handleCountViews = async (id: string) => {
    await handleViewCourse({ id });
  };

  useEffect(() => {
    handleGetCourses();
  }, [searchParams]);

  return (
    <>
      {!openCategory ? (
        <Box sx={{ padding: 2 }}>
          {listCourses.map((orderCourse: GroupedCourseTypes) => (
            <Box key={orderCourse.type} sx={{ mt: 2, mb: 8 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    display: 'flex',
                    borderTop: `2px solid ${theme.palette.text.disabled}`,
                    mb: 1,
                    width: { sx: '100%', sm: '30%', md: '15%' },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.6rem',
                      fontWeight: 800,
                      textTransform: 'uppercase',
                      color: 'common.white',
                      width: 'fit-content',
                      backgroundColor: 'text.disabled',
                      borderRadius: '0px 0px 3px 3px',
                      padding: '4px 10px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {orderCourse.type}
                  </Typography>
                </Box>
                {orderCourse.list_course.length > 5 && (
                  <Box
                    onClick={() => {
                      setGroupedSelected(orderCourse);
                      setOpenCategory(true);
                      setTypeSelected(orderCourse.type);
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'warning.main',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'warning.dark',
                      },
                    }}
                  >
                    <Typography variant="body2">{t('management.training.seeAll')}</Typography>
                    <ArrowForwardIosIcon sx={{ fontSize: '1.4rem', ml: 1 }} />
                  </Box>
                )}
              </Box>

              <Grid container spacing={4} sx={{ mt: '-15px !important' }}>
                {orderCourse.list_course.slice(0, 5).map((course: CourseTypes) => (
                  <Grid item xs={6} sm={4} md={4} lg={2.4} key={course.id}>
                    <Card
                      sx={{
                        borderRadius: 2,
                        transition: 'all 0.2s',
                        backgroundColor: 'background.main',
                        cursor: 'pointer',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 4px 8px #0000001a',
                        },
                        minHeight: '300px',
                        maxHeight: '350px',
                      }}
                      onClick={() => {
                        setCourseSelected(course);
                        setOpenDialogDetail(true);
                        handleCountViews(course.id);
                        setTypeSelected(course.type_course);
                      }}
                    >
                      <Box sx={{ position: 'relative' }}>
                        <CardMedia
                          component="img"
                          alt="green iguana"
                          image={`${course.url_storage}/${course.bucket_name}/${course.detail_link.link_thumbnail}`}
                          sx={{ height: '200px' }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            left: 0,
                            bottom: '45px',
                            padding: { xs: 2, sm: 3 },
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <PlayCircleFilledIcon
                            sx={{
                              fontSize: '7rem',
                              cursor: 'pointer',
                              color: 'rgba(0,0,0,0.5)',
                              '&:hover': {
                                color: 'error.main',
                              },
                            }}
                          />
                        </Box>
                      </Box>

                      <CardContent>
                        <Typography
                          gutterBottom
                          component="div"
                          sx={{
                            fontWeight: 500,
                            cursor: 'pointer',
                            color: 'text.secondary',
                            '&:hover': {
                              color: 'primary.main',
                            },
                            textTransform: 'capitalize',
                            minHeight: '50px',
                            maxHeight: '50px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                          }}
                        >
                          {course.title}
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                          <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                            <VisibilityIcon sx={{ fontSize: '2rem' }} />
                            <Typography variant="body2" sx={{ ml: 1, alignSelf: 'center' }}>
                              {course.views}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                            <AccessTimeFilledIcon sx={{ fontSize: '2rem' }} />
                            <Typography variant="body2" sx={{ ml: 1, alignSelf: 'center' }}>
                              {videoDurations[course.id] || 'Loading...'}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}

          {courseSelected && (
            <DetailCourse
              openDialogDetail={openDialogDetail}
              onDialogDetailChange={setOpenDialogDetail}
              detail={courseSelected}
              typeSelected={typeSelected}
              listCourses={listCourses ?? ([] as GroupedCourseTypes[])}
            />
          )}
        </Box>
      ) : (
        <Category
          onCategoryChange={setOpenCategory}
          groupedCourse={groupedSelected ?? ({} as GroupedCourseTypes)}
          typeSelected={typeSelected}
          listCourses={listCourses ?? ([] as GroupedCourseTypes[])}
        />
      )}
    </>
  );
};

export default Learning;
