import React from 'react';

import { Button, TableHead, TableRow, TableCell, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useAsm from '@/Hooks/api/useAsm';
import { ProductSelectedType, ProductType } from '@/types/Assets';
import { decrypt } from '@/utils/crypto';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  openDialogDeleteAll: boolean;
  onOpenDialogDeleteAllChange: (newValue: boolean) => void;
  selected: ProductSelectedType[];
  navigateDeleteAllSuccess: () => void;
  listProduct: ProductType[];
}

const HeaderTableProduct = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    openDialogDeleteAll,
    onOpenDialogDeleteAllChange,
    selected,
    navigateDeleteAllSuccess,
    listProduct,
  } = props;

  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { t } = useTranslation();
  const { handleDeleteAsset } = useAsm();

  const handleDeleteAll = () => {
    handleDeleteAsset({ list_product_delete: selected }, navigateDeleteAllSuccess);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: 'action.hover' }}>
        {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
          <TableCell padding="checkbox" width="10%">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        <TableCell width="45%" sx={{ fontSize: '1.2rem', whiteSpace: 'nowrap', padding: '10px' }}>
          {`${t('table.asset.product')} (${listProduct.length})`}
        </TableCell>
        <TableCell width="30%" sx={{ fontSize: '1.2rem', whiteSpace: 'nowrap', padding: '10px' }}>
          {t('table.asset.version')}
        </TableCell>
        {(inforUser.role === 'manager' || inforUser.role === 'admin') && (
          <>
            <TableCell width="15%" sx={{ padding: '5px', textAlign: 'center' }}>
              {numSelected > 0 && (
                <Button
                  variant="contained"
                  fullWidth
                  type="button"
                  color="error"
                  onClick={() => {
                    onOpenDialogDeleteAllChange(true);
                  }}
                  sx={{
                    width: '60%',
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    whiteSpace: 'nowrap',
                    padding: '3px 5px',
                    minWidth: '30px',
                  }}
                >
                  {t('action.delete')}
                </Button>
              )}
            </TableCell>
            <ConfirmDeleteModal
              openDialogDelete={openDialogDeleteAll}
              onOpenDialogDeleteChange={onOpenDialogDeleteAllChange}
              handleDelete={handleDeleteAll}
            />
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

export default HeaderTableProduct;
