import { Theme } from '@mui/material';
import { merge } from 'lodash';

import Button from './Button';
import Card from './Card';
import CssBaseline from './CssBaseline';
import Stepper from './Stepper';
import Table from './Table';
import Tabs from './Tabs';

export default function ComponentsOverrides(theme: Theme) {
  return merge({
    ...Button(theme),
    ...Card(theme),
    ...Table(theme),
    ...Tabs(theme),
    ...CssBaseline(theme),
    ...Stepper(theme),
  });
}
