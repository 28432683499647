import React, { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { urlApi } from '@/configs/Api';

const GitLabCallback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasCalledApi = useRef(false);

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    if (code && !hasCalledApi.current) {
      hasCalledApi.current = true;
      console.log(code);
      fetch(`${urlApi.source.gl_oauth_callback}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem('gitlab_access_token', data.access_token);
          // Close this window and redirect the opener
          if (window.opener) {
            window.opener.postMessage({ type: 'GITHUB_AUTH_SUCCESS' }, '*');
            window.close();
          } else {
            // If there's no opener, just close this window
            window.close();
          }
        })
        .catch((error) => {
          console.error('Error exchanging code for GitLab token:', error);
          if (window.opener) {
            window.opener.postMessage({ type: 'GITHUB_AUTH_ERROR' }, '*');
          }
          // Close the window even if there's an error
          window.close();
        });
    }
  }, [location, navigate]);

  return <div>Processing GitLab login...</div>;
};

export default GitLabCallback;
