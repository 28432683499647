import { useCallback, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Backdrop,
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import { typeCourse } from '@/configs/managementConfig';
import useManagenent from '@/Hooks/api/useManagenent';
import useNotify from '@/Hooks/common/useNotify';
import { CourseTypes, TypeCourseTypes } from '@/types/management';

import 'react-quill/dist/quill.snow.css';

interface UpdateCourseProps {
  openDialogUpdate: boolean;
  onDialogUpdateChange: (newValue: boolean) => void;
  detail: CourseTypes;
  handleGetCourses: () => void;
}

const UpdateCourse: React.FC<UpdateCourseProps> = ({
  openDialogUpdate,
  onDialogUpdateChange,
  detail,
  handleGetCourses,
}) => {
  const { t } = useTranslation();
  const { handleUpdateCourse } = useManagenent();
  const { onNotify } = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();

  const contentRef = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<FileWithPath | null>(null);
  const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
  const [documentFile, setDocumentFile] = useState<FileWithPath | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [courseName, setCourseName] = useState<string>('');
  const [idVideo, setIdVideo] = useState<string>(uuidv4());
  const [type, setType] = useState(detail.type_course);
  const [courseDescription, setCourseDescription] = useState<string>(detail.description);

  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB mỗi phần

  const handleChangeType = (event: SelectChangeEvent<string>) => {
    setType(event.target.value);
  };

  // Sử dụng react-dropzone để xử lý video và thumbnail
  const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } = useDropzone({
    accept: { 'video/*': [] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const { getRootProps: getThumbnailRootProps, getInputProps: getThumbnailInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const fileThumbnail = acceptedFiles[0];
        setThumbnailFile(fileThumbnail);
        setThumbnailPreview(URL.createObjectURL(fileThumbnail));
      }
    },
  });

  const { getRootProps: getDocumentRootProps, getInputProps: getDocumentInputProps } = useDropzone({
    accept: {
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const fileDocument = acceptedFiles[0];
        setDocumentFile(fileDocument);
      }
    },
  });

  useEffect(() => {
    if (openDialogUpdate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogUpdate]);

  const validationSchema = yup.object().shape({
    title: yup.string(),
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClose = useCallback(() => {
    setFile(null);
    setThumbnailFile(null);
    setDocumentFile(null);
    setThumbnailPreview(null);
    setCourseName('');
    setUploadProgress(0);
    setUploading(false);
    setIdVideo(uuidv4());
  }, []);

  const updateSuccess = () => {
    onDialogUpdateChange(false);
    handleClose();
    reset();
    if (searchParams.get('page') === '1') {
      handleGetCourses();
    } else {
      setSearchParams({ page: '1' });
    }
  };

  const onSubmit = async () => {
    setUploading(true);

    if (file) {
      const totalChunks: number = Math.ceil(file.size / CHUNK_SIZE);
      let uploadedSize = 0;

      for (let i: number = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();

        // Chỉ thêm nếu `file` tồn tại
        formData.append('chunk_video', chunk);
        formData.append('name_video', file.name);
        formData.append('upload_id', idVideo);
        formData.append('start_size_video', start.toString());
        formData.append('end_size_video', end.toString());
        formData.append('size_video', file.size.toString());
        formData.append('mine_type_video', file.type);
        formData.append('id', detail.id);

        if (courseName) formData.append('title', courseName);
        if (thumbnailFile) {
          formData.append('thumbnail', thumbnailFile);
          formData.append('mine_type_thumbnail', thumbnailFile.type);
        }
        if (documentFile) {
          formData.append('document_file', documentFile);
          formData.append('mine_type_document', documentFile.type);
          formData.append('name_document', documentFile.name);
        }
        if (type) formData.append('type_course', type);
        if (courseDescription) formData.append('description', courseDescription);

        // eslint-disable-next-line no-await-in-loop
        await handleUpdateCourse(formData);

        // Cập nhật tiến trình upload
        uploadedSize += chunk.size;
        setUploadProgress(Math.round((uploadedSize / file.size) * 100));
      }
      setUploading(false);
      handleClose();
      updateSuccess();
      onNotify('success', t('notify.updateCourse'), 3000);
      return;
    }
    const formData = new FormData();

    if (courseName) {
      formData.append('title', courseName);
    }
    if (thumbnailFile) {
      formData.append('thumbnail', thumbnailFile);
      formData.append('mine_type_thumbnail', thumbnailFile.type);
    }
    if (documentFile) {
      formData.append('document_file', documentFile);
      formData.append('mine_type_document', documentFile.type);
      formData.append('name_document', documentFile.name);
    }
    if (type) {
      formData.append('type_course', type);
    }
    if (courseDescription) {
      formData.append('description', courseDescription);
    }
    formData.append('id', detail.id);

    await handleUpdateCourse(formData);

    setUploading(false);
    handleClose();
    updateSuccess();
    onNotify('success', t('notify.updateCourse'), 3000);
  };

  useEffect(() => {
    setCourseDescription(detail.description || '');
  }, [detail.description]);

  useEffect(() => {
    setType(detail.type_course || '');
  }, [detail.type_course]);

  return (
    <>
      <Dialog
        open={openDialogUpdate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogUpdateChange(false);
          handleClose();
          reset();
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.training.updateCourse')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('table.courseManagement.title')}
              </Typography>
              <Controller
                name="title"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    placeholder={t('placeholder.courseName')}
                    key={detail.title}
                    defaultValue={detail.title}
                    value={courseName || detail.title}
                    onChange={(e) => setCourseName(e.target.value)}
                  />
                )}
              />
            </Box>

            <Box sx={{ marginTop: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('management.training.typeCourse')}
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Select
                  key={detail.type_course}
                  defaultValue={detail.type_course}
                  value={type}
                  onChange={handleChangeType}
                  placeholder={t('management.training.typeCourse')}
                >
                  {typeCourse.map((course: TypeCourseTypes) => (
                    <MenuItem key={course.id} value={course.value}>
                      {course.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('table.courseManagement.thumbnail')}
              </Typography>
              <Box
                {...getThumbnailRootProps()}
                sx={{
                  border: '2px dashed grey',
                  borderRadius: 2,
                  padding: 2,
                  textAlign: 'center',
                  marginTop: 2,
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                }}
              >
                <input {...getThumbnailInputProps()} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('placeholder.dropzone')}
                </Typography>
                {thumbnailFile ? (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {thumbnailFile.name}
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {detail?.detail_link?.link_thumbnail}
                  </Typography>
                )}
              </Box>
              {thumbnailPreview && (
                <Box sx={{ marginTop: 2 }}>
                  <CardMedia
                    component="img"
                    image={thumbnailPreview}
                    alt="Thumbnail Preview"
                    sx={{ height: 140, width: 'auto', borderRadius: 1 }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Video
              </Typography>
              <Box
                {...getVideoRootProps()}
                sx={{
                  border: '2px dashed grey',
                  borderRadius: 2,
                  padding: 2,
                  textAlign: 'center',
                  marginTop: 2,
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                }}
              >
                <input {...getVideoInputProps()} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('placeholder.dropzone')}
                </Typography>
                {file ? (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {file.name}
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {detail?.detail_link?.link_video}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('management.training.document')}
              </Typography>
              <Box
                {...getDocumentRootProps()}
                sx={{
                  border: '2px dashed grey',
                  borderRadius: 2,
                  padding: 2,
                  textAlign: 'center',
                  marginTop: 2,
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                }}
              >
                <input {...getDocumentInputProps()} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('placeholder.dropzone')}
                </Typography>
                {documentFile ? (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {documentFile.name}
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {detail?.detail_link?.link_document}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('management.training.description')}
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#ffff',
                  '& .ql-editor': { color: 'black' },
                }}
              >
                <ReactQuill theme="snow" value={courseDescription} onChange={setCourseDescription} />
              </Box>
            </Box>
          </form>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={uploading} sx={{ mt: 3 }}>
              {uploading ? t('action.uploading') : t('action.update')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={uploading}>
        <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', width: { xs: '70vw', md: '50vw' } }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body1" color="#fff">
              {`${Math.round(uploadProgress)}%`}
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};
export default UpdateCourse;
