import * as React from 'react';
import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useSocialInfo } from '@/contexts/Social';
import useSocial from '@/Hooks/api/useSocial';
import useSetTopicSocial from '@/Hooks/social/useSetTopicSocial';
import { PAGE_SIZE } from '@/utils/constants';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '5px',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

type TopicState = {
  listTopic: any[];
  hasMore: boolean;
  currentPage: number;
};

export default function MenuTopic() {
  const { t } = useTranslation();
  const { setTopicSocial } = useSetTopicSocial();
  const { topicSocial } = useSocialInfo();
  const { handleListTopic } = useSocial();

  const [topicState, setTopicState] = useState<TopicState>({
    listTopic: [],
    hasMore: true,
    currentPage: 1,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const updateTopicState = (newState: Partial<TopicState>) => {
    setTopicState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const fetchMoreData = async () => {
    const params = {
      keyword: '',
      page: topicState.currentPage,
      page_size: PAGE_SIZE,
    };
    const res = await handleListTopic(params);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_topic } = res;
    if (list_topic && list_topic.length > 0) {
      updateTopicState({
        listTopic: [...topicState.listTopic, ...list_topic],
        currentPage: topicState.currentPage + 1,
      });
    } else {
      // Nếu không còn dữ liệu để fetch, setHasMore(false)
      updateTopicState({
        hasMore: false,
      });
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActive = (topicSelected: any) => {
    setTopicSocial(topicSelected);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ fontSize: '1.8rem', color: 'text.secondary', fontWeight: 600 }}>
        {topicSocial?.topic_name}
      </Typography>
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="text"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{ textTransform: 'capitalize', ml: 2, fontSize: '1.3rem' }}
          color="secondary"
        >
          {t('smcc.changeTopic')}
        </Button>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <InfiniteScroll
            dataLength={topicState.listTopic.length}
            next={fetchMoreData}
            hasMore={topicState.hasMore}
            loader={<h4>Loading...</h4>}
            endMessage=""
            scrollableTarget="demo-customized-menu"
            height={200}
          >
            {topicState.listTopic.map((item: any, index: number) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                  handleActive(item);
                }}
                disableRipple
                sx={{
                  fontSize: '1.4rem',
                  borderRadius: 0.8,
                  mb: 0.5,
                  backgroundColor: item.topic_name === topicSocial?.topic_name ? 'rgb(77, 162, 168)' : 'initial',
                  color: item.topic_name === topicSocial?.topic_name ? 'common.white' : 'text.disabled',
                  '&:hover': {
                    backgroundColor: item.topic_name === topicSocial?.topic_name ? 'rgb(77, 162, 168)' : 'action.hover',
                    color: item.topic_name === topicSocial?.topic_name ? 'common.white' : 'text.secondary',
                  },
                }}
              >
                {item.topic_name}
              </MenuItem>
            ))}
          </InfiniteScroll>
        </StyledMenu>
      </div>
    </Box>
  );
}
