/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardHeader, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Facebook from '@/assets/image/facebook.png';
import Forum from '@/assets/image/forum.png';
import News from '@/assets/image/news.png';
import Tiktok from '@/assets/image/tiktok.png';
import Youtube from '@/assets/image/youtube.png';
import { useCommonInfo } from '@/contexts/Common';
import { useSocialInfo } from '@/contexts/Social';
import useSocialApi from '@/Hooks/api/useSocial';
import {
  MentionedTypes,
  OverviewTopicTypes,
  SentimentBySourceTypes,
  SentimentByTimeTypes,
  StatisticalByTimeOverviewTypes,
} from '@/types/Social';
import { SEARCH_ALL, TYPE_SEARCH } from '@/utils/constants';

import BoxStatistic from './BoxStatistic';
import BarChartSentiment from '../../chart/BarChartSentiment';
import LineChartMentionsByTimeOverview from '../../chart/LineChartMentionsByTimeOverview';
import LineChartSentimentByTime from '../../chart/LineChartSentimentByTime';
import PieChart from '../../chart/PieChart';

const SkeketonCard = () => {
  return (
    <Card sx={{ backgroundColor: 'background.main' }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={null}
        title={<Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />}
      />
      <CardContent>
        <Skeleton height={30} animation="wave" variant="text" />
        <Skeleton height={30} animation="wave" variant="text" />
        <Skeleton height={30} animation="wave" variant="text" />
      </CardContent>
    </Card>
  );
};

const Overview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { topicSocial, timeScan } = useSocialInfo();
  const { handleGetOverviewSocial } = useSocialApi();
  const { apiError } = useCommonInfo();

  const [overviewData, setOverviewData] = useState<OverviewTopicTypes[]>([]);
  const [statisticalByTime, setStatisticalByTime] = useState<StatisticalByTimeOverviewTypes[]>([]);
  const [mentionedBySource, setMentionedBySource] = useState<MentionedTypes[]>([]);
  const [mentionedBySentiment, setMentionedBySentiment] = useState<MentionedTypes[]>([]);
  const [sentimentByTime, setSentimentByTime] = useState<SentimentByTimeTypes[]>([]);
  const [sentimentBySource, setSentimentBySource] = useState<SentimentBySourceTypes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (apiError) {
      setLoading(false);
    }
  }, [apiError]);

  // Statistic
  const transformDataStatistic = (data: OverviewTopicTypes[]) => {
    return data.map((item: OverviewTopicTypes) => {
      let label;
      let img;
      switch (item.label) {
        case 'facebook':
          label = 'Facebook';
          img = Facebook;
          break;
        case 'youtube':
          label = 'Youtube';
          img = Youtube;
          break;
        case 'tiktok':
          label = 'TikTok';
          img = Tiktok;
          break;
        case 'newspaper':
          label = t('smcc.overview.news');
          img = News;
          break;
        case 'forum':
          label = t('smcc.overview.forum');
          img = Forum;
          break;
        default:
          label = item.label;
          img = item.img;
          break;
      }
      return {
        label,
        img,
        counts: item.counts,
        source_mentions: item.source_mentions,
        comments: item.comments,
        reactions: item.reactions,
      };
    });
  };

  // Đề cập theo nguồn
  const transformDataMentionBySource = (data: MentionedTypes[]) => {
    return data.map((item: MentionedTypes) => {
      let source;
      switch (item.source) {
        case 'newspaper':
          source = t('smcc.overview.news');
          break;
        case 'forum':
          source = t('smcc.overview.forum');
          break;
        default:
          source = item.source.charAt(0).toUpperCase() + item.source.slice(1);
          break;
      }
      return {
        source,
        count: item.count,
        sliceSettings: {},
      };
    });
  };

  // Đề cập theo sắc thái
  const transformDataMentionBySentiment = (data: MentionedTypes[]) => {
    return data.map((item: MentionedTypes) => {
      let source;
      let sliceSettings;
      switch (item.source) {
        case 'positive':
          source = t('smcc.status.positive');
          sliceSettings = {
            fill: theme.palette.success.main,
            stroke: theme.palette.success.main,
          };
          break;
        case 'neutral':
          source = t('smcc.status.neutral');
          sliceSettings = {
            fill: '#cb997e',
            stroke: '#cb997e',
          };
          break;
        case 'negative':
          source = t('smcc.status.negative');
          sliceSettings = {
            fill: theme.palette.error.main,
            stroke: theme.palette.error.main,
          };
          break;
        default:
          source = item.source;
          sliceSettings = {};
          break;
      }
      return {
        source,
        count: item.count,
        sliceSettings,
      };
    });
  };

  // Sắc thái theo nguồn
  const transformSentimentBySource = (data: SentimentBySourceTypes[]) => {
    return data.map((item: SentimentBySourceTypes) => {
      let source;
      switch (item.source) {
        case 'newspaper':
          source = t('smcc.overview.news');
          break;
        case 'forum':
          source = t('smcc.overview.forum');
          break;
        default:
          source = item.source.charAt(0).toUpperCase() + item.source.slice(1);
          break;
      }
      return {
        source,
        positive: item.positive,
        neutral: item.neutral,
        negative: item.negative,
      };
    });
  };

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (topicSocial && timeScan) {
      handleRequest();
    }
  }, [topicSocial, timeScan]);

  const handleRequest = async () => {
    setLoading(true);
    const params = {
      keyword: topicSocial?.key_word_main,
      social: SEARCH_ALL,
      search_type: TYPE_SEARCH,
      time_range: timeScan,
    };

    const res = await handleGetOverviewSocial(params, handleLoading);
    const { data } = res;
    const { overviews, mentioned_by_source, mention_by_sentiment, sentiment, statistical_time, sentiment_time } = data;
    // data thống kê bài viết trên các nền tảng
    setOverviewData(transformDataStatistic(overviews));
    setStatisticalByTime(statistical_time);
    setMentionedBySource(transformDataMentionBySource(mentioned_by_source));
    setMentionedBySentiment(transformDataMentionBySentiment(mention_by_sentiment));
    setSentimentByTime(sentiment_time);
    setSentimentBySource(transformSentimentBySource(sentiment));
  };

  return (
    <>
      {!loading ? (
        <>
          <BoxStatistic data={overviewData} />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsByTimeGraph')}
                </Typography>
                <LineChartMentionsByTimeOverview data={statisticalByTime} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={6} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsBySourceGraph')}
                </Typography>
                <PieChart data={mentionedBySource} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.mentionsBySentimentGraph')}
                </Typography>
                <PieChart data={mentionedBySentiment} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.sentimentByTimeGraph')}
                </Typography>
                <LineChartSentimentByTime data={sentimentByTime} />
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
                <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  {t('smcc.overview.sentimentBySourceGraph')}
                </Typography>
                <BarChartSentiment data={sentimentBySource} />
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item lg={3} sm={6} xs={12}>
            <SkeketonCard />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SkeketonCard />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SkeketonCard />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SkeketonCard />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default Overview;
