/* eslint-disable no-underscore-dangle */
import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HighlightTextSocial } from '@/components/common/format/HighlightTextSocial';
import { useSocialInfo } from '@/contexts/Social';
import { YoutubePostsTypes } from '@/types/Social';

import PostsYoutubeDetail from './PostsYoutubeDetail';
import TitlePostsYoutube from './TitlePostsYoutube';

const initialPost: YoutubePostsTypes = {
  channel_title: '',
  image: '',
  tags: [],
  link: '',
  likeCount: '',
  commentCount: '',
  channel_link: '',
  channel_avatar: '',
  viewCount: '',
  favoriteCount: '',
  desc: '',
  post_time: '',
  sentiment: '',
  title: '',
};

interface PostsYoutubeProps {
  item: YoutubePostsTypes;
}

const PostsYoutube: React.FC<PostsYoutubeProps> = ({ item }) => {
  const { t } = useTranslation();
  const { topicSocial } = useSocialInfo();

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [detailSelected, setDetailSelected] = useState<YoutubePostsTypes>(initialPost);

  return (
    <>
      <Box>
        <TitlePostsYoutube item={item} />
        <Box sx={{ mt: 3, cursor: 'pointer' }}>
          <Grid container spacing={2} direction="row" sx={{ justifyContent: 'space-between' }}>
            <Grid item sm={6}>
              <Box
                component="img"
                src={item?.image}
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  // height: '70px',
                  borderRadius: 1,
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  fontWeight: 600,
                  letterSpacing: '0.01em',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 2,
                  textOverflow: 'ellipsis',
                  color: 'text.secondary',
                }}
              >
                <HighlightTextSocial text={item.title} keyword={topicSocial?.key_word_main} />
              </Typography>

              <Typography
                variant="body2"
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  mt: 2,

                  letterSpacing: '0.01em',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 5,
                  textOverflow: 'ellipsis',
                  color: 'text.secondary',
                }}
              >
                <HighlightTextSocial text={item.desc} keyword={topicSocial?.key_word_main} />
              </Typography>
              <Typography
                variant="caption"
                onClick={() => {
                  setOpenDialogDetail(true);
                  setDetailSelected(item);
                }}
                sx={{
                  mt: 1,
                  width: 'fit-content',
                  cursor: 'pointer',
                  color: 'text.secondary',
                  ':hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                  fontStyle: 'italic',
                }}
              >
                {t('action.showMore')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <PostsYoutubeDetail
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detailSelected={detailSelected}
      />
    </>
  );
};
export default PostsYoutube;
