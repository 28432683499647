import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useDataleakService from '@/services/dataleak';

import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

const useDataleak = () => {
  const { getListKeywords, getListAllKeywords, createKeywords, deleteKeywords, getListDarkweb } = useDataleakService();
  const { onNotify } = useNotify();
  const { t } = useTranslation();
  const { setLoadingGif } = useLoadingGif();

  const handleListKeywords = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListKeywords(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleListAllKeywords = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListAllKeywords(params);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      return res;
    }
  };

  const handleCreateKeywords = async (body: any, createSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createKeywords(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.createKeyword'), 3000);
      createSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteKeywords = async (body: any, deleteSuccess: any) => {
    setLoadingGif(true);
    const res: any = await deleteKeywords(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.deleteKeyword'), 3000);
      deleteSuccess();
      setLoadingGif(false);
    }
  };

  const handleListDarkweb = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await getListDarkweb(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  return {
    handleListKeywords,
    handleListAllKeywords,
    handleCreateKeywords,
    handleDeleteKeywords,
    handleListDarkweb,
  };
};

export default useDataleak;
