import { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Box,
  TextField,
  Autocomplete,
  Typography,
  Chip,
} from '@mui/material';
import * as _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Transition } from '@/components/common/Transition';
import useDataleak from '@/Hooks/api/useDataleak';

import AddButton from '../common/Button/AddButton';

interface AddKeywordsProps {
  openDialog: boolean;
  onOpenDialogChange: (newValue: boolean) => void;
  handleGetListKeywords: () => Promise<void>;
}

const AddKeywords: React.FC<AddKeywordsProps> = ({ openDialog, onOpenDialogChange, handleGetListKeywords }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleCreateKeywords } = useDataleak();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isEnter, setIsEnter] = useState(false);

  const handleTagsChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    setTags(value);
    setIsEnter(false);
  };

  const createSuccess = () => {
    onOpenDialogChange(false);
    setTags([]);
    setSearchParams({ page: String(1) });
    handleGetListKeywords();
  };

  const { handleSubmit } = useForm({});

  const onSubmit = async () => {
    const params = {
      list_key_word: tags,
    };
    if (tags.length > 0) {
      await handleCreateKeywords(params, createSuccess);
      setIsEnter(false);
    } else {
      setIsEnter(true);
    }
  };

  const handleBlur = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags((prevTags) => [...prevTags, inputValue]);
      setInputValue('');
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onOpenDialogChange(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'background.main',
              border: `1px solid ${theme.palette.divider}`,
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>{t('dataleak.createKeywords')}</DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={tags}
              onChange={handleTagsChange}
              renderTags={
                (value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              sx={{
                mt: 2,
                '.MuiChip-label': {
                  fontSize: '1.4rem',
                },
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('placeholder.keyword')}
                  onBlur={handleBlur}
                  error={Boolean(isEnter)}
                />
              )}
            />
            {isEnter ? (
              <Typography sx={{ fontSize: '1.2rem', color: 'error.main', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            ) : (
              <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>{t('smcc.enterToAdd')}</Typography>
            )}
            <Box sx={{ display: 'flex', mt: 3 }}>
              <AddButton handleClick={handleSubmit(onSubmit)} />
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddKeywords;
