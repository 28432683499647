import { SvgIconComponent } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

interface ItemSideBarProps {
  onOpenChange?: (newValue: boolean) => void;
  icon: SvgIconComponent;
  text: string;
  value: string;
}

const ItemSideBar: React.FC<ItemSideBarProps> = ({ onOpenChange, icon: IconComponent, text, value }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box
      onClick={() => {
        if (value === '/management/api-key') {
          navigate(`${value}/serper`);
        } else if (value === '/management/news') {
          navigate(`${value}/list`);
        } else {
          navigate(`${value}`);
        }
        if (onOpenChange) {
          onOpenChange(false);
        }
      }}
      sx={{
        mt: 0.5,
        padding: '5px 10px',
        backgroundColor: location.pathname.includes(value) ? 'rgb(3, 201, 215)' : 'initial',
        borderRadius: 0.5,
        transition: 'background-color 0.4s ease, color 0.4s ease',
        width: '100%',
        justifyContent: 'center',
        '& .MuiTypography-root': {
          color: location.pathname.includes(value) ? 'common.white' : 'text.secondary',
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: location.pathname.includes(value) ? 'rgb(3, 201, 215)' : 'background.hoverMenu',
          color: location.pathname.includes(value) ? 'common.white' : 'rgb(3, 201, 215) !important',
          '& .MuiTypography-root': {
            color: location.pathname.includes(value) ? 'common.white' : 'rgb(3, 201, 215) !important',
          },
          '& .MuiSvgIcon-root': {
            color: location.pathname.includes(value) ? 'common.white' : 'rgb(3, 201, 215) !important',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconComponent
          sx={{
            fontSize: '2rem',
            color: location.pathname.includes(value) ? 'common.white' : 'text.disabled',
          }}
        />
        <Typography
          sx={{
            fontSize: '1.2rem',
            ml: 1.5,
            whiteSpace: 'nowrap',
            color: 'text.primary',
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default ItemSideBar;
