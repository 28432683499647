import { useState, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ShieldIcon from '@mui/icons-material/Shield';
import {
  Button,
  useTheme,
  Box,
  Grid,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableRow,
  TableBody,
  Link,
  Skeleton,
  TableHead,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import * as _ from 'lodash';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import useAsm from '@/Hooks/api/useAsm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CPEPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleListVendors } = useAsm();

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      vendor: params.vendor as string,
      search: params.search as string,
      product_page: params.product_page as string,
      page: params.page as string,
    };
  }, [location.search]);

  const [listVendors, setListVendors] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countPageVendors, setCountPageVendors] = useState(0);
  const [countPageProducts, setCountPageProducts] = useState(0);
  const [isVendorView, setIsVendorView] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (queryParams.vendor && queryParams.vendor !== '') {
      setIsVendorView(true);
    } else {
      setIsVendorView(false);
    }
    if (queryParams.search && queryParams.search !== '') {
      setValue('searchKey', queryParams.search);
    }

    handleGetVendorsAndProducts();
  }, [queryParams]);

  const handleGetVendorsAndProducts = async () => {
    setLoading(true);
    const dataRes = await handleListVendors({
      vendor: queryParams.vendor,
      search: queryParams.search,
      page: Number.parseInt(queryParams.page || '1', 10),
      product_page: Number.parseInt(queryParams.product_page || '1', 10),
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { info_vendor, info_product, list_product, number_product } = dataRes;
    if (list_product) {
      setListProducts(list_product);
      setCountPageProducts(number_product);
    } else {
      setListProducts(info_product.list_vendor);
      setCountPageProducts(info_product.number);
      setListVendors(info_vendor.list_vendor);
      setCountPageVendors(info_vendor.number);
    }
    setLoading(false);
  };

  const validationSchema = yup.object({
    searchKey: yup.string(),
  });

  const { control, handleSubmit, setValue } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: any) => {
    setCountPageVendors(0);
    setCountPageProducts(0);
    const filter = {
      ...queryParams,
      search: data.searchKey?.trim(),
      page: 1,
      product_page: 1,
    };
    navigate(`/vendors?${queryString.stringify(filter)}`);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const handleSearchVendor = (vendor: string) => {
    const filter = {
      vendor: decodeURIComponent(vendor),
    };
    navigate(`/vendors?${queryString.stringify(filter)}`);
  };

  const handleChangePageVendor = (page: number) => {
    const filter = {
      ...queryParams,
      page,
    };
    navigate(`/vendors?${queryString.stringify(filter)}`);
  };

  const handleChangePageProduct = (page: number) => {
    const filter = {
      ...queryParams,
      product_page: page,
    };
    navigate(`/vendors?${queryString.stringify(filter)}`);
  };

  return (
    <>
      <Helmet>
        <title>Vendors & Products (CPE) - ESS</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 2,
          backgroundColor: 'background.main',
          borderTop: `5px solid ${theme.palette.divider}`,
          borderRadius: '3px',
          padding: 1.5,
          boxShadow: 2,
        }}
      >
        <Box>
          {isVendorView ? (
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginLeft: 0.5,
                }}
              >
                {t('cve.cpe.searchLabel')}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 0.5,
                  fontWeight: 800,
                  color: 'primary.main',
                  textTransform: 'capitalize',
                }}
              >
                {decodeURIComponent(queryParams.vendor).replace(/_/g, ' ')}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginLeft: 0.5,
                }}
              >
                {t('cve.cpe.vendor')}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginLeft: 0.5,
                }}
              >
                {t('cve.cpe.searchVendorProduct')}
              </Typography>
            </Box>
          )}

          <Box sx={{ width: '100%', marginTop: 1.5 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="searchKey"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    style={{ width: '100%' }}
                    placeholder={t('placeholder.search')}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon
                          onClick={handleSubmit(onSubmit)}
                          sx={{
                            fontSize: '2.2rem',
                            color: 'text.secondary',
                            mr: 1,
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        />
                      ),
                      endAdornment: queryParams.search && queryParams.search !== '' && (
                        <ClearIcon
                          onClick={() => {
                            setValue('searchKey', '');
                            navigate('/vendors');
                          }}
                          sx={{
                            fontSize: '2rem',
                            color: 'text.secondary',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Box>
        </Box>
      </Box>

      <Grid container sx={{ justifyContent: 'space-between' }}>
        {isVendorView ? (
          <Grid item xs={12} md={5.9} sx={{ mb: { xs: 2 } }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                padding: 2,
                borderRadius: '3px',
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow: 2,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <FilterAltIcon sx={{ color: 'text.secondary' }} />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginLeft: 0.5, textTransform: 'capitalize' }}
                >
                  {t('cve.cpe.vendors')}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginLeft: 0.5,
                    fontWeight: 800,
                    color: 'primary.main',
                    textTransform: 'capitalize',
                  }}
                >
                  {decodeURIComponent(queryParams.vendor).replace(/_/g, ' ')}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 0.5 }}>
                  {t('cve.cpe.selected')}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  setCountPageProducts(0);
                  setValue('searchKey', '');
                  navigate('/vendors');
                }}
              >
                <ClearIcon sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { color: 'text.primary' } }} />
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={5.9} sx={{ mb: { xs: 2 } }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'background.main',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '3px',
                boxShadow: 2,
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {t('cve.cpe.vendors')} ({countPageVendors})
              </Typography>
              <TableContainer
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  marginTop: 1,
                  borderRadius: '5px',
                }}
              >
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{t('table.cve.vendor')}</StyledTableCell>
                      <StyledTableCell>{t('table.cve.products')}</StyledTableCell>
                      <StyledTableCell>{t('table.cve.action')}</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  {!loading ? (
                    <TableBody>
                      {!_.isEmpty(listVendors) ? (
                        <>
                          {listVendors.map((row: any, index: number) => (
                            <StyledTableRow
                              key={index}
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'action.focus',
                                },
                              }}
                            >
                              <StyledTableCell component="th" scope="row">
                                {row.vendor}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() => {
                                  setCountPageVendors(0);
                                  setCountPageProducts(0);
                                  setValue('searchKey', '');
                                  handleSearchVendor(row.params_vendor);
                                }}
                                sx={{
                                  whiteSpace: 'nowrap',
                                  color: 'info.main',
                                  '&:hover': {
                                    color: 'text.secondary',
                                    textDecoration: 'underline',
                                  },
                                  cursor: 'pointer',
                                  fontWeight: 600,
                                }}
                              >
                                {row.products}
                              </StyledTableCell>
                              <StyledTableCell>
                                <Link
                                  href={`/cve?vendor=${row.params_vendor}`}
                                  rel="noopener"
                                  sx={{
                                    marginLeft: 1,
                                    color: 'info.main',
                                    '&:hover': {
                                      color: 'text.secondary',
                                      textDecoration: 'underline',
                                    },
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      textTransform: 'none',
                                      border: `1px solid ${theme.palette.text.primary}`,
                                      '&:hover': {
                                        border: `1px solid ${theme.palette.primary.light}`,
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: '1rem',
                                        color: 'text.secondary',
                                        '&:hover': {
                                          color: 'primary.main',
                                        },
                                        fontStyle: 'italic',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      <ShieldIcon sx={{ verticalAlign: 'text-top', height: '1.4rem' }} />
                                      {t('cve.cpe.viewCVE')}
                                    </Typography>
                                  </Button>
                                </Link>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <StyledTableRow>
                          <TableCell
                            colSpan={3}
                            sx={{ color: 'text.secondary', textAlign: 'center', fontSize: '1.4rem' }}
                          >
                            {t('notify.noResult')}
                          </TableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow style={{ width: '100%' }}>
                        <TableCell>
                          <Skeleton height={20} width="80%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={20} width="80%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={20} width="80%" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Pagination
                  count={Math.ceil(countPageVendors / 20)}
                  color="primary"
                  onChange={(event, value) => {
                    handleChangePageVendor(value);
                  }}
                  page={Number.parseInt(queryParams.page || '1', 10)}
                />
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={5.9}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.main',
              flexDirection: 'column',
              padding: 2,
              borderRadius: '3px',
              boxShadow: 4,
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              {t('cve.cpe.products')} ({countPageProducts})
            </Typography>
            <TableContainer
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                marginTop: 1,
                borderRadius: '5px',
              }}
            >
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('table.cve.vendor')}</StyledTableCell>
                    <StyledTableCell>{t('table.cve.products')}</StyledTableCell>
                    <StyledTableCell>{t('table.cve.action')}</StyledTableCell>
                  </TableRow>
                </TableHead>

                {!loading ? (
                  <TableBody>
                    {!_.isEmpty(listProducts) ? (
                      <>
                        {listProducts.map((row: any, index: number) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              '&:hover': {
                                backgroundColor: 'action.focus',
                              },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.vendor}
                            </StyledTableCell>
                            <StyledTableCell>{row?.products || row.product}</StyledTableCell>
                            <StyledTableCell>
                              <Link
                                href={`/cve?vendor=${row?.params_vendor?.vendor || row?.params?.vendor}&product=${
                                  row?.params_vendor?.product === undefined
                                    ? row?.params?.product
                                    : row?.params_vendor?.product
                                }`}
                                rel="noopener"
                                sx={{
                                  marginLeft: 1,
                                  color: 'info.main',
                                  '&:hover': {
                                    color: 'text.secondary',
                                    textDecoration: 'underline',
                                  },
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    textTransform: 'none',
                                    border: `1px solid ${theme.palette.text.primary}`,
                                    '&:hover': {
                                      border: `1px solid ${theme.palette.primary.light}`,
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: '1rem',
                                      color: 'text.secondary',
                                      '&:hover': {
                                        color: 'primary.main',
                                      },
                                      fontStyle: 'italic',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    <ShieldIcon sx={{ verticalAlign: 'text-top', height: '1.4rem' }} />
                                    {t('cve.cpe.viewCVE')}
                                  </Typography>
                                </Button>
                              </Link>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <StyledTableRow>
                        <TableCell
                          colSpan={3}
                          sx={{ color: 'text.secondary', textAlign: 'center', fontSize: '1.4rem' }}
                        >
                          {t('notify.noResult')}
                        </TableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow style={{ width: '100%' }}>
                      <TableCell>
                        <Skeleton height={20} width="80%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={20} width="80%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={20} width="80%" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <Pagination
                count={Math.ceil(countPageProducts / 20)}
                color="primary"
                onChange={(event, value) => {
                  handleChangePageProduct(value);
                }}
                page={Number.parseInt(queryParams.product_page || '1', 10)}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default CPEPage;
