import React, { useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Box,
  Typography,
  Checkbox,
  InputBase,
  ListItem,
  ListItemText,
  useTheme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useScrollTrigger,
  Popover,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { IconCheck, IconHelp, IconLocation2 } from '@/assets/svg/svgIcon';
import { IconWeather } from '@/assets/svg/svgWeather';
import useWeather from '@/Hooks/api/useWeather';
import { cityMappings } from '@/utils/cityMappings';
import { weatherMappings } from '@/utils/weatherMappings';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2db568' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#5b5b5b',
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface IWeather {
  temperature: string;
  cloud_status: string;
  humidity: string;
  phrase: string;
  temperature_feels_like: string;
  temperature_max: string;
  temperature_min: string;
  uv: string;
  visibility: string;
  wind: string;
  air_quality: {
    category: string;
    severity: string;
  };
}

const defaultWeather: IWeather = {
  temperature: '',
  cloud_status: '',
  humidity: '',
  phrase: '',
  temperature_feels_like: '',
  temperature_max: '',
  temperature_min: '',
  uv: '',
  visibility: '',
  wind: '',
  air_quality: {
    category: '',
    severity: '',
  },
};

interface PropsAirQualityStatus {
  status: string;
}

interface IMappings {
  [key: string]: string;
}

const Weather = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const weatherProvince = localStorage.getItem('weather-province') as string;
  const detectLocation = sessionStorage.getItem('detect_location');
  const weatherAllData = sessionStorage.getItem('weather_all_data') as string;
  const weatherProvinceSelected = sessionStorage.getItem('weather-province-selected') as string;
  const { handleGetLocation, handleGetListWeather } = useWeather();
  const locale = localStorage.getItem('locale') || 'vi';

  const [checkedLocation, setCheckedLocation] = useState(false);
  const [weather, setWeather] = useState<IWeather>(defaultWeather);
  const [listProvince, setListProvince] = useState<string[]>([]);
  const [searchLocation, setSearchLocation] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [listFilterProvince, setListFilterProvince] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const airQualityMappings: IMappings = {
    Tốt: t('weather.airQualityStatus.good'),
    'Trung bình': t('weather.airQualityStatus.moderate'),
    'Không tốt cho sức khỏe đối với các nhóm nhạy cảm': t('weather.airQualityStatus.notGood'),
    'Không tốt cho sức khỏe': t('weather.airQualityStatus.unhealthy'),
    'Rất không tốt cho sức khỏe': t('weather.airQualityStatus.veryUnhealthy'),
    'Chất lượng không khí được coi là đạt yêu cầu và ô nhiễm không khí gây ra ít rủi ro hoặc không gây ra rủi ro.': t(
      'weather.airQualitySeverity.goodSeverity',
    ),
    'Chất lượng không khí có thể chấp nhận được; tuy nhiên đối với một số chất gây ô nhiễm, có thể hơi có vấn đề về sức khỏe đối với một số rất ít những người nhạy cảm bất thường đối với ô nhiễm không khí.':
      t('weather.airQualitySeverity.moderateSeverity'),
    'Những người trong nhóm nhạy cảm có thể có ảnh hưởng đến sức khỏe. Dân số chung không có khả năng bị ảnh hưởng': t(
      'weather.airQualitySeverity.notGoodSeverity',
    ),
    'Mọi người có thể bắt đầu có các ảnh hưởng sức khỏe; những người trong nhóm nhạy cảm có thể có ảnh hưởng nghiêm trọng hơn đến sức khỏe.':
      t('weather.airQualitySeverity.unhealthySeverity'),
    'Cảnh báo sức khỏe ở tình trạng khẩn cấp.  Toàn bộ cư dân có khả năng bị ảnh hưởng cao hơn.': t(
      'weather.airQualitySeverity.veryUnhealthySeverity',
    ),
  };

  const uvIndexMappings: IMappings = {
    'Trung bình': t('weather.moderate'),
    'Rất cao': t('weather.veryHigh'),
    'Cực điểm': t('weather.extreme'),
  };
  const convertAirQualityStatus = (status: string) => {
    return airQualityMappings[status] || status;
  };

  const convertUVIndex = (status: string) => {
    return uvIndexMappings[status] || status;
  };

  const convertCityName = (city: string) => {
    const normalizedCity = city.trim();
    return cityMappings[normalizedCity] || city;
  };

  const convertWeatherStatus = (status: string) => {
    return weatherMappings[status] || status;
  };

  const getStatusIcon = (status: string) => {
    const phrase = status?.replace(/\s+/g, '');
    return IconWeather.find((icon) => icon.name === phrase);
  };

  const IconDisplay = (svgIcon: any) => {
    const foundIcon = getStatusIcon(svgIcon.svgIcon);

    if (!foundIcon) {
      return null;
    }
    return React.createElement(foundIcon, {} as any);
  };

  const sortProvinceName = (list: string[]) => {
    function customSort(a: string, b: string) {
      if (a === 'Hà Nội') return -1;
      if (b === 'Hà Nội') return 1;
      if (a === 'TP HCM') return -1;
      if (b === 'TP HCM') return 1;
      return a.localeCompare(b);
    }
    return list.sort(customSort);
  };

  const getLocation = async () => {
    const resLocation = await handleGetLocation();
    const { location } = resLocation;
    const resWeather = await handleGetListWeather();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_weather_province } = resWeather;
    sessionStorage.setItem('weather-province-selected', convertCityName(location.province_name));
    setWeather(list_weather_province[convertCityName(location.province_name)]);
    sessionStorage.setItem('weather_all_data', JSON.stringify(list_weather_province));
  };

  const getWeather = async () => {
    const res = await handleGetListWeather();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { list_weather_province } = res;
    if (list_weather_province) {
      setWeather(list_weather_province[weatherProvinceSelected || weatherProvince || 'Hà Nội']);
      sessionStorage.setItem('weather_all_data', JSON.stringify(list_weather_province));
      setListProvince(sortProvinceName(Object.keys(list_weather_province)));
    } else {
      weatherAllData && setListProvince(sortProvinceName(Object.keys(JSON.parse(weatherAllData))));
    }
  };

  useEffect(() => {
    if (!weatherProvince) {
      localStorage.setItem('weather-province', 'Hà Nội');
    }
  }, []);

  useEffect(() => {
    getWeather();
  }, []);

  useEffect(() => {
    if (detectLocation === '1') {
      setCheckedLocation(true);
    } else if (detectLocation === '0') {
      setCheckedLocation(false);
    }
  }, []);

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedLocation(event.target.checked);
    const isCheck = event.target.checked;
    if (isCheck) {
      getLocation();
      sessionStorage.setItem('detect_location', '1');
      setSearchLocation(false);
    } else {
      sessionStorage.setItem('detect_location', '0');
    }
  };

  const handleCheckedSearchLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLocation(event.target.checked);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    const filteredProvince = listProvince.filter((provinceSelected: string) => {
      const normalizedProvinceName = provinceSelected.toLowerCase();
      return normalizedProvinceName.includes(event.target.value);
    });
    setListFilterProvince(filteredProvince);

    if (event.target.value === '') {
      setListFilterProvince([]);
    }
  };

  const handSetDefaultLocation = (provinceSelected: string) => {
    localStorage.setItem('weather-province', provinceSelected);
  };

  const handSelectedLocation = (provinceSelected: string) => {
    sessionStorage.setItem('weather-province-selected', provinceSelected);
    sessionStorage.setItem('detect_location', '0');
    setCheckedLocation(false);
    setSearchLocation(false);
    setWeather(JSON.parse(weatherAllData)[provinceSelected]);
    setSearchValue('');
  };

  const colorState = (status: string) => {
    if (status === t('weather.airQualityStatus.good')) {
      return { color: '#5af587' };
    }
    if (status === t('weather.airQualityStatus.moderate')) {
      return { color: '#e9c85f' };
    }
    if (status === t('weather.airQualityStatus.notGood')) {
      return { color: '#E0782F' };
    }
    if (status === t('weather.airQualityStatus.unhealthy')) {
      return { color: '#fb545c' };
    }
    if (status === t('weather.airQualityStatus.veryUnhealthy')) {
      return { color: '#d168ff' };
    }
    return { color: 'text.primary' };
  };

  const AirQualityStatus: React.FC<PropsAirQualityStatus> = ({ status }) => {
    const { color } = colorState(status);
    return (
      <Typography variant="body2" sx={{ textWrap: 'wrap', cursor: 'pointer', color }}>
        {status}
      </Typography>
    );
  };

  return (
    <>
      {weather?.temperature && (
        <>
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              mr: 2,
              color: 'text.primary',
              ml: 2,
            }}
            disableFocusRipple={true}
            disableRipple={true}
            aria-describedby={id}
            onClick={handleClick}
          >
            <Typography variant="body2" sx={{ textTransform: 'none' }}>
              {weatherProvinceSelected || weatherProvince}
            </Typography>
            <ArrowDropDownIcon />
            <Box sx={{ width: '18px', height: '18px', marginLeft: '10px' }}>
              <IconDisplay svgIcon={`${weather?.cloud_status} S`} />
            </Box>
            <Typography variant="body2" sx={{ marginLeft: '4px' }}>
              {weather?.temperature}
            </Typography>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            disableScrollLock
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box
              sx={{
                width: { xs: '300px', sm: '400px' },
                backgroundColor: 'background.card',
                boxShadow: 15,
                padding: 2,
                borderRadius: 1,
                transition: '0.4s ease',
                textTransform: 'capitalize',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  paddingBottom: 1,
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '25px', height: '25px', color: 'text.secondary' }}>
                    <IconLocation2 />
                  </Box>
                  <Box sx={{ marginLeft: 1 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {t('weather.autoDetectLocation')}
                    </Typography>
                    {detectLocation && detectLocation !== '0' && (
                      <Box sx={{ display: 'flex', marginTop: 0.5, alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '1rem', color: 'text.secondary' }}>
                          {t('weather.currentLocation')}
                        </Typography>
                        <Typography sx={{ marginLeft: 1, fontSize: '1.2rem', color: 'text.primary' }}>
                          {weatherProvinceSelected}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                <IOSSwitch checked={checkedLocation} onChange={handleChangeSwitch} />
              </Box>
              <Box sx={{ padding: 1, transition: '0.4s ease' }}>
                {!searchLocation ? (
                  <Box sx={{ marginTop: '5px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="caption" sx={{ alignContent: 'center', color: 'text.secondary' }}>
                          {t('weather.now')}
                        </Typography>
                        <Typography variant="body2" sx={{ marginLeft: 2 }}>
                          {weatherProvinceSelected || weatherProvince}
                        </Typography>
                      </Box>

                      <Checkbox
                        icon={<SearchIcon sx={{ fontSize: '2.5rem' }} />}
                        checkedIcon={<SearchIcon sx={{ fontSize: '2.5rem' }} />}
                        onChange={handleCheckedSearchLocation}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginTop: 0.5 }}>
                          <Box sx={{ width: '45px', height: '45px' }}>
                            <IconDisplay svgIcon={weather?.cloud_status} />
                          </Box>
                          <Typography sx={{ fontSize: '3.5rem', marginLeft: 2, fontWeight: 500 }}>
                            {weather?.temperature}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{
                            marginTop: 0.5,
                            textWrap: 'wrap',
                            textTransform: 'lowercase',
                            '&:first-letter': {
                              textTransform: 'uppercase',
                            },
                          }}
                        >
                          {locale === 'vi' ? convertWeatherStatus(weather?.phrase) : weather?.cloud_status}
                        </Typography>
                        <Box sx={{ marginTop: 2, display: 'flex' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              {t('weather.high')}
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft: 1 }}>
                              {weather?.temperature_max}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', marginLeft: 1 }}>
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              {t('weather.low')}
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft: 1 }}>
                              {weather?.temperature_min}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: 0.5 }}>
                          <Typography variant="caption" sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }}>
                            {t('weather.airQuality')}
                          </Typography>
                          <Box sx={{ marginLeft: 1 }}>
                            <HtmlTooltip
                              title={
                                <>
                                  <Typography variant="body2" color="inherit">
                                    {convertAirQualityStatus(weather?.air_quality.severity)}
                                  </Typography>
                                </>
                              }
                            >
                              <Box sx={{ display: 'flex' }}>
                                <AirQualityStatus status={convertAirQualityStatus(weather?.air_quality.category)} />

                                <Box
                                  sx={{
                                    width: '15px',
                                    height: '15px',
                                    ml: 0.5,
                                    alignSelf: 'center',
                                    '&:hover': {
                                      color: 'text.disabled',
                                    },
                                  }}
                                >
                                  <IconHelp />
                                </Box>
                              </Box>
                            </HtmlTooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                            {t('weather.feelsLike')}
                          </Typography>
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>
                            {weather?.temperature_feels_like}
                          </Typography>
                        </Box>

                        <Box sx={{ marginTop: 2, display: 'flex' }}>
                          <Typography variant="caption" sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }}>
                            {t('weather.humidity')}
                          </Typography>
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>
                            {weather?.humidity}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: 0.5 }}>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {t('weather.uvIndex')}
                          </Typography>
                          <Typography variant="body2" sx={{ marginLeft: 1 }}>
                            {convertUVIndex(weather?.uv)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ marginTop: 1 }}>
                    <Box
                      sx={{
                        width: '100%',
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                      }}
                    >
                      <InputBase
                        sx={{
                          width: '100%',
                          ml: 1,
                          flex: 1,
                          '.MuiInputBase-input': {
                            padding: '10px 10px',
                          },
                        }}
                        placeholder={t('weather.findlocation')}
                        inputProps={{
                          'aria-label': 'filter location',
                        }}
                        endAdornment={
                          <CloseIcon
                            onClick={() => {
                              setSearchValue('');
                              setSearchLocation(false);
                            }}
                            sx={{
                              marginRight: 2,
                              color: 'text.disabled',
                              '&:hover': {
                                cursor: 'pointer',
                              },
                            }}
                          />
                        }
                        value={searchValue}
                        onChange={handleInputChange}
                      />
                    </Box>

                    <Box
                      sx={{
                        marginTop: 2,
                        width: '100%',
                        height: 200,
                        overflow: 'auto',
                      }}
                    >
                      {(searchValue === '' ? listProvince : listFilterProvince).map((item: string) => (
                        <ListItem
                          key={item}
                          component="div"
                          sx={{
                            padding: 0.5,
                            cursor: 'pointer',
                            '&:hover': {
                              borderRadius: 1,
                              backgroundColor: 'action.hover',
                              '&:hover .set-default': {
                                visibility: 'visible',
                              },
                            },
                          }}
                          onClick={() => {
                            handSelectedLocation(item);
                          }}
                        >
                          <ListItemText
                            primary={item}
                            sx={{
                              marginLeft: 1,
                              fontWeight: 500,
                              color: 'text.primary',
                              '.MuiListItemText-primary': {
                                fontSize: '1.2rem',
                              },
                            }}
                          />
                          {weatherProvince !== item ? (
                            <Button
                              className="set-default"
                              sx={{
                                display: 'flex',
                                paddingInline: 1,
                                marginRight: 1,
                                visibility: 'hidden',
                                backgroundColor: 'action.hover',
                                color: 'primary.light',
                                border: `1px solid ${theme.palette.divider}`,
                                '&:hover': {
                                  backgroundColor: 'action.selected',
                                  color: 'primary.dark',
                                },
                              }}
                              onClick={() => {
                                handSetDefaultLocation(item);
                              }}
                            >
                              <MyLocationIcon sx={{ fontSize: '1.5rem' }} />
                              <Typography
                                sx={{
                                  marginLeft: 1,
                                  fontSize: '1rem',
                                  fontWeight: 300,
                                  textTransform: 'none',
                                }}
                              >
                                {t('weather.autoLocation')}
                              </Typography>
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                display: 'flex',
                                paddingInline: 1,
                                marginRight: 1,
                                backgroundColor: 'action.hover',
                                color: 'text.primary',
                                border: `1px solid ${theme.palette.divider}`,
                              }}
                            >
                              <Box sx={{ width: '15px', height: '15px', ml: '5px', alignSelf: 'center' }}>
                                <IconCheck />
                              </Box>
                              <Typography
                                sx={{ marginLeft: 1, fontSize: '1rem', fontWeight: 300, textTransform: 'none' }}
                              >
                                {t('weather.default')}
                              </Typography>
                            </Button>
                          )}
                        </ListItem>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Popover>
        </>
      )}
    </>
  );
};
export default Weather;
