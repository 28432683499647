/* eslint-disable @typescript-eslint/naming-convention */

import { useState } from 'react';

import { Box, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import useLoadingGif from '@/Hooks/common/useLoadingGif';
import { FeatureArticleTypes } from '@/types/Social';

import PostsFacebook from '../facebook/PostsFacebook';
import PostsForum from '../forum/PostsForum';
import PostsNews from '../news/PostsNews';
import PostsTiktok from '../tiktok/PostsTiktok';
import PostsYoutube from '../youtube/PostsYoutube';

interface IMappings {
  [key: string]: string;
}

const TabPanel = ({
  children,
  value,
  selectedTab,
}: {
  children: React.ReactNode;
  value: string;
  selectedTab: string;
}) => {
  return (
    <div role="tabpanel" hidden={selectedTab !== value}>
      {selectedTab === value && <Box p={3}>{children}</Box>}
    </div>
  );
};

interface FeaturedPostsProps {
  listArticles: FeatureArticleTypes[];
}

const FeaturedPosts: React.FC<FeaturedPostsProps> = ({ listArticles }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setLoadingGif } = useLoadingGif();

  const [selectedTab, setSelectedTab] = useState(listArticles.length > 0 ? listArticles[0].name : '');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    setLoadingGif(true);
    setTimeout(() => {
      setLoadingGif(false);
    }, 1000);
  };

  const mappingSourcename: IMappings = {
    newspaper: t('smcc.overview.news'),
    forum: t('smcc.overview.forum'),
    facebook: 'Facebook',
    youtube: 'Youtube',
    tiktok: 'Tiktok',
  };

  return (
    <Box sx={{ mt: 4, boxShadow: 4, padding: 2, borderRadius: 1, backgroundColor: 'background.main' }}>
      <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{t('smcc.overview.featuredPosts')}</Typography>
      <Box sx={{ mt: 1, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label="featured articles tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {listArticles.map((item) => (
            <Tab
              label={mappingSourcename[item.name]}
              value={item.name}
              key={item.name}
              sx={{ textTransform: 'capitalize' }}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        {listArticles.map((articles) => (
          <>
            {articles.name === 'newspaper' && (
              <TabPanel value={articles.name} selectedTab={selectedTab} key={articles.name}>
                <Grid container spacing={2}>
                  {!_.isEmpty(articles.article) ? (
                    <>
                      {articles.article.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} md={6}>
                          <Box
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: 1,
                              padding: 2,
                              minHeight: '280px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <PostsNews item={item} />
                          </Box>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                      {t('notify.noData')}
                    </Typography>
                  )}
                </Grid>
              </TabPanel>
            )}
            {articles.name === 'facebook' && (
              <TabPanel value={articles.name} selectedTab={selectedTab} key={articles.name}>
                <Grid container spacing={2}>
                  {!_.isEmpty(articles.article) ? (
                    <>
                      {articles.article.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} md={6}>
                          <Box
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: 1,
                              padding: 2,
                              minHeight: '280px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <PostsFacebook item={item} />
                          </Box>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                      {t('notify.noData')}
                    </Typography>
                  )}
                </Grid>
              </TabPanel>
            )}
            {articles.name === 'youtube' && (
              <TabPanel value={articles.name} selectedTab={selectedTab} key={articles.name}>
                <Grid container spacing={2}>
                  {!_.isEmpty(articles.article) ? (
                    <>
                      {articles.article.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} md={6}>
                          <Box
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: 1,
                              padding: 2,
                              minHeight: '280px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <PostsYoutube item={item} />
                          </Box>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                      {t('notify.noData')}
                    </Typography>
                  )}
                </Grid>
              </TabPanel>
            )}
            {articles.name === 'forum' && (
              <TabPanel value={articles.name} selectedTab={selectedTab} key={articles.name}>
                <Grid container spacing={2}>
                  {!_.isEmpty(articles.article) ? (
                    <>
                      {articles.article.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} md={6}>
                          <Box
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: 1,
                              padding: 2,
                              minHeight: '280px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <PostsForum item={item} />
                          </Box>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                      {t('notify.noData')}
                    </Typography>
                  )}
                </Grid>
              </TabPanel>
            )}
            {articles.name === 'tiktok' && (
              <TabPanel value={articles.name} selectedTab={selectedTab} key={articles.name}>
                <Grid container spacing={2}>
                  {!_.isEmpty(articles.article) ? (
                    <>
                      {articles.article.map((item: any, index: number) => (
                        <Grid key={index} item xs={12} md={6}>
                          <Box
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: 1,
                              padding: 2,
                              minHeight: '280px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <PostsTiktok item={item} />
                          </Box>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {t('notify.noData')}
                    </Typography>
                  )}
                </Grid>
              </TabPanel>
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};
export default FeaturedPosts;
