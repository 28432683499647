import { useState } from 'react';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Box,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableBody,
  useTheme,
  Tooltip,
  Pagination,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useManagenent from '@/Hooks/api/useManagenent';
import { PAGE_SIZE } from '@/utils/constants';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface User {
  user_name: string;
  email: string;
}

interface ListIdentityProps {
  listUsers: [];
  totalRow: number;
  page: number;
  onPageChange: (newValue: number) => void;
  onSearchValueChange: (newValue: string) => void;
  handleGetList: (arg1: string, arg2: number) => Promise<void>;
}

const ListAuthentication2FA: React.FC<ListIdentityProps> = ({
  listUsers,
  totalRow,
  page,
  onPageChange,
  onSearchValueChange,
  handleGetList,
}) => {
  const { handleDisable2FA } = useManagenent();
  const { t } = useTranslation();
  const theme = useTheme();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDeleteAll, setOpenDialogDeleteAll] = useState(false);
  const [selected, setSelected] = useState<readonly User[]>([]);
  const [userDelete, setUserDelete] = useState<any>({});

  const navigateDeleteSuccess = () => {
    onPageChange(1);
    onSearchValueChange('');
    setOpenDialogDelete(false);
    setOpenDialogDeleteAll(false);
    setSelected([]);
    handleGetList('', 1);
  };

  const handleDeleteAll = () => {
    const listUsername = selected.map((user) => user.email);
    const params = {
      list_user_delete_2FA: listUsername,
    };
    handleDisable2FA(params, navigateDeleteSuccess);
  };

  const handleDelete = () => {
    const params = {
      list_user_delete_2FA: [userDelete.email],
    };
    handleDisable2FA(params, navigateDeleteSuccess);
  };

  const handleChangePage = (pageValue: number) => {
    onPageChange(pageValue);
    setSelected([]);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow sx={{ backgroundColor: 'action.hover' }}>
          <TableCell sx={{ width: '10%' }} padding="checkbox">
            {!_.isEmpty(listUsers) && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}
          </TableCell>
          <TableCell>{t('table.permission.username')}</TableCell>
          <TableCell>{t('table.permission.groupName')}</TableCell>
          <TableCell>{t('table.permission.createAt')}</TableCell>
          <TableCell>{t('table.permission.lastLogin')}</TableCell>
          <TableCell sx={{ width: '10%', padding: '5px', textAlign: 'center' }}>
            {numSelected > 0 && (
              <Button
                variant="contained"
                fullWidth
                type="button"
                color="error"
                onClick={() => {
                  setOpenDialogDeleteAll(true);
                }}
                sx={{
                  width: 'fit-content',
                  minWidth: '30px',
                  textTransform: 'none',
                  fontSize: '1.2rem',
                  whiteSpace: 'nowrap',
                  padding: '4px 8px',
                }}
              >
                {t('action.reset')}
              </Button>
            )}
          </TableCell>
          <ConfirmDeleteModal
            openDialogDelete={openDialogDeleteAll}
            onOpenDialogDeleteChange={setOpenDialogDeleteAll}
            handleDelete={handleDeleteAll}
          />
        </TableRow>
      </TableHead>
    );
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(listUsers);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, user_name: string) => {
    const selectedIndex = selected.findIndex((item: any) => item === user_name);
    let newSelected: readonly User[] = [];
    if (selectedIndex === -1) {
      const userToAdd: any = listUsers.find((user: any) => user === user_name);
      if (userToAdd) {
        newSelected = newSelected.concat(selected, userToAdd);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (user_name: string) => selected.findIndex((item: any) => item === user_name) !== -1;

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 3,
        }}
      >
        <Table aria-label="simple table">
          <EnhancedTableHead
            numSelected={selected?.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={listUsers?.length}
          />
          {!_.isEmpty(listUsers) ? (
            <TableBody>
              <>
                {listUsers.map((row: any, index: number) => (
                  <TableRow key={index} hover tabIndex={-1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        role="checkbox"
                        onClick={(event) => handleClick(event, row)}
                        checked={isSelected(row)}
                        inputProps={{
                          'aria-labelledby': `enhanced-table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>

                    <TableCell sx={{ overflowWrap: 'anywhere' }}>{row.email}</TableCell>
                    <TableCell sx={{ overflowWrap: 'anywhere' }}>{row.group_name}</TableCell>
                    <TableCell>{row.time_create_2FA}</TableCell>
                    <TableCell>{row.time_last_used}</TableCell>

                    <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                      <Tooltip title={t('action.reset')} placement="bottom">
                        <RestartAltIcon
                          onClick={() => {
                            setOpenDialogDelete(true);
                            setUserDelete(row);
                          }}
                          sx={{
                            fontSize: '2rem',
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'error.main',
                            },
                          }}
                        />
                      </Tooltip>
                      <ConfirmDeleteModal
                        openDialogDelete={openDialogDelete}
                        onOpenDialogDeleteChange={setOpenDialogDelete}
                        handleDelete={handleDelete}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noData')}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalRow / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            page={page}
          />
        </Box>
      </TableContainer>
    </>
  );
};
export default ListAuthentication2FA;
