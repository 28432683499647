import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  CardMedia,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { useSearchParams } from 'react-router-dom';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useManagenent from '@/Hooks/api/useManagenent';
import { CourseTypes } from '@/types/management';
import { PAGE_SIZE } from '@/utils/constants';

import DetailCourse from './DetailCourse';
import UpdateCourse from './UpdateCourse';

import 'react-photo-view/dist/react-photo-view.css';

interface CourseTableProps {
  listCourses: CourseTypes[];
  handleGetCourses: () => void;
  totalPage: number;
}

const CourseTable: React.FC<CourseTableProps> = ({ listCourses, handleGetCourses, totalPage }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleDeleteCourse } = useManagenent();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [newsIdSelected, setNewsIdSelected] = useState('');
  const [detailCourse, setDetailCourse] = useState<CourseTypes>();

  const deleteSuccess = () => {
    setOpenDialogDelete(false);
    if (searchParams.get('page') === '1') {
      handleGetCourses();
    } else {
      handleChangePage(1);
    }
  };

  const handleDelete = () => {
    handleDeleteCourse(
      {
        id: newsIdSelected,
      },
      deleteSuccess,
    );
  };

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 3,
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('table.courseManagement.title')}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('management.training.typeCourse')}</TableCell>
              <TableCell>{t('table.courseManagement.thumbnail')}</TableCell>
              <TableCell>{t('table.courseManagement.linkVideo')}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('table.courseManagement.document')}</TableCell>
              <TableCell sx={{ width: '20%' }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {!_.isEmpty(listCourses) ? (
                <>
                  {listCourses.map((row: CourseTypes) => (
                    <TableRow
                      key={row.id}
                      hover
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ lineBreak: 'anywhere' }}>{row.title}</TableCell>
                      <TableCell sx={{ lineBreak: 'anywhere' }}>{row.type_course}</TableCell>
                      <TableCell sx={{ lineBreak: 'anywhere' }}>
                        {row.detail_link.link_thumbnail ? (
                          <>
                            <Link
                              href={`${row.url_storage}/${row.bucket_name}/${row.detail_link.link_thumbnail}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row.detail_link.link_thumbnail}
                            </Link>

                            <Box sx={{ marginTop: 2 }}>
                              <PhotoProvider>
                                <PhotoView
                                  src={`${row.url_storage}/${row.bucket_name}/${row.detail_link.link_thumbnail}`}
                                >
                                  <CardMedia
                                    component="img"
                                    image={`${row.url_storage}/${row.bucket_name}/${row.detail_link.link_thumbnail}`}
                                    alt=""
                                    sx={{ height: 100, width: 'auto', borderRadius: 1 }}
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            </Box>
                          </>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ lineBreak: 'anywhere' }}>
                        {row.detail_link.link_video ? (
                          <Link
                            href={`${row.url_storage}/${row.bucket_name}/${row.detail_link.link_video}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.detail_link.link_video}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ lineBreak: 'anywhere' }}>
                        {row.detail_link.link_document ? (
                          <Link
                            href={`${row.url_storage}/${row.bucket_name}/${row.detail_link.link_document}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.detail_link.link_document}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ minWidth: '100px', textAlign: 'center' }}>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title={t('tooltip.detail')} placement="bottom">
                            <InfoIcon
                              onClick={() => {
                                setDetailCourse(row);
                                setOpenDialogDetail(true);
                              }}
                              sx={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'text.secondary',
                                },
                              }}
                            />
                          </Tooltip>
                          <Tooltip title={t('tooltip.edit')} placement="bottom">
                            <EditIcon
                              onClick={() => {
                                setDetailCourse(row);
                                setOpenDialogUpdate(true);
                              }}
                              sx={{
                                fontSize: '2rem',
                                ml: 3,
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'primary.main',
                                },
                              }}
                            />
                          </Tooltip>

                          <Tooltip title={t('tooltip.delete')} placement="bottom">
                            <DeleteIcon
                              onClick={() => {
                                setNewsIdSelected(row.id);
                                setOpenDialogDelete(true);
                              }}
                              sx={{
                                fontSize: '2rem',
                                ml: 3,
                                cursor: 'pointer',
                                '&:hover': {
                                  color: 'error.main',
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>

                        <ConfirmDeleteModal
                          openDialogDelete={openDialogDelete}
                          onOpenDialogDeleteChange={setOpenDialogDelete}
                          handleDelete={handleDelete}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                    {t('notify.noData')}
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(totalPage / PAGE_SIZE)}
            color="primary"
            onChange={(event, value) => {
              handleChangePage(value);
            }}
            page={Number(searchParams.get('page') || 1)}
          />
        </Box>
      </TableContainer>
      <DetailCourse
        openDialogDetail={openDialogDetail}
        onDialogDetailChange={setOpenDialogDetail}
        detail={detailCourse ?? ({} as CourseTypes)}
      />
      <UpdateCourse
        openDialogUpdate={openDialogUpdate}
        onDialogUpdateChange={setOpenDialogUpdate}
        detail={detailCourse ?? ({} as CourseTypes)}
        handleGetCourses={handleGetCourses}
      />
    </>
  );
};
export default CourseTable;
