import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  AppBar,
  Link,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { string } from 'yup';

import BackGroundHeader from '@/assets/image/Bg_Header_Asm.png';
import BgLogin from '@/assets/image/BgLogin.png';
import useBreakpoints from '@/helpers/useBreakpoints';
import useAuth from '@/Hooks/api/useAuth';
import { LOGO_ESS_WHITE } from '@/utils/imgBase64';

const Login = () => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const { handleCreateSuperAdmin, handleCheckSuperAdmin } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigateCheckSuccess = () => {
    navigate('/');
  };

  const navigateSuccess = () => {
    navigate('/');
  };

  useEffect(() => {
    handleCheckSuperAdmin({}, navigateCheckSuccess);
  }, []);

  const validationSchema = yup.object({
    email: string().required(t('validation.fieldRequired')).email(t('validation.invalidEmail')),
    password: yup
      .string()
      .required(t('validation.fieldRequired'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/, t('validation.regexPassword')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], t('validation.confirmPassword'))
      .required(t('validation.fieldRequired')),
  });

  const { control, handleSubmit } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (values: any) => {
    const params = {
      user_name: values.email,
      email: values.email,
      password: values.password,
    };
    handleCreateSuperAdmin(params, navigateSuccess);
  };

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: `url(${BgLogin}) center center no-repeat`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          padding: { xs: '0px 20px 0px 20px', md: 0 },
        }}
      >
        <AppBar
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: '80px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: `url(${BackGroundHeader}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
        >
          <Link href="/" rel="noopener" underline="none">
            <Box
              component="img"
              src={LOGO_ESS_WHITE}
              sx={{
                width: '150px',
                justifyContent: 'center',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Link>
        </AppBar>
        <Grid
          item
          sx={{
            width: { md: '600px', xs: '100%' },
          }}
        >
          <Card sx={{ backgroundColor: (theme) => theme.palette.action.activatedOpacity, boxShadow: 'none' }}>
            <CardContent>
              <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                {t('authen.createSuperAdmin.title')}
              </Typography>
              <Box sx={{ mt: 1 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        type="text"
                        variant="filled"
                        fullWidth
                        style={{ marginTop: '15px' }}
                        label={t('placeholder.email')}
                        onKeyDown={handleKeyDown}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                  />

                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                        variant="filled"
                        fullWidth
                        style={{ marginTop: '15px' }}
                        label={t('placeholder.password')}
                        onKeyDown={handleKeyDown}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        InputProps={{
                          onCopy: (e) => e.preventDefault(),
                          onPaste: (e) => e.preventDefault(),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword((prevShowPassword) => !prevShowPassword);
                                }}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={showConfirmPassword ? 'text' : 'password'}
                        variant="filled"
                        fullWidth
                        style={{ marginTop: '15px' }}
                        label={t('placeholder.confirmPassword')}
                        onKeyDown={handleKeyDown}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        InputProps={{
                          onCopy: (e) => e.preventDefault(),
                          onPaste: (e) => e.preventDefault(),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
                                }}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </form>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                      width: '40%',
                      mt: 3,
                      mb: 2,
                      boxShadow: 'none',
                      fontWeight: 700,
                      height: 45,
                      borderRadius: '10px',
                      letterSpacing: 1,
                      textTransform: 'uppercase',
                      fontSize: '1.4rem',
                      color: '#ffff',
                      backgroundColor: 'transparent',
                      border: 'solid 1px #abd2fc',
                      transition: 'all .1s ease-in-out',
                      '&:hover': {
                        background: '#50727B',
                        borderColor: '#fff',
                        transition: 'all .1s ease-in-out',
                      },
                      '&:active': {
                        transform: 'translate(0, calc(-5 * 1%)) scale(0.95)',
                        transition: 'all .1s ease-in-out',
                      },
                    }}
                  >
                    {t('action.create')}
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
};
export default Login;
