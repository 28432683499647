import { useTranslation } from 'react-i18next';

import { httpStatus } from '@/configs/Enums/httpStatus';
import useSocialService from '@/services/social';

import useLoadingGif from '../common/useLoadingGif';
import useNotify from '../common/useNotify';

const useSocial = () => {
  const {
    createTopic,
    editTopic,
    deleteTopic,
    listTopic,
    detailTopic,
    getOverviewSocial,
    getPostSocial,
    getTrendingSocial,
    getStatisticalSocial,
    getReport,
  } = useSocialService();
  const { setLoadingGif } = useLoadingGif();
  const { onNotify } = useNotify();
  const { t } = useTranslation();

  const handleCreateTopic = async (body: any, createSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await createTopic(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.createTopic'), 3000);
      createSuccess();
      setLoadingGif(false);
    }
  };

  const handleUpdateTopic = async (body: any, updateSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await editTopic(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.updateTopic'), 3000);
      updateSuccess();
      setLoadingGif(false);
    }
  };

  const handleDeleteTopic = async (body: any, deleteSuccess?: any) => {
    setLoadingGif(true);
    const res: any = await deleteTopic(body);
    const { status } = res || {};
    if (status === httpStatus.StatusOK) {
      onNotify('success', t('notify.deleteTopic'), 3000);
      deleteSuccess();
      setLoadingGif(false);
    }
  };

  const handleListTopic = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await listTopic(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleDetailTopic = async (params: {}) => {
    setLoadingGif(true);
    const res: any = await detailTopic(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetOverviewSocial = async (params: any, handleLoading?: any) => {
    setLoadingGif(true);
    const res: any = await getOverviewSocial(params);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      handleLoading();
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetPostSocial = async (body: any, handleLoading?: any) => {
    setLoadingGif(true);
    const res: any = await getPostSocial(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      handleLoading();
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetTrendingSocial = async (body: any, handleLoading?: any) => {
    setLoadingGif(true);
    const res: any = await getTrendingSocial(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      handleLoading();
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetStatisticalSocial = async (body: any, handleLoading?: any) => {
    setLoadingGif(true);
    const res: any = await getStatisticalSocial(body);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      handleLoading();
      setLoadingGif(false);
      return data;
    }
  };

  const handleGetReport = async (id: string) => {
    setLoadingGif(true);
    const res: any = await getReport(id);
    const { data, status } = res || {};
    if (status === httpStatus.StatusOK) {
      setLoadingGif(false);
      return data;
    }
  };

  return {
    handleCreateTopic,
    handleUpdateTopic,
    handleDeleteTopic,
    handleListTopic,
    handleDetailTopic,
    handleGetOverviewSocial,
    handleGetPostSocial,
    handleGetTrendingSocial,
    handleGetStatisticalSocial,
    handleGetReport,
  };
};

export default useSocial;
