/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  useTheme,
  Box,
  TextField,
  LinearProgress,
  Typography,
  CircularProgress,
  TableRow,
  TableCell,
  Button,
  Tooltip,
  SelectChangeEvent,
  Grid,
  Card,
  TableContainer,
  Table,
  TableBody,
  Link,
} from '@mui/material';
import * as _ from 'lodash';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import ScanButton from '@/components/common/Button/ScanButton';
import StopButton from '@/components/common/Button/StopButton';
import FormSelectDomain from '@/components/common/FormSelectDomain';
import ScanDoneNotice from '@/components/common/Notice/ScanDoneNotice';
import ScanTime from '@/components/common/ScanTime';
import PortModal from '@/components/Modal/PortModal';
import { useCommonInfo } from '@/contexts/Common';
import { useScanSubdomainInfo } from '@/contexts/ScanSubdomain';
import { useWebSocketContext } from '@/contexts/WebSocketContext';
import useBreakpoints from '@/helpers/useBreakpoints';
import useAsm from '@/Hooks/api/useAsm';
import useNotify from '@/Hooks/common/useNotify';
import useScanError from '@/Hooks/common/useScanError';
import useSetKeySearch from '@/Hooks/common/useSetKeySearch';
import useLoadingStatistics from '@/Hooks/subdomain/useLoadingStatistics';
import useStatistics from '@/Hooks/subdomain/useStatistics';
import useSubdomain from '@/Hooks/subdomain/useSubdomain';
import { PAGE_SIZE, REGEX_DOMAIN, REGEX_IP } from '@/utils/constants';
import { decrypt, encrypt } from '@/utils/crypto';

import GeoChart from './GeoChart';
import LoadingStatistics from './LoadingStatistics';
import Statistics from './Statistics';
import TableListSubdomain from './TableListSubdomain';

const Subdomains = () => {
  const { isMobile } = useBreakpoints();
  const theme = useTheme();
  const { t } = useTranslation();
  const { onNotify } = useNotify();
  const { setKeySearch } = useSetKeySearch();
  const navigate = useNavigate();
  const { handleScanSubdomain, handleSearchSubdomain, handleStatistics } = useAsm();
  const { setScanError } = useScanError();
  const { setSubdomain } = useSubdomain();
  const { setStatistics } = useStatistics();
  const { setLoadingStatistics } = useLoadingStatistics();
  const { isScanError } = useCommonInfo();
  const { subdomain, statistics, isLoadingStatistics } = useScanSubdomainInfo();
  const domainActive = localStorage.getItem('domainActive')
    ? decrypt(localStorage.getItem('domainActive') as string)
    : '';
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const { dataWebSocket } = useWebSocketContext();

  const [subdomainState, setSubdomainState] = useState({
    listSubdomain: subdomain?.listSubdomain,
    subdomainDetail: subdomain?.dataSubdomain,
    isRunning: subdomain?.stateRunning,
    buttonState: subdomain?.stateButton,
    isFirstCall: subdomain?.isFirstCall,
    isSuccessApi: subdomain?.isSuccessApi,
    count: subdomain?.countSubdomain,
  });
  const [statisticsState, setStatisticsState] = useState({
    countPort: statistics?.countPort,
    countTech: statistics?.countTech,
    countScheme: statistics?.countScheme,
    countLocation: statistics?.countLocation,
    countTotalDomain: statistics?.countTotalDomain,
    countTotalUniqHost: statistics?.countTotalUniqHost,
    countTotalUniqUrl: statistics?.countTotalUniqUrl,
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingChangePage, setLoadingChangePage] = useState(false);
  const [showMoreTech, setShowMoreTech] = useState<any>({});
  const [domain, setDomain] = useState(domainActive);
  const [updateTime, setUpdateTime] = useState('');

  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  const handleChangeData = async (page = 0) => {
    const scanKey = inforUser?.role === 'super admin' ? getValues('searchKey') : domain;
    const paramsScan = {
      url: scanKey,
      page,
      page_size: PAGE_SIZE,
    };
    setLoadingChangePage(true);
    const dataResDetail = await handleScanSubdomain(paramsScan);
    const { data, total_item } = dataResDetail;
    setSubdomainState((prevState) => ({
      ...prevState,
      subdomainDetail: data,
      count: total_item,
    }));
    setLoadingChangePage(false);
  };

  const handleGetStatistics = async () => {
    const scanKey = inforUser?.role === 'super admin' ? getValues('searchKey') : domain;
    const params = {
      url: scanKey,
    };
    const dataResDetail = await handleStatistics(params);
    const { list_port, list_tech, list_scheme, list_geo, total_domain, total_different_host, total_different_url } =
      dataResDetail;
    setStatisticsState({
      countPort: list_port,
      countTech: list_tech,
      countScheme: list_scheme,
      countLocation: list_geo,
      countTotalDomain: total_domain,
      countTotalUniqHost: total_different_host,
      countTotalUniqUrl: total_different_url,
    });
    setStatistics({
      countPort: list_port,
      countTech: list_tech,
      countScheme: list_scheme,
      countLocation: list_geo,
      countTotalDomain: total_domain,
      countTotalUniqHost: total_different_host,
      countTotalUniqUrl: total_different_url,
    });
    setLoadingStatistics(false);
  };

  useEffect(() => {
    if (isScanError) {
      setSubdomainState((prevState) => ({
        ...prevState,
        isRunning: false,
        buttonState: false,
        isSuccessApi: false,
      }));
      clearInterval(intervalRef.current);
      clearInterval(subdomain?.intervalId);
      setSubdomain({
        ...subdomain,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
        stateButton: false,
      });
      setScanError(false);
    }
  }, [isScanError]);

  useEffect(() => {
    if (subdomain?.intervalId) {
      clearInterval(subdomain?.intervalId);
    }
  }, []);

  useEffect(() => {
    if (subdomainState.isRunning && !subdomain?.isFinish) {
      if (subdomainState.isFirstCall) {
        handleScanDetail();
      }
      if (subdomainState.isSuccessApi) {
        intervalRef.current = setInterval(() => {
          handleScanDetail();
        }, 10000);
      } else {
        clearInterval(intervalRef.current);
        handleCacheSubdomain();
      }
    } else {
      clearInterval(intervalRef.current);
      setSubdomain({
        ...subdomain,
        dataSubdomain: subdomainState.subdomainDetail,
        countSubdomain: subdomainState.count,
        stateRunning: false,
        isSuccessApi: subdomainState.isSuccessApi,
        isFirstCall: subdomainState.isFirstCall,
        stateButton: false,
        keySearch: getValues('searchKey'),
        intervalId: intervalRef.current,
      });
    }
    return () => {
      handleCacheSubdomain();
    };
  }, [subdomainState.isRunning, subdomainState.isFirstCall, subdomainState.isSuccessApi]);

  // load xong url se goi api load toan bo detail
  const handleScanDetail = async () => {
    const scanKey = inforUser?.role === 'super admin' ? getValues('searchKey') : domain;
    const paramsScan = {
      url: scanKey,
      page: 0,
      page_size: PAGE_SIZE,
    };
    const dataResDetail = await handleScanSubdomain(paramsScan);
    const { data, is_finish, total_item } = dataResDetail;
    setSubdomainState((prevState) => ({
      ...prevState,
      isFirstCall: false,
      isSuccessApi: true,
      subdomainDetail: data,
      count: total_item,
    }));
    if (is_finish === 'true') {
      handleGetStatistics();
      setSubdomain({
        ...subdomain,
        dataSubdomain: data,
        countSubdomain: total_item,
        stateRunning: false,
        isSuccessApi: false,
        isFirstCall: false,
        stateButton: false,
        isFinish: true,
      });
      clearInterval(intervalRef.current);
      clearInterval(subdomain?.intervalId);
      setSubdomainState((prevState) => ({
        ...prevState,
        isRunning: false,
        buttonState: false,
        isSuccessApi: false,
      }));
      onNotify('success', t('notify.scanSubdomainDone'), null);
      return;
    }
    setSubdomain({
      ...subdomain,
      dataSubdomain: data,
      countSubdomain: total_item,
      stateRunning: subdomainState.isRunning,
      isSuccessApi: subdomainState.isSuccessApi,
      isFirstCall: subdomainState.isFirstCall,
      stateButton: subdomainState.buttonState,
      keySearch: getValues('searchKey'),
      intervalId: intervalRef.current,
      isFinish: false,
    });
  };
  const handleClickSubdomain = (url: any) => {
    setKeySearch(url);
    navigate('/info');
  };

  // validate search
  const validationSchema = yup.object({
    searchKey: yup
      .string()
      .required(t('validation.fieldRequired'))
      .test('is-valid', t('validation.aValidDomainAndIp'), function (value) {
        return REGEX_DOMAIN.test(value) || REGEX_IP.test(value);
      }),
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      searchKey: subdomain?.keySearch,
    },
  });

  const handleCacheSubdomain = () =>
    setSubdomain({
      ...subdomain,
      dataSubdomain: subdomainState.subdomainDetail,
      countSubdomain: subdomainState.count,
      stateRunning: subdomainState.isRunning,
      isSuccessApi: subdomainState.isSuccessApi,
      isFirstCall: subdomainState.isFirstCall,
      stateButton: subdomainState.buttonState,
      keySearch: inforUser?.role === 'super admin' ? getValues('searchKey') : domain,
      intervalId: intervalRef.current,
    });

  const onSubmit = async (data: any) => {
    setLoadingStatistics(true);
    setSubdomainState((prevState) => ({
      ...prevState,
      buttonState: true,
      listSubdomain: [],
      subdomainDetail: [],
      count: 0,
    }));
    setStatisticsState({
      countPort: [],
      countTech: [],
      countScheme: [],
      countLocation: [],
      countTotalDomain: null,
      countTotalUniqHost: null,
      countTotalUniqUrl: null,
    });
    setSubdomain(null);
    setStatistics(null);
    setPageNumber(0);
    const scanKey = getValues('searchKey');
    const paramsSearch = {
      domain: scanKey,
    };
    const dataResSubdomain = await handleSearchSubdomain(paramsSearch);
    const { data: dataSub } = dataResSubdomain;
    setSubdomainState((prevState) => ({
      ...prevState,
      listSubdomain: dataSub,
      isRunning: true,
      isFirstCall: true,
    }));
    setSubdomain({
      listSubdomain: dataSub,
    });
  };

  const filteredList = dataWebSocket.filter((item: any) => item.domain === domain);

  useEffect(() => {
    if (inforUser?.role !== 'super admin') {
      if (!_.isEmpty(filteredList.find((item: any) => item.title === 'listSubdomain')?.data?.data)) {
        setSubdomainState((prevState) => ({
          ...prevState,
          buttonState: false,
          subdomainDetail: filteredList.find((item: any) => item.title === 'listSubdomain')?.data?.data || [],
        }));
        setUpdateTime(filteredList.find((item: any) => item.title === 'listSubdomain')?.update_time);
        const statistic = filteredList.find((item: any) => item.title === 'statisticalInforDomain')?.data || [];
        setStatisticsState({
          countPort: statistic.list_port,
          countTech: statistic.list_tech,
          countScheme: statistic.list_scheme,
          countLocation: statistic.list_geo,
          countTotalDomain: statistic.total_domain,
          countTotalUniqHost: statistic.total_different_host,
          countTotalUniqUrl: statistic.total_different_url,
        });
        setLoadingStatistics(false);
      } else {
        setSubdomainState((prevState) => ({
          ...prevState,
          buttonState: true,
        }));
        setLoadingStatistics(true);
      }
    }
  }, [domain, dataWebSocket]);

  const handleKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const toggleShowMoreTech = (id: number) => {
    setShowMoreTech((prevShowMoreTech: any) => ({
      ...prevShowMoreTech,
      [id]: !prevShowMoreTech[id],
    }));
  };

  const handleChangeDomain = async (event: SelectChangeEvent) => {
    setDomain(event.target.value);
    localStorage.setItem('domainActive', await encrypt(event.target.value));
  };

  // table recon
  const columns = [
    {
      name: 'url',
      label: 'URL',
      options: {
        customBodyRender: (value: any) => {
          return (
            <Typography
              variant="body2"
              onClick={() => handleClickSubdomain(value)}
              sx={{
                color: `${theme.palette.info.main}`,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'host',
      label: 'Host',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'port',
      label: 'Port',
      options: {
        sort: false,
        filter: false,
        download: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value?.length !== 0 ? (
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{
                    fontSize: '1.2rem',
                    textTransform: 'none',
                    width: 'auto',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('asm.subdomain.showPort')}
                </Button>
              ) : (
                <Typography variant="body2" sx={{ color: `${theme.palette.text.primary}` }}>
                  -
                </Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'scheme',
      label: 'Scheme',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'tech',
      label: 'Tech',
      options: {
        customBodyRender: (value: [], rowIndex: any) =>
          !_.isEmpty(value) ? (
            <>
              {value.map((tech, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    display: index > 2 && !showMoreTech[rowIndex.rowIndex] ? 'none' : 'block',
                    padding: 0.4,
                  }}
                >
                  - {tech}
                </Typography>
              ))}
              {value.length > 3 && (
                <Typography
                  sx={{ fontSize: '1.2rem', color: 'primary.main', '&:hover': { color: 'text.secondary' } }}
                  onClick={() => toggleShowMoreTech(rowIndex.rowIndex)}
                >
                  {showMoreTech[rowIndex.rowIndex] ? (
                    <Tooltip title={t('tooltip.showLess')}>
                      <ExpandLessIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('tooltip.showMore')}>
                      <MoreHorizIcon />
                    </Tooltip>
                  )}
                </Typography>
              )}
            </>
          ) : (
            '-'
          ),
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'waf',
      label: 'Waf',
      options: {
        customBodyRender: (value: string) => value || '-',
      },
    },
    {
      name: 'cve',
      label: 'CVE',
      options: {
        customBodyRender: (value: [], rowIndex: any) =>
          !_.isEmpty(value) ? (
            <>
              <TableContainer sx={{ maxHeight: 195, overflow: 'auto' }}>
                <Table>
                  <TableBody>
                    {value.map((itemCVE: any, indexCVE: number) => (
                      <TableRow key={indexCVE} hover>
                        <TableCell sx={{ padding: '6px' }}>
                          <Link
                            underline="none"
                            sx={{
                              padding: '5px',
                              display: 'flex',
                              fontSize: '1.2rem',
                              color: 'primary.dark',
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'secondary.main',
                              },
                            }}
                            href={`/cve/${itemCVE}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemCVE}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            '-'
          ),
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },
    serverSide: true,
    count: subdomainState.count,
    page: pageNumber,
    rowsPerPage: PAGE_SIZE,
    rowsPerPageOptions: [],
    onTableChange: (action: any, tableState: any) => {
      switch (action) {
        case 'changePage':
          setPageNumber(tableState.page);
          handleChangeData(tableState.page);
          break;
        case 'sort':
          // eslint-disable-next-line no-case-declarations
          const { sortColumn, sortDirection } = tableState;
          // eslint-disable-next-line no-case-declarations
          const sortedData = [...subdomainState.subdomainDetail].sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];
            if (sortDirection === 'asc') {
              return aValue > bValue ? 1 : -1;
            }
            return aValue < bValue ? 1 : -1;
          });
          setSubdomainState((prevState) => ({
            ...prevState,
            subdomainDetail: sortedData,
          }));
          break;
        default:
          break;
      }
    },
    pagination: subdomainState.subdomainDetail?.length > 0,
    expandableRows: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex: any) => {
      return subdomainState.subdomainDetail[dataIndex]?.port?.length !== 0;
    },
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const colSpan = rowData.length + 1;
      const { dataIndex } = rowMeta;
      const expandPort = subdomainState.subdomainDetail[dataIndex].port;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <PortModal port={expandPort} />
          </TableCell>
        </TableRow>
      );
    },
  };

  const optionsNotSuperAdmin = {
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: false,
    selectableRowsHideCheckboxes: true,
    count: subdomainState.subdomainDetail?.length,
    rowsPerPage: PAGE_SIZE,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },

    expandableRows: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex: any) => {
      return subdomainState.subdomainDetail[dataIndex]?.port?.length !== 0;
    },
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const colSpan = rowData.length + 1;
      const { dataIndex } = rowMeta;
      const expandPort = subdomainState.subdomainDetail[dataIndex].port;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <PortModal port={expandPort} />
          </TableCell>
        </TableRow>
      );
    },
  };
  const components = {
    ExpandButton(props: any) {
      if (
        props.dataIndex !== undefined &&
        props.dataIndex !== null &&
        subdomainState.subdomainDetail[props.dataIndex] &&
        subdomainState.subdomainDetail[props.dataIndex].port.length !== 0
      )
        return <ExpandButton {...props} />;
      return <div style={{ width: '24px' }} />;
    },
  };

  return (
    <>
      <Helmet>
        <title>Scan Subdomain - Attack Surface Management</title>
      </Helmet>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('asm.subdomain.title')}
      </Typography>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'background.main',
              flexDirection: 'column',
              padding: 2,
              borderRadius: 1,
              boxShadow: 3,
            }}
          >
            {inforUser?.role === 'super admin' ? (
              <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 2 }}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: isMobile ? '100%' : '50%' }}>
                  <Controller
                    name="searchKey"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={!!subdomainState.buttonState}
                        autoFocus
                        variant="outlined"
                        fullWidth
                        helperText={errors.searchKey?.message?.toString()}
                        error={Boolean(errors.searchKey)}
                        onKeyDown={handleKeyDown}
                        sx={{
                          display: isMobile ? 'flex' : '',
                          alignItems: isMobile ? 'center' : '',
                          justifyContent: isMobile ? 'center' : '',
                        }}
                        placeholder={t('placeholder.domainAndIp')}
                      />
                    )}
                  />
                </form>
                {subdomainState.buttonState ? (
                  <StopButton
                    handleClick={() => {
                      setSubdomainState((prevState) => ({
                        ...prevState,
                        isSuccessApi: false,
                        isRunning: false,
                        buttonState: false,
                      }));
                      setLoadingStatistics(false);
                    }}
                  />
                ) : (
                  <ScanButton handleClick={handleSubmit(onSubmit)} />
                )}
              </Box>
            ) : (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <FormSelectDomain domain={domain} handleChangeDomain={handleChangeDomain} />
                {!_.isEmpty(subdomainState.subdomainDetail && updateTime) && <ScanTime updateTime={updateTime} />}
              </Box>
            )}

            {subdomainState.buttonState && (
              <>
                <Typography
                  variant="body2"
                  sx={{ marginTop: 3, textAlign: 'center', color: 'error.main', fontWeight: 600 }}
                >
                  {t('notify.scanInProgress')}
                </Typography>
                <br />
                <Box sx={{ width: '100%', marginTop: 1, marginBottom: 3 }}>
                  <LinearProgress color="info" />
                </Box>
              </>
            )}

            {subdomain?.isFinish && subdomainState.isRunning !== undefined && inforUser?.role === 'super admin' && (
              <ScanDoneNotice />
            )}
            <Box
              sx={{
                marginTop: 3,
              }}
            >
              {!_.isEmpty(subdomainState.subdomainDetail) ? (
                <MUIDataTable
                  title={
                    loadingChangePage && (
                      <div style={{ color: 'text.primary' }}>
                        <CircularProgress color="inherit" />
                      </div>
                    )
                  }
                  data={subdomainState.subdomainDetail}
                  columns={columns}
                  options={inforUser?.role === 'super admin' ? (options as any) : (optionsNotSuperAdmin as any)}
                  components={components}
                />
              ) : (
                <TableListSubdomain listSubdomain={subdomainState.listSubdomain} />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          {isLoadingStatistics ? (
            <LoadingStatistics />
          ) : (
            <Statistics
              countPort={statisticsState.countPort}
              countTech={statisticsState.countTech}
              countScheme={statisticsState.countScheme}
              countLocation={statisticsState.countLocation}
              countTotalDomain={statisticsState.countTotalDomain}
              countTotalUniqHost={statisticsState.countTotalUniqHost}
              countTotalUniqUrl={statisticsState.countTotalUniqUrl}
            />
          )}
        </Grid>
      </Grid>
      {subdomainState.subdomainDetail && (
        <Card
          sx={{
            mt: 4,
            padding: 2,
          }}
        >
          <GeoChart subdomainDetail={subdomainState.subdomainDetail} />
        </Card>
      )}
    </>
  );
};
export default Subdomains;
