import { useState } from 'react';

import { useTheme, Box, Modal, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';

import useBreakpoints from '@/helpers/useBreakpoints';
import useAsm from '@/Hooks/api/useAsm';

import PortModal from './PortModal';

interface CidrModalProps {
  cidr: [];
  openCidr: boolean;
  handleCloseCidr: () => void;
}

const CidrModal: React.FC<CidrModalProps> = ({ cidr, openCidr, handleCloseCidr }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleSearchPort } = useAsm();
  const { isMobile } = useBreakpoints();

  const [port, setPort] = useState<any>([]);
  const [openPort, setOpenPort] = useState(false);

  // open Port Modal
  const handleOpenPort = () => {
    setOpenPort(true);
  };
  const handleClosePort = () => {
    setOpenPort(false);
  };

  const handleSearchPortDetail = async (portDetail: string) => {
    const params = {
      domain: portDetail,
    };
    const dataRes = await handleSearchPort(params);
    if (dataRes.data) {
      handleOpenPort();
    }
    setPort(dataRes.data);
  };
  const handleClickPort = (portDetail: string) => {
    handleSearchPortDetail(portDetail);
  };

  const columns = [
    {
      name: 'ip',
      label: 'IP Address',
      options: {
        customBodyRender: (value: any) => {
          return (
            <Typography
              variant="body2"
              onClick={() => handleClickPort(value)}
              sx={{
                color: `${theme.palette.info.main}`,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: t('notify.noResult'),
      },
    },
  };

  return (
    <>
      <Modal open={openCidr} onClose={handleCloseCidr}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '85vh',

            width: isMobile ? 400 : 550,
            bgcolor: 'background.main',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
            p: 4,
          }}
        >
          <MUIDataTable title="" data={cidr.map((ip) => [ip])} columns={columns} options={options as any} />
        </Box>
      </Modal>
      <PortModal port={port} openPort={openPort} handleClosePort={handleClosePort} />
    </>
  );
};
export default CidrModal;
