import React from 'react';

import { Button, TextField, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type SubmitData = {
  searchInput: string;
};

interface TextFieldOsintProps {
  submitHandler: (data: SubmitData) => void;
  defaultValue: string;
}

const TextFieldOsint: React.FC<TextFieldOsintProps> = ({ submitHandler, defaultValue }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<SubmitData>();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(submitHandler)();
    }
  };

  return (
    <TextField
      id="searchKeyword"
      type="text"
      sx={{
        width: '50%',
        borderRadius: '10px',
        backgroundColor: 'action.focus',
        '.MuiInputBase-input': {
          padding: '15px',
          borderColor: 'transparent',
          fontSize: '1.2rem',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
        },
        input: {
          '&::placeholder': {
            opacity: 0.9,
            fontSize: '1.2rem',
          },
        },
      }}
      defaultValue={defaultValue}
      placeholder={t('placeholder.typeSearch')}
      autoComplete="off"
      onKeyUp={handleKeyDown}
      error={Boolean(errors.searchInput)}
      InputProps={{
        endAdornment: (
          <Button type="submit" aria-labelledby="submit" sx={{ padding: 0, minWidth: '20px', mr: 2 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              style={{ height: '2rem', width: '2rem', fill: theme.palette.text.primary }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </Button>
        ),
      }}
      {...register('searchInput', {
        required: t('validation.keywordRequired'),
      })}
    />
  );
};

export default TextFieldOsint;
